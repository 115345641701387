<template>
  <div>
    <div
      style="
        background: #ececec;
        padding: 15px;
        width: 100%;
        margin-bottom: 15px;
      "
      v-if="maxPointFilter.length !== 0"
    >
    <!-- Total Points -->
      <a-card
        :title="$t(addcomm.TotalPoints) (' + this.minPoint + '-' + this.maxPoint + ')"
        :bordered="false"
        style="width: 100%"
      >
        <a-slider
          range
          :max="this.maxPointFilter"
          :min="this.minPointFilter"
          :defaultValue="[this.minPointFilter, this.maxPointFilter]"
          :v-model="this.hotelPointsFilters"
          @change="getSliderValue($event)"
        />
      </a-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      minPointFilter: 0,
      maxPointFilter: 0,
      maxPoint: 0,
      minPoint: 0,
      hotelPointsFilters: []
    };
  },
  watch: {
    hotelPointsFilters: function () {
      this.$emit("input", this.hotelPointsFilters);
    },
  },
  created() {
    this.minPointFilter =
      this.$store.getters["hotel/hotelSearchResults"].minPoint || 0;
    this.maxPointFilter =
      this.$store.getters["hotel/hotelSearchResults"].maxPoint || 0;
    this.maxPoint = this.$store.getters["hotel/hotelSearchResults"].maxPoint;
    this.minPoint = this.$store.getters["hotel/hotelSearchResults"].minPoint;
  },
  methods: {
    getSliderValue(value) {
      this.minPoint = value[0];
      this.maxPoint = value[1];
      this.hotelPointsFilters = [this.minPoint, this.maxPoint]
    },
  },
};
</script>
<style scoped>
</style>