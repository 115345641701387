<template>
  <div>
    <div class="main">
      <div class="row" v-if="data !== ''">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div
            class="row"
            style="
              text-align: left;
              border: 1px dotted;
              border-radius: 4px;
              padding: 15px;
            "
          >
            <div style="text-align: center">
              <h3>Congratulations!</h3>
              <img
                src="https://www.cntraveller.in/wp-content/themes/cntraveller/images/check-circle.gif"
                height="40px"
                width="40px"
              />
              <br /><br />
              <div style="text-align: left">
                <h4>
                  Your transaction is successful and the gift card has been
                  emailed to your registered mail id.<br />
                  Use your Gift card codes to redeem at any of our participating
                  partners.
                </h4>
              </div>
              <br /><br />
              <br />
            </div>
            <div class="col-md-6">
              <h4>Name: {{ data.buyable.name || "" }}</h4>
              <h4>Points: {{ data.points || "" }}</h4>
              <!-- <div class="row">
                <div v-for="(d, index) in formDetails" :key="index">
                  <h4>{{ d.Key.toUpperCase() }}: {{ d.Value }}</h4>
                </div>
              </div> -->
            </div>
            <br /><br />
            <div class="col-md-6" v-if="data !== ''">
              <img :src="data.buyable.imgs[0]" height="220px" width="100%" />
            </div>
            <br /><br />
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
      <br /><br />
      <a-button
        type="primary"
        @click="$store.dispatch('navigate', { c2: 'home' })"
      >
        Back to Home Page
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: "",
      formDetails: [],
    };
  },
  created() {
    window.scroll(0, 0);
  },
  activated() {
    this.data = this.$store.getters.buyableRedeemSuccess;
    console.log(this.data);
    if (this.data !== "" && this.data.form) {
      this.getFormDetails(this.data.form);
    }
  },
  methods: {
    getFormDetails(form) {
      this.formDetails = [];
      let objectKeys = Object.keys(form);
      for (let i = 0; i < objectKeys.length; i++) {
        this.formDetails.push({
          Key: objectKeys[i],
          Value: this.data.form[objectKeys[i]],
        });
        console.log(this.formDetails);
      }
    },
  },
};
</script>

<style scoped>
.main {
  margin-top: 3%;
  margin-bottom: 5%;
}
</style>