<template>
  <div class="container" style="padding-top: 15px; position: relative; top: 0">
    <div class="row">
      <div class="col-md-12">
        <a-collapse accordion style="margin-bottom: 15px">
          <a-collapse-panel
            key="1"
            :header="$t('addcomm.Modify Search')"
            style="text-align: left"
          >
            <div slot="extra"></div>
            <flight-search-panel show-errors :border="false" title="" />
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <flight-airline-filter v-model="airlineFilters" />
        <flight-stops-filter v-model="stopsFilters" />
      </div>
      <div class="col-md-9">
        <div class="row" v-if="filteredPayload.length != 0">
          <div class="col-md-12" style="text-align: left">
            <p>
              {{$t('addcomm.All timings are local')}}
               | {{$t('addcomm.Fare not guaranteed until ticketed')}} | {{$t('addcomm.Points displayed')}}  
            </p>
          </div>
        </div>
        <div
          class="row"
          v-for="(result, index) in filteredPayload.slice(startIndex, endIndex)"
          :key="index"
        >
          <div class="col-md-12">
            <a-card hoverable style="margin-bottom: 15px">
              <div slot="title" style="text-align: left">
                <img
                  class="avatar"
                  :src="
                    result.ListOfFlightDetails[0].ListOfFlightSegments[0]
                      .Carrier.CarrierLogoPath
                  "
                />&nbsp;
                {{
                  result.oneWayFlightDetails.ListOfFlightSegments[0].Carrier
                    .CarrierName
                }}
              </div>
              <div slot="extra" style="text-align: right">
                <h5 style="margin-bottom: 0">
                  {{ result.Fare }} <small>{{$t('common.Points')}}</small>
                </h5>
                <span v-if="result.returnFlightDetails != undefined"
                  >{{$t('addcomm.round trip')}} </span
                >
                <span v-if="result.returnFlightDetails == undefined"
                  >{{$t('addcomm.one way trip')}}</span
                >
              </div>
              <div class="row">
                <div class="col-md-12">
                  <a-card-grid
                    style="width: 15%; text-align: center"
                    :hoverable="false"
                  >
                    <h6>
                      {{
                        moment(
                          result.oneWayFlightDetails.ListOfFlightSegments[0]
                            .DepartureDate
                        ).format("DD-MM-YYYY")
                      }}
                    </h6>
                  </a-card-grid>
                  <a-card-grid
                    style="width: 15%; text-align: center"
                    :hoverable="false"
                  >
                    <h6>
                      {{
                        result.oneWayFlightDetails.ListOfFlightSegments[0]
                          .DisplayDepartureTime
                      }}
                    </h6>
                  </a-card-grid>
                  <a-card-grid
                    style="
                      width: 40%;
                      text-align: center;
                      padding: 18.5px 0px 10.5px;
                    "
                    :hoverable="false"
                  >
                    <a-row>
                      <a-col :span="8">
                        <h6>
                          {{
                            result.oneWayFlightDetails.ListOfFlightSegments[0]
                              .DepartureAirField.City
                          }}<br />
                          <small>{{
                            result.oneWayFlightDetails.ListOfFlightSegments[0]
                              .DepartureAirField.IATACode
                          }}</small>
                        </h6>
                      </a-col>
                      <a-col :span="8">
                        {{ result.oneWayFlightDetails.TotalDurationHrs }} h
                        {{ result.oneWayFlightDetails.TotalDurationMinutes }}
                        mins
                        <br />
                        <small
                          v-if="
                            result.oneWayFlightDetails.ListOfFlightSegments
                              .length != 1
                          "
                        >
                          {{
                            result.oneWayFlightDetails.ListOfFlightSegments
                              .length - 1
                          }}
                      {{$t('addcomm.Stop')}} 
                        </small>
                        <small v-else>{{$t('addcomm.Non-Stop')}} </small>
                      </a-col>
                      <a-col :span="8">
                        <h6>
                          {{
                            result.oneWayFlightDetails.ListOfFlightSegments[
                              result.oneWayFlightDetails.ListOfFlightSegments
                                .length - 1
                            ].ArrivalAirField.City
                          }}<br />
                          <small>{{
                            result.oneWayFlightDetails.ListOfFlightSegments[
                              result.oneWayFlightDetails.ListOfFlightSegments
                                .length - 1
                            ].ArrivalAirField.IATACode
                          }}</small>
                        </h6>
                      </a-col>
                    </a-row>
                  </a-card-grid>
                  <a-card-grid
                    style="width: 15%; text-align: center"
                    :hoverable="false"
                  >
                    <h6>
                      {{
                        result.oneWayFlightDetails.ListOfFlightSegments[
                          result.oneWayFlightDetails.ListOfFlightSegments
                            .length - 1
                        ].DisplayArrivalDate
                      }}
                    </h6>
                  </a-card-grid>
                  <a-card-grid
                    style="width: 15%; text-align: center"
                    :hoverable="false"
                  >
                    <h6>
                      {{
                        result.oneWayFlightDetails.ListOfFlightSegments[
                          result.oneWayFlightDetails.ListOfFlightSegments
                            .length - 1
                        ].DisplayArrivalTime
                      }}
                    </h6>
                  </a-card-grid>
                </div>
              </div>
              <div class="row" v-if="result.returnFlightDetails != undefined">
                <div class="col-md-12">
                  <a-card-grid
                    style="width: 15%; text-align: center"
                    :hoverable="false"
                  >
                    <h6>
                      {{
                        moment(
                          result.returnFlightDetails.ListOfFlightSegments[
                            result.returnFlightDetails.ListOfFlightSegments
                              .length - 1
                          ].DepartureDate
                        ).format("DD-MM-YYYY")
                      }}
                    </h6>
                  </a-card-grid>
                  <a-card-grid
                    style="width: 15%; text-align: center"
                    :hoverable="false"
                  >
                    <h6>
                      {{
                        result.returnFlightDetails.ListOfFlightSegments[
                          result.returnFlightDetails.ListOfFlightSegments
                            .length - 1
                        ].DisplayDepartureTime
                      }}
                    </h6>
                  </a-card-grid>
                  <a-card-grid
                    style="
                      width: 40%;
                      text-align: center;
                      padding: 18.5px 0px 10.5px;
                    "
                    :hoverable="false"
                  >
                    <a-row>
                      <a-col :span="8">
                        <h6>
                          {{
                            result.returnFlightDetails.ListOfFlightSegments[0]
                              .DepartureAirField.City
                          }}<br />
                          <small>{{
                            result.returnFlightDetails.ListOfFlightSegments[0]
                              .DepartureAirField.IATACode
                          }}</small>
                        </h6>
                      </a-col>
                      <a-col :span="8">
                        {{ result.returnFlightDetails.TotalDurationHrs }} h
                        {{ result.returnFlightDetails.TotalDurationMinutes }}
                        mins
                        <br />
                        <small
                          v-if="
                            result.returnFlightDetails.ListOfFlightSegments
                              .length != 1
                          "
                        >
                          {{
                            result.returnFlightDetails.ListOfFlightSegments
                              .length - 1
                          }}
                         {{$t('addcomm.Stop')}}
                        </small>
                        <small v-else>  {{$t('addcomm.Non-Stop')}}  </small>
                      </a-col>
                      <a-col :span="8">
                        <h6>
                          {{
                            result.returnFlightDetails.ListOfFlightSegments[
                              result.returnFlightDetails.ListOfFlightSegments
                                .length - 1
                            ].ArrivalAirField.City
                          }}<br />
                          <small>{{
                            result.returnFlightDetails.ListOfFlightSegments[
                              result.returnFlightDetails.ListOfFlightSegments
                                .length - 1
                            ].ArrivalAirField.IATACode
                          }}</small>
                        </h6>
                      </a-col>
                    </a-row>
                  </a-card-grid>
                  <a-card-grid
                    style="width: 15%; text-align: center"
                    :hoverable="false"
                  >
                    <h6>
                      {{
                        result.returnFlightDetails.ListOfFlightSegments[
                          result.returnFlightDetails.ListOfFlightSegments
                            .length - 1
                        ].DisplayArrivalDate
                      }}
                    </h6>
                  </a-card-grid>
                  <a-card-grid
                    style="width: 15%; text-align: center"
                    :hoverable="false"
                  >
                    <h6>
                      {{
                        result.returnFlightDetails.ListOfFlightSegments[
                          result.returnFlightDetails.ListOfFlightSegments
                            .length - 1
                        ].DisplayArrivalTime
                      }}
                    </h6>
                  </a-card-grid>
                </div>
              </div>
              <template slot="actions" class="ant-card-actions">
                <div style="text-align: left">
                  <a-icon
                    key="question-circle"
                    type="question-circle"
                    :style="{ fontSize: '16px' }"
                  />
                  <span style="line-height: 35px; margin: 5px;position:absolute;top:-2px;"
                    >{{$t('addcomm.Flight Details')}}</span
                  >
                </div>
                <div style="text-align: right">
                  <a-button
                    type="danger"
                    size="large"
                    @click="gotoNextPage(result, index)"
                    >{{$t('addcomm.Book Now')}}</a-button
                  >
                </div>
              </template>
            </a-card>
          </div>
        </div>
        <div class="row" style="margin-bottom: 35px">
          <a-pagination
            :total="filteredPayload.length"
            @change="pagination"
            :pageSize="pageSize"
            v-if="filteredPayload.length !== 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlightAirlineFLTR from "@/views/modules/flight/components/FlightAirlineFLTR.vue";
import FlightStopsFLTR from "@/views/modules/flight/components/FlightStopsFLTR.vue";
import FlightSearchPanel from "@/views/modules/flight/components/FlightSearchPanel.vue";
import { i18n } from '@/i18n';
import moment from "moment";

export default {
  name: "FlightSearch",
  components: {
    "flight-search-panel": FlightSearchPanel,
    "flight-airline-filter": FlightAirlineFLTR,
    "flight-stops-filter": FlightStopsFLTR,
  },
  data() {
    return {
      airlineFilters: [],
      stopsFilters: [],
      pointsFilters: [],
      durationFilters: [],
      departureTimeFilters: [],
      arrivalTimeFilters: [],
      currentPage: 1,
      startIndex: 0,
      endIndex: 10,
      pageSize: 10,
    };
  },

  updated() {
    // this.pagination(1);
    // console.log(this.currentPage);
    // this.$nextTick(() {
    //   this.pagination(1);
    // });
  },

  methods: {
    moment,
    pagination(event) {
      this.currentPage = event;
      this.startIndex = (this.currentPage - 1) * this.pageSize;
      this.endIndex = this.startIndex + this.pageSize;
      window.scroll(0, 0);
    },
    gotoNextPage(selectedFlight, index) {
      if (this.$store.getters.IsUserLoggedIn) {
        const pts = this.$store.getters.points || 0;
        const selectedFlightPts = selectedFlight.Fare;
        if (selectedFlightPts > pts) {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: i18n.t("addcomm.Insufficient Points"),
          });
        } else {
          this.$store.commit("flight/setSelectedFlight", selectedFlight);
          this.$store.commit(
            "flight/setSelectedItinerary",
            this.$store.getters["flight/airSearchRequest"].ItineraryDetailsList[
              index
            ]
          );
          this.$store.dispatch("navigate", { c2: "flight-booking" });
        }
      } else {
        this.$store.dispatch("navigate", { c2: "login" });
      }
    },
  },

  computed: {
    filteredPayload() {
      // this.pagination(1);
      return this.$store.getters["flight/filteredPayload"]({
        airlineFilters: this.airlineFilters,
        stopsFilters: this.stopsFilters,
        pointsFilters: this.pointsFilters,
        durationFilters: this.durationFilters,
        departureTimeFilters: this.departureTimeFilters,
        arrivalTimeFilters: this.arrivalTimeFilters,
      });
    },
  },
};
</script>

<style scoped>
.ant-card >>> .ant-card-head-title {
  text-align: left !important;
}

.ant-card >>> .ant-card-body {
  padding: 0 !important;
}

.ant-card >>> .ant-card-actions > li {
  padding: 0 15px;
}

.ant-card >>> .ant-card-actions > li > span {
  line-height: 35px;
}
</style>