<template>
  <div>
    <!-- <div style="marging-top: 5%; margin-left: 5%; margin-right: 5%" v-if="false">
    <br />
    <h5>Hotel Booking Successful</h5>
    <br />
    <img src="./icons/success.gif" height="200px" width="200px" />
    <div class="row" v-if="hotelBookingResponse !== ''">
      <h5>
        TransactionRefCode:
        {{ hotelBookingResponse.BookingResponse.TransactionRefCode }}
      </h5>
      <h5>Booking Id: {{ hotelBookingResponse.BookingResponse.bookingid }}</h5>
      <h5>
        Confirmation Number:
        {{ hotelBookingResponse.BookingResponse.confirmationnumber }}
      </h5>
    </div>
  </div> -->
    <div class="row">
      <div class="col-md-2"></div>
      <div
        class="col-md-8"
        v-if="!isProcessing"
        style="margin-top: 3%; margin-bottom: 5%"
      >
        <div style="padding: 15px; border: 1px solid; border-radius: 4px">
          <div class="row" style="text-align: left">
            <h6>
             {{$t('addcomm.Thank You')}} {{ hotelBookingRequest.Customer.title }}
              {{ hotelBookingRequest.Customer.firstname }}
              {{ hotelBookingRequest.Customer.lastname }},  {{$t('addcomm.Your booking is now')}}
               {{$t('addcomm.confirmed')}}
            </h6>
            <h6>
              {{$t('addcomm.Voucher No')}}:
              {{ hotelBookingResponse.BookingResponse.confirmationnumber || "" }}
            </h6>
            <h6>
                {{$t('addcomm.Booking Id')}}:
              {{ hotelBookingResponse.BookingResponse.bookingid || "" }}
            </h6>
            <h6>
              {{$t('addcomm.Reference Number')}}:
              {{ hotelBookingResponse.BookingResponse.TransactionRefCode || "" }}
            </h6>
            <h6>
              {{$t('details.Total Points')}}:
              {{ hotelRepriceResponse.roomrates.RoomRate[0].ActualPoints }}
                {{$t('common.Points')}}
            </h6>
            <h6>
              {{$t('common.Room Details')}}:
              {{
                hotelRepriceResponse.roomrates.RoomRate[0].roomtype
                  .roomdescription
              }}
            </h6>
          </div>
          <div class="row">
            <div class="col-md-6" style="text-align: left">
              <a-card size="small" title="Booking Details" style="width: 100%">
                <h6>
                  {{$t('common.Your')}}: {{ hotelSearchRequest.noOfRooms }} {{$t('common.Room')}},
                  {{ hotelRepriceResponse.roomrates.RoomRate.length }} {{$t('common.Night')}}
                </h6>
                <h6>
                  {{$t('common.Check-In')}}:
                  {{ hotelSearchRequest.checkInDate }}
                </h6>
                <h6>
                    {{$t('common.Check-Out')}}:
                  {{ hotelSearchRequest.checkOutDate }}
                </h6>
                <h6>
                  {{$t('common.Booked By')}}: {{ hotelBookingRequest.Customer.title }}
                  {{ hotelBookingRequest.Customer.firstname }}
                  {{ hotelBookingRequest.Customer.lastname }}
                </h6>
                <h6>  {{$t('common.Email')}}: {{ hotelBookingRequest.Customer.email }}</h6>
                <h6> {{$t('common.Mobile')}}: {{ hotelBookingRequest.Customer.mobile }}</h6>
                <h6>
                   {{$t('Address')}}: {{ hotelBookingRequest.Customer.city }}
                  {{ hotelBookingRequest.Customer.postalcode }},
                  {{ hotelBookingRequest.Customer.country }}
                </h6>
              </a-card>
            </div>
            <div class="col-md-6" style="text-align: left">
              <a-card size="small" title="Hotel Details" style="width: 100%">
                <h6>
                   {{$t('addcomm.Hotel Name')}}: {{ hotelRepriceResponse.basicinfo.hotelname }}
                </h6>
                <h6>
                   {{$t('Address')}}: {{ hotelRepriceResponse.basicinfo.address }},
                  {{ hotelRepriceResponse.basicinfo.city }},
                  {{ hotelRepriceResponse.basicinfo.country }}
                </h6>
                <h6>
                 {{$t('addcomm.Contact')}}:
                  {{ hotelRepriceResponse.basicinfo.communicationinfo.phone }}
                </h6>
                <h6>
                  {{$t('addcomm.Fax')}}:
                  {{ hotelRepriceResponse.basicinfo.communicationinfo.fax }}
                </h6>
                <h6>
                   {{$t('addcomm.Web')}}:
                  {{ hotelRepriceResponse.basicinfo.communicationinfo.website }}
                </h6>
                <h6> {{$t('addcomm.Rating')}}: {{ hotelRepriceResponse.basicinfo.starrating }}</h6>
              </a-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8" v-if="isProcessing" style="margin-top: 3%">
        <div
          class="col-md-9"
          style="height: 100%; z-index: 10; margin-left: 12%; margin-top: 5%"
        >
          <a-spin
            size="large"
            class="ht-100"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hotelBookingResponse: "",
      hotelSearchRequest: "",
      hotelBookingRequest: "",
      hotelRepriceResponse: "",
      isProcessing: true,
    };
  },
  activated() {
    this.hotelBookingResponse =
      this.$store.getters["hotel/hotelBookingResponse"] || "";
    this.hotelSearchRequest =
      this.$store.getters["hotel/hotelSearchRequest"] || "";
    this.hotelBookingRequest =
      this.$store.getters["hotel/hotelBookingRequest"] || "";
    this.hotelRepriceResponse =
      this.$store.getters["hotel/hotelRepriceResponse"] || "";
    // console.log(this.hotelBookingResponse, "hotelBookingResponse");
    // console.log(this.hotelSearchRequest, "hotelSearchRequest");
    // console.log(this.hotelBookingRequest, "hotelBookingRequest");
    // console.log(this.hotelRepriceResponse, "hotelRepriceResponse");
    this.isProcessing = false;
  },
};
</script>
<style scoped>
</style>