import {i18n} from "@/i18n";
export default {
    bookingFormValidation(vm: any) {
        const errorAlert = [];
        if (vm.pickUp.Country === "") {
            errorAlert.push(i18n.t('addcomm.Please Select PickUp Country'));
        }
        if (vm.pickUp.City === "") {
            errorAlert.push(i18n.t('addcomm.Please Select PickUp City'));
        }
        if (vm.pickUp.Location.Value === "") {
            errorAlert.push(i18n.t('addcomm.Please Select PickUp Location'));
        }
        if (vm.dropOff.dropOffDate === "") {
            errorAlert.push(i18n.t('addcomm.Please Select DropOff Date'));
        }
        if (!vm.pickUp.isReturnSameLocation) {
            if (vm.dropOff.Country === "") {
                errorAlert.push("Please Select DropOff Country");
            }
            if (vm.dropOff.City === "") {
                errorAlert.push("Please Select DropOff City");
            }
        }
        if (vm.pickUp.driverAge === "") {
            errorAlert.push("Please Enter Driver Age");
        }
        if (Number(vm.pickUp.driverAge) < 25 || Number(vm.pickUp.driverAge) > 70) {
            errorAlert.push("Driver Age should be between 25-70 Years");
        }
        return errorAlert;
    },

    driverFormValidation(vm: any) {
        const errorAlert = [];
        if (vm.driverform.firstname === "") {
            errorAlert.push("Please Enter First Name");
        }
        if (vm.driverform.lastname === "") {
            errorAlert.push("Please Enter Last Name");
        }
        if (vm.driverform.isFlightNumber) {
            if (vm.driverform.flightNo === "") {
                errorAlert.push("Please Select flight Number")
            }
        }
        return errorAlert;
    },

    removeDuplicates(arr: any) {
        return arr.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
    },

    CleanPayload(payload: any) {
        const r: any = {
            allCarTransmissionType: [],
            allCarClass: []
        };
        if (payload && payload.length > 0) {
            payload.forEach((item: any) => {
                item.Vehicle[0].CarFuel = this.SetupfuelType(item.Vehicle[0].fuelPolicy);
                item.Vehicle[0].CarClass = this.SetupCarClass(item.Vehicle[0].group);
                r.allCarTransmissionType.push(item.Vehicle[0].automatic);
                r.allCarClass.push(this.SetupCarClass(item.Vehicle[0].group))
            });
            payload.allCarTransmissionType = this.removeDuplicates(r.allCarTransmissionType).sort();
            payload.allCarClassType = this.removeDuplicates(r.allCarClass).sort();
            return payload;
        } else {
            return []
        }
    },

    SetupCarClass(group: string) {
        if (group)
            if (group.startsWith('X')) return 'Special';
            else if (group.startsWith('EM')) return 'Mini';
            else if (group.startsWith('E')) return 'Economy';
            else if (group.startsWith('C')) return 'Compact';
            else if (group.startsWith('I')) return 'Midsize';
            else if (group.startsWith('S')) return 'Standard';
            else if (group.startsWith('F')) return 'Full';
            else if (group.startsWith('P')) return 'Premium';
            else if (group.startsWith('L')) return 'Luxury';
            else return group
    },

    SetupfuelType(fuelPolicy: string) {
        if (fuelPolicy == "0") return "Undefine";
        if (fuelPolicy == "1") return "Full to Full";
        if (fuelPolicy == "2") return "Prepay, no Refunds";
        if (fuelPolicy == "3") return "Prepay, with Refunds";
        if (fuelPolicy == "4") return "Free Tank";
    },

    filterPayload(payload: any, filters: any) {
        // Filter Car Transmission Type
        if (filters.carAutomaticFilters !== undefined && filters.carAutomaticFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                if (filters.carAutomaticFilters.includes(data.Vehicle[0].automatic)) {
                    return data;
                }
            });
        }

        // Filter Car AC/Non-AC
        if (filters.carAirconFilters !== undefined && filters.carAirconFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                if (filters.carAirconFilters.includes(data.Vehicle[0].aircon === 'Yes' ? 'AC' : 'Non-AC')) {
                    return data;
                }
            });
        }

        // Filter Car Class
        if (filters.carClassFilters !== undefined && filters.carClassFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                if (filters.carClassFilters.includes(data.Vehicle[0].CarClass)) {
                    return data;
                }
            });
        }

        return payload;
    }
}