import { render, staticRenderFns } from "./sweepstake-details.vue?vue&type=template&id=361b80e8&scoped=true&"
import script from "./sweepstake-details.vue?vue&type=script&lang=js&"
export * from "./sweepstake-details.vue?vue&type=script&lang=js&"
import style0 from "./sweepstake-details.vue?vue&type=style&index=0&id=361b80e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361b80e8",
  null
  
)

export default component.exports