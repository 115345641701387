<template>
  <div>
    <div
      class="row"
      style="justify-content: space-around"
      v-if="products && products.length > 0"
    >
      <genCard
        class="m-2"
        v-for="item in products.slice(0, 4)"
        :item="item"
        :key="item.Id"
        style="height: 300px"
      />
    </div>
  </div>
</template>

<script>
import genCard from "@/components/genCard";

export default {
  components: { genCard },
  props: ["products"],
  data() {
    return {
      home: "home",
    };
  },
  methods: {
    navigate(item) {
      console.log(`🍅 Item: ${JSON.stringify(item)}`);
      const categoryId = item.CategoryId;
      const category = this.$store.getters.shopCategories.find(
        (cat) => cat.Id === categoryId
      );
      //console.log (`🍅 Category: ${JSON.stringify(category)}`)
      if (category)
        // a shop Item
        this.$store.dispatch("navigate", { c2: category.Id, d: item.Id });
      // Unfinished! TODO!
      else
        this.$store.dispatch("navigate", {
          c: "mkt",
          c2: categoryId,
          d: item.Id,
        }); // Unfinished! TODO!
    },
  },
};
</script>

<style scoped>
.card {
  align-items: center;
  padding-top: 8px;
}
.card > img {
  width: auto !important;
  max-width: 183px;

  max-height: 183px;
}
</style>