<template>
  <a-spin :spinning="spinning" size="large">
    <a-card style="text-align: left" :bordered="border">
      <div slot="title" v-if="title">{{ $t('addcomm.Flight Booking') }}</div>
      <div slot="extra">
        <a-radio-group v-model="onReturnToggle" @change="onReturn">
          <a-radio :value="false">{{ $t('addcomm.One Way') }}</a-radio>
          <a-radio :value="true">{{ $t('addcomm.Return') }}</a-radio>
        </a-radio-group>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label>{{ $t('addcomm.From') }}</label><br />
          <v-select
            v-model="form.from"
            aria-placeholder=""
            width="100%"
            :options="airFieldsFrom"
            :loading="airfields.length === 0"
            :disabled="airfields.length === 0"
            index="IATACode"
            label="SearchAirfieldDetails"
            @search="onSearchFrom"
          >
            <template #option="{ City, CountryName, IATACode, AirportName }">
              <strong>{{ City }}, {{ CountryName }}</strong>
              <br />
              <cite>{{ AirportName }} ({{ IATACode }})</cite>
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                {{ $t('addcomm.No results found for') }} <em>{{ search }}</em
                >.
              </template>
              <em style="opacity: 0.5" v-else>{{ $t('addcomm.Start typing to search') }}</em>
            </template>
          </v-select>
        </div>
        <div class="col-md-3">
          <label>{{ $t('addcomm.To') }}</label><br />
          <v-select
            v-model="form.to"
            width="100%"
            :options="airFieldsTo"
            :loading="airfields.length === 0"
            :disabled="airfields.length === 0"
            index="IATACode"
            label="SearchAirfieldDetails"
            @search="onSearchTo"
          >
            <template #option="{ City, CountryName, IATACode, AirportName }">
              <strong>{{ City }}, {{ CountryName }}</strong>
              <br />
              <cite>{{ AirportName }} ({{ IATACode }})</cite>
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                {{ $t('addcomm.No results found for') }} <em>{{ search }}</em
                >.
              </template>
              <em style="opacity: 0.5" v-else>{{ $t('addcomm.Start typing to search') }}</em>
            </template>
          </v-select>
        </div>

        <div class="col-md-3">
          <label>{{ $t('addcomm.Depart') }}</label><br />
          <a-date-picker
            size="large"
            style="width: 100%"
            v-model="form.departDate"
            :default-value="form.departDate"
            :disabled-date="disabledDate"
            :format="dateFormat"
            :placeholder="$t('addcomm.Select Departure Date')"
            @openChange="handleStartOpenChange"
            @change="updateReturnDate()"
          />
        </div>

        <div class="col-md-3" v-if="onReturnToggle">
          <label>{{ $t('addcomm.Return') }}</label><br />
          <a-date-picker
            size="large"
            style="width: 100%"
            v-model="form.returnDate"
            :default-value="form.returnDate"
            :disabled-date="disabledEndDate"
            :format="dateFormat"
            :placeholder="$t('addcomm.Select Return Date')"
            :open="endOpen"
            @openChange="handleEndOpenChange"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-3">
          <label>{{ $t('addcomm.Class') }}</label><br />
          <a-select
            v-model="form.flightClass"
            size="large"
            :default-value="form.flightClass"
            style="width: 100%"
          >
            <a-select-option
              v-for="flightClass in flightClasses"
              :key="flightClass"
              :value="flightClass"
            >
              {{ flightClass }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-md-3">
          <label>{{ $t('addcomm.Adults 12+ Yrs') }}</label><br />
          <a-input-number
            v-model="form.adultCount"
            :defaultValue="form.adultCount"
            :step="1"
            size="large"
            style="width: 100%"
            :min="1"
            :max="5"
          />
        </div>
        <div class="col-md-3">
          <label>{{ $t('addcomm.Children 2 to 11 Yrs') }}</label><br />
          <a-input-number
            v-model="form.childCount"
            :defaultValue="form.childCount"
            :step="1"
            size="large"
            style="width: 100%"
            :min="0"
            :max="5"
          />
        </div>
        <div class="col-md-3">
          <label>{{ $t('addcomm.Infants below 2 Yrs') }}</label><br />
          <a-input-number
            type="number"
            v-model="form.infantCount"
            :defaultValue="form.infantCount"
            size="large"
            style="width: 100%"
            :min="0"
            :max="5"
          />
        </div>
      </div>
      <div v-if="getErrors">
        <a-alert
          v-for="(err, index) in errorAlert"
          :key="err"
          :message="err"
          type="error"
          closable
          class="mt-3"
          @close="handleErrorClose(index)"
          show-icon
        />
      </div>

      <div class="row mt-3">
        <div class="col-md-12" style="text-align: right">
          <a-button
            @click.prevent="submitSearch"
            type="primary"
            size="large"
            class="btn-block"
            >{{ $t('common.search') }}</a-button
          >
        </div>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import moment from "moment";
import debounce from "lodash/debounce";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters, mapMutations } from "vuex";
import Helper from "../Helper";
import {i18n} from "@/i18n";

export default {
  name: "flight-search-panel",
  components: {
    vSelect,
  },

  props: {
    showErrors: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: i18n.t('addcomm.Flight Booking'),
    },
  },

  model: {
    props: ["showErrors", "border", "title"],
    event: "callback",
  },

  data() {
    return {
      form: {},
      airFieldsFrom: [],
      airFieldsTo: [],
      flightClasses: [i18n.t('addcomm.Economy'), i18n.t('addcomm.Business'), i18n.t('addcomm.First')],
      dateFormat: "DD/MM/YYYY",
      endOpen: false,
      onReturnToggle: true,
      value: undefined,
      spinning: false,
      errorAlert: [],
    };
  },

  created() {
    this.form = this.$store.getters["flight/defaultForm"];
    this.$store.dispatch("flight/loadAllAirFields", this);
  },

  mounted() {
    this.spinning = false;
  },

  methods: {
    ...mapMutations({
      resetAirSearchRequest: "flight/resetAirSearchRequest",
    }),

    onReturn(e) {
      if (!e.target.value) {
        this.form.returnDate = this.form.departDate;
      } else {
        this.form.returnDate = "";
      }
    },

    updateReturnDate() {
      this.form.returnDate = moment(this.form.departDate).endOf("day");
    },

    disabledDate(current) {
      // Can not select days before today
      return current && current < moment().endOf("day");
    },

    disabledEndDate(returnDate) {
      const departDate = this.form.departDate;
      if (!returnDate || !departDate) {
        return false;
      }
      return departDate.valueOf() >= returnDate.valueOf();
    },

    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },

    handleEndOpenChange(open) {
      this.endOpen = open;
    },

    onSearchFrom(sought, loading) {
      if (sought.trim().length >= 3) {
        loading(true);
        const updator = debounce(() => {
          const result = this.airfields.filter(
            (c) =>
              c.SearchAirfieldDetails.toLowerCase().indexOf(
                sought.trim().toLowerCase()
              ) != -1
          );
          loading(false);
          this.airFieldsFrom = result;
        }, 1000);
        return updator();
      } else {
        this.airFieldsFrom = [];
      }
    },

    onSearchTo(sought, loading) {
      if (sought.trim().length >= 3) {
        loading(true);
        const updator = debounce(() => {
          const result = this.airfields.filter(
            (c) =>
              c.SearchAirfieldDetails.toLowerCase().indexOf(
                sought.trim().toLowerCase()
              ) != -1
          );
          loading(false);
          this.airFieldsTo = result;
        }, 1000);
        return updator();
      } else {
        this.airFieldsTo = [];
      }
    },

    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },

    async submitSearch() {
      this.errorAlert = Helper.bookingFormValidation(this);
      this.$emit("callback", {
        errors: this.errorAlert,
        status: false,
      });
      //
      if (this.errorAlert.length == 0) {
        this.resetAirSearchRequest();
        this.spinning = true;
        try {
          this.form.onReturnToggle = this.onReturnToggle;
          this.form.MemberId =
            this.$store.getters.memberDetails.MemberRelationsList[0]
              .RelationReference || "";
          await this.$store.dispatch("flight/airSearchRequest", this.form);
        } catch (e) {
          e.filter((d) => this.errorAlert.push(d));
        } finally {
          this.$emit("callback", {
            errors: this.errorAlert,
            status: this.errorAlert.length == 0 ? true : false,
          });
          this.spinning = false;
        }
      }
    },
  },

  computed: {
    ...mapGetters({
      airfields: "flight/allAirFields",
      callBack: "flight/airSearchRequest",
    }),

    getErrors() {
      return !this.showErrors ? 0 : this.errorAlert.length >= 1;
    },
  },
};
</script>
