import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
Vue.use (VueRouter)

//import Auth from '@/views/modules/Auth/authRoutes';
import store from '@/store/index'
import buyablesService from "@/api/buyablesService";

//

const baseRoutes: Array<RouteConfig> = [
  { path: '/', },
  { path: '/404NotFound' },
  { path: '/context/:context' },
  { path: '/detail/:id' },
  { path: '/context/:context/detail/:id', },
  { path: '/context/:context/undercontext/:undercontext' },
  { path: '/context/:context/undercontext/:undercontext' },
  { path: '/context/:context/undercontext/:undercontext/detail/:id' },
  { path: '/undercontext/:undercontext' },
  { path: '/*' }
]
const routes = baseRoutes

const router = new VueRouter({
  mode: 'history',
  routes
  })

router.beforeEach((to, from, next) => {
  //console.log ("🔥  real nav to " + to.path + " to all props are " + JSON.stringify (to) + " while we are at " + router.currentRoute.path)
  let c = to.params.context
  let c2 = to.params.undercontext
  const id = to.params.id
  if (c || c2) {
    if (!c) c = store.getters.navContext
    if (!c2) c2 = store.getters.underNavcontext
    const b = [store.getters.startBlock, store.getters.countPerBlock]
    //store.commit('mutNavContext', { c: c, c2: c2})    
    buyablesService.getBuyables (c, c2, "", b)
    .then (() => {
      store.commit('mutNavContext', {c: c, c2: c2, id: id})
      next()  
      })
    }
  else {
    // Just changing product, we're keeping:
    //c == store.getters.navContext
    //c2 == store.getters.underNavContext
    store.commit('mutNavContext', { id: id})
    next()
    }  
  })

// Added by PS:
window.onpopstate = function () {
  console.log ("🔥 A pop! we are currently at " + router.currentRoute.path + " going to " + window.location.pathname)
  router.push(window.location.pathname).catch(() => { })
  }

export default router