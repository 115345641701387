<template>
  <div v-if="relatedItems && relatedItems.length > 0">
    <!-- <div >
      <div class="row recently-viewed">
        <h5>
          <b>Related Items</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a @click="$store.dispatch ('navigate', {c: navContext})">
            <span style="font-size: 14px"> (View All)</span>
          </a>
        </h5>
        <br />
        <div
          class="col-md-3 card"
          v-for="item in relatedItems.slice(0, 5 || relatedItems.length)"
          :key="item.underlying_id || item.Id || item.id"
          @click="relatedItemClicked(item)"
        >
          <br />
          <img
            class="card-img-top"
            height="50%"
            width="100%"
            :src="item.imgs"
            :alt="item.name"
          />
          <div class="card-body">
            <h6 class="card-title" v-if="item.name.length > 18">
              {{ item.name.substring(0, 18) + "..." }}
            </h6>
            <h6 class="card-title" v-if="item.name.length < 18">
              {{ item.name }}
            </h6>
            <h6>
              From:
              {{
                item.units[0].price.toFixed(2) || item.units[0].min.toFixed(2)
              }}
              Points,   Country: {{ item.country }}
            </h6>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["relatedBuyables"],
  data() {
    return {
      relatedItems: [],
      navContext: "",
    };
  },
  created() {
    this.relatedItems = this.relatedBuyables.relatedItems;
    this.navContext = this.relatedBuyables.navContext;
    // console.log(this.relatedItems, this.navContext);
  },
  methods: {
    relatedItemClicked(item) {
      const d = item.underlying_id || item.Id || item.id;
      console.log(`    🛍️  related items: ${this.navContext} and id=${d}`);

      this.$store.dispatch("navigate", { c: this.navContext, d: d });
    },
  },
};
</script>

<style scoped>
.recently-viewed {
  /* padding-left: 5%; */
  /* text-align: left; */
}

.recently-viewed .card {
  width: 14rem;
  height: 14rem;
  margin-left: 2%;
  cursor: pointer;
}
</style>