<template>
  <div  style="width: 100%">
    <div class="row login" >
      <div class="col-md-4"></div>
      <div class="col-md-4" v-if="showActivateForm">
        <form class="login-form">
          <h6>{{ $t('common.Activate Account') }}</h6>
          <br />
          <div class="mb-3">
            <label for="email" class="form-label">{{ $t('common.Email address') }}</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="email"
            />
          </div>
          <a-button type="primary" block @click.prevent="submitBid">
            {{ $t('common.Submit') }}
          </a-button>
        </form>
      </div>
      <div class="col-md-4" v-if="showVerifyOtpForm">
        <form class="login-form">
          <h6>{{ $t('common.Verify OTP') }}</h6>
          <br />
          <div class="mb-3">
            <label for="email" class="form-label">{{ $t('addcomm.Enter OTP') }}</label>
            <input type="text" class="form-control" id="otp" v-model="otp" />
          </div>
          <div>
            <vue-countdown
              :time="600000"
              @end="onCountdownEnd"
              v-slot="{ minutes, seconds }"
            >
              {{ $t('addcomm.OTP Expires in') }}：{{ minutes }} {{ $t('minutes') }} {{ seconds }} {{ $t('seconds') }}.
            </vue-countdown>
          </div>
          <a-button
            type="primary"
            block
            @click.prevent="callVerifyOtp"
            :disabled="disableVerifyOtp"
          >
            {{ $t('common.Submit') }}
          </a-button>
        </form>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>
<script>

import VueCountdown from "@chenfengyuan/vue-countdown"
import router from '@/router/index'
import {i18n} from "@/i18n";
import LBMSService from "@/api/lbmsService";
export default {
  components: { VueCountdown },
  data() {
    return {
      email: "",
      otp: "",
      showActivateForm: true,
      showVerifyOtpForm: false,
      isProcessing: true,
      disableVerifyOtp: false,
      userName: "",
    };
  },
  computed: {
    programDefinition: function() {return this.$store.getters.programDefinition},
    systemParameter: function() {return this.$store.getters.systemParameter}
    },
  methods: {
    submitBid() {
      const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regexEmail.test(this.email.toLowerCase())) {
        this.$notification["error"]({
          message: i18n.t('addcomm.Error'),
          description: i18n.t('addcomm.Please Enter Valid Email'),
        });
        return;
      }
      if (this.email === "") {
        this.$notification["error"]({
          message: i18n.t('addcomm.Error'),
          description: i18n.t('addcomm.Please Enter Email'),
        });
        return;
      }
      // Generate OTP
      LBMSService.GenerateOTP({
        UniquerefID: this.email,
        SourceCode: 1,
        SourceAddress: "192.168.46.121",
        OtpType: "ACTIVATION",
        ProgramId: this.programDefinition.program_id,
        RelationType: this.$store.getters.relationType,
        AddExpirationTimeInMinutes: this.systemParameter.OTPExpirationTime,
      }).then(
        (response) => {
          // console.log(response);
          if (response.data.results.IsSuccessful) {
            this.showActivateForm = false;
            this.showVerifyOtpForm = true;
          } else {
            this.$notification["error"]({
              message: i18n.t('addcomm.Error'),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    },
    callVerifyOtp() {
      if (this.otp === "") {
        this.$notification["error"]({
          message: i18n.t('addcomm.Error'),
          description: i18n.t('addcomm.Please Enter OTP'),
        });
        return;
      }
      LBMSService.VerifyOTP({
        UniquerefID: this.email,
        OTP: Number(this.otp),
        DestinationAddress: "192.168.46.111",
        Destination: "Web",
        OtpType: "FORGOTPWD",
        ProgramId: Number(this.programDefinition.program_id),
        RelationType: Number(this.$store.getters.relationType),
      }).then(
        (response) => {
          // console.log(response);
          if (response.data.results.IsSuccessful) {
            const data = {
              email: this.email,
              type: "Activate",
            };
            router.push({
              name: "password",
              params: data,
            }).catch(() => { });
          } else {
            this.$notification["error"]({
              message: i18n.t('addcomm.Error'),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    onCountdownEnd() {
      this.disableVerifyOtp = true;
    },
  },
};
</script>
<style scoped>
.login {
  width: 100%;
  height: 100%;
}

.login-form {
  margin-top: 15%;
  /* text-align: left; */
}

.btn {
  width: 100%;
}
</style>
