<template>
  <div class="container" style="margin-top: 15px">
    <car-search-panel :show-errors="true" v-on:callback="redirection" />
  </div>
</template>

<script>
import CarSearchPanel from "../../modules/car/components/CarSearchPanel";

export default {
  components: {
    "car-search-panel": CarSearchPanel,
  },
  methods: {
    redirection(e) {
      console.log(e);
      if (e.status) {
        // console.log(this.$store.getters["car/carSearchResults"]);
        this.$store.dispatch("navigate", { c2: "car-results" });
      }
    },
  },
};
</script>