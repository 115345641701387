<template>
  <div  style="width: 100%">
    <div class="row">
      <div>
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <br />
            <UserManageNav></UserManageNav>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4" v-if="!isProcessing"  style="text-align: left">
            <br />
            <vue-form-generator
              :schema="schema"
              :model="model"
              :options="formOptions"
            ></vue-form-generator>
            <a-button type="primary" block @click.prevent="submit">
              Submit
            </a-button>
            <br /><br />
          </div>
          <div class="col-md-4" v-if="isProcessing">
            <br/><br/>
            <a-skeleton :loading="true" active></a-skeleton>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import UserManageNav from "../../../components/userManageNav.vue";
import { i18n } from '@/i18n';
export default {
  components: { UserManageNav },
  data() {
    return {
      userForm: {},
      isUserFormValid: false,
      isProcessing: false,
      model: {
        // FirstName: "",
        // LastName: "",
        // Address1: "",
        // MobileNumber: "",
        // Nationality: "male",
        // PassportNumber: "",
        // Gender: "",
        // Email: "",
      },
      schema: {
        fields: [],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  computed: {
    programDefinition: function() {return this.$store.getters.programDefinition},
    },
  beforeMount() {
    this.isProcessing = true;
  },
  mounted() {
    const postdata = {
      ProgramId: this.programDefinition.program_id,
      RelationReference: this.$store.getters.relationReference,
      RelationType: this.$store.getters.relationType,
      }
    this.$axios
      .post("/api/callback/v1/lbms/user/GetMemberDetailsForLBMS", postdata)
      .then(
        (response) => {
          // console.log(response);
          if (response.status === 200 && response.data.results.IsSuccessful) {
            this.userForm = response.data.results.ReturnObject;
            this.model["FirstName"] = this.userForm.FirstName;
            this.model["LastName"] = this.userForm.LastName;
            this.model["Address1"] = this.userForm.Address1;
            this.model["MobileNumber"] = this.userForm.MobileNumber;
            this.model["Nationality"] = this.userForm.Nationality;
            this.model["PassportNumber"] = this.userForm.PassportNumber;
            this.model["Gender"] = this.userForm.Gender;
            this.model["Email"] = this.userForm.Email;

            this.schema.fields.push(
              {
                type: "input",
                inputType: "text",
                label: "First name",
                model: "FirstName",
                placeholder: "User's First name",
                required: true,
                validator: VueFormGenerator.validators.string,
              },
              {
                type: "input",
                inputType: "text",
                label: "Last name",
                model: "LastName",
                placeholder: "User's Last name",
                required: true,
                validator: VueFormGenerator.validators.string,
              },
              {
                type: "input",
                inputType: "text",
                label: "Email",
                model: "Email",
                placeholder: "User's Email",
                required: true,
                validator: VueFormGenerator.validators.string,
              },
              {
                type: "input",
                inputType: "text",
                label: "Address",
                model: "Address1",
                placeholder: "User's Address",
                required: this.userForm.Address1.mandatory,
                validator: VueFormGenerator.validators.string,
              },
              {
                type: "input",
                inputType: "text",
                label: "Mobile Number",
                model: "MobileNumber",
                placeholder: "User's Mobile Number",
                required: this.userForm.MobileNumber.mandatory,
                validator: VueFormGenerator.validators.string,
              },
              {
                type: "input",
                inputType: "text",
                label: "Nationality",
                model: "Nationality",
                placeholder: "User's Nationality",
                required: this.userForm.Nationality.mandatory,
                validator: VueFormGenerator.validators.string,
              },
              {
                type: "input",
                inputType: "text",
                label: "Passport Number",
                model: "PassportNumber",
                placeholder: "User's Passport Number",
                required: this.userForm.PassportNumber.mandatory,
                validator: VueFormGenerator.validators.string,
              },
              {
                type: "select",
                inputType: "dropdown",
                label: "Gender",
                model: "Gender",
                placeholder: "User's Gender",
                values: ["M", "F"],
                // required: this.userForm.Gender.mandatory,
                validator: VueFormGenerator.validators.string,
              }
            );
            this.isProcessing = false;
          } else {
            this.isProcessing = false;
            this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  },
  methods: {
    submit() {
      this.userForm.FirstName = this.model["FirstName"];
      this.userForm.LastName = this.model["LastName"];
      this.userForm.Address1 = this.model["Address1"];
      this.userForm.MobileNumber = this.model["MobileNumber"];
      this.userForm.Nationality = this.model["Nationality"];
      this.userForm.PassportNumber = this.model["PassportNumber"];
      this.userForm.Gender = this.model["Gender"];
      this.userForm.Email = this.model["Email"];

      this.$axios
        .post("/api/callback/v1/lbms/user/UpdateProfile", this.userForm)
        .then(
          (response) => {
            if (response.status === 200) {
              this.$notification["success"]({
                message: i18n.t('addcomm.Success'),
                description: i18n.t('addcomm.Profile Updated successfully')
              });
            } else {
              this.$notification["error"]({
                message: i18n.t("addcomm.Error"),
                description: response.data.results.ErrorDescription,
              });
            }
          },
          (error) => {
            this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: error.message,
            });
            console.log(error);
          }
        );
    },
  },
};
</script>

<style scoped>
</style>