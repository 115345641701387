<template>
  <div class="container" style="margin-top: 15px">
    <div class="row" v-if="!processing">
      <div class="col-md-8">
        <div class="row">
          <div
            class="col-md-12"
            v-if="getFlightDetails.ListOfFlightDetails != undefined"
          >
            <flight-details />
          </div>
        </div>

        <a-card :bordered="false" style="margin-top: 15px">
          <!-- text-align: left;  -->
          <div slot="title" style="font-size: 16px">
            Passenger Details
          </div>
          <flight-passenger-info
            :formDetails="formDetails"
          ></flight-passenger-info>
        </a-card>
      </div>
      <div class="col-md-4">
        <a-card>
          <div slot="title" style="text-align: left">Redeem Points</div>

          <h4 style="text-weight: bold">
            {{ getFlightDetails.Fare }} <small>Points</small>
          </h4>
          <template slot="actions" class="ant-card-actions">
            <a-button type="primary" size="large" @click="gotoNextPage()">
              Proceed to Checkout
            </a-button>
          </template>
        </a-card>
        <div class="row">
          <div v-if="getErrors">
            <a-alert
              v-for="(err, index) in errorAlert"
              :key="index"
              :message="err"
              type="error"
              closable
              class="mt-3"
              @close="handleErrorClose(index)"
              show-icon
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="processing"
      class="col-md-9"
      style="height: 100%; z-index: 10; margin-left: 12%"
    >
        <a-spin
          size=large
          class="ht-100"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        />
    </div>
  </div>
</template>

<script>
import FlightDetails from "@/views/modules/flight/components/FlightDetails.vue";
import FlightPassengerInfo from "@/views/modules/flight/components/FlightPassengerInfo.vue";
import moment from "moment";
import FlightAPI from "@/api/ibe/flight";
import { i18n } from '@/i18n';
export default {
  data() {
    return {
      formDetails: [],
      errorAlert: [],
      processing: false,
      passengers: {},
    };
  },

  created() {
    this.initView();
  },

  activated() {
    this.initView();
  },

  watch: {
    airlineFilters: function () {
      this.$emit("input", this.airlineFilters);
    },

    getBookingForm(d) {
      this.passengers = this.getPassengerCount(d);
    },
  },

  components: {
    "flight-details": FlightDetails,
    "flight-passenger-info": FlightPassengerInfo,
  },

  methods: {
    initView() {
      this.formDetails = [];
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
      if (
        this.$store.getters["flight/selectedFlight"].ListOfFlightDetails ==
        undefined
      ) {
        this.$store.dispatch("navigate", { c2: "flight" });
      } else {
        this.passengers = this.getPassengerCount(this.getBookingForm);
        this.setUpPassengerForm();
      }
    },

    setUpPassengerForm() {
      for (let i = 0; i < this.passengers.Adult; i++) {
        this.formDetails.push({
          title: "Adult " + (i + 1),
          prefix: "Mr",
          firstName: "",
          lastName: "",
          Gender: "Male",
          dob: null,
          issuePassDate: null,
          expirePassDate: null,
          email: "",
          passNo: "",
          Nationality: null,
        });
      }
      for (let i = 0; i < this.passengers.Children; i++) {
        this.formDetails.push({
          title: "Children " + (i + 1),
          prefix: "Mr",
          firstName: "",
          lastName: "",
          Gender: "Male",
          dob: null,
          issuePassDate: null,
          expirePassDate: null,
          email: "",
          passNo: "",
          Nationality: null,
        });
      }
      for (let i = 0; i < this.passengers.Infant; i++) {
        this.formDetails.push({
          title: "Infant " + (i + 1),
          prefix: "Mr",
          firstName: "",
          lastName: "",
          Gender: "Male",
          dob: null,
          issuePassDate: null,
          expirePassDate: null,
          email: "",
          passNo: "",
          Nationality: "",
        });
      }
    },

    gotoNextPage() {
      if (this.$store.getters.IsUserLoggedIn) {
        const pts = this.$store.getters.points || 0;
        const selectedFlightPts = this.getFlightDetails.Fare;
        if (selectedFlightPts > pts) {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: i18n.t("addcomm.Insufficient Points")
          });
        } else {
          this.validatePassengerForm(
            this.$store.getters["flight/flightPassengerInfo"] || []
          );
        }
      } else {
        this.$store.dispatch("navigate", { c2: "login" });
      }
    },

    validatePassengerForm(flightPassengerInfo) {
      // console.log(flightPassengerInfo);
      this.errorAlert = [];
      if (flightPassengerInfo.length === 0) {
        this.errorAlert.push("Please Fill Passenger(s) Information");
      } else {
        for (let i = 0; i < flightPassengerInfo.length; i++) {
          if (flightPassengerInfo[i].firstName === "") {
            this.errorAlert.push(
              flightPassengerInfo[i].title + ": Please Enter First Name"
            );
          }
          if (flightPassengerInfo[i].lastName === "") {
            this.errorAlert.push(
              flightPassengerInfo[i].title + ": Please Enter Last Name"
            );
          }
          if (flightPassengerInfo[i].dob === null) {
            this.errorAlert.push(
              flightPassengerInfo[i].title + ": Please Enter DOB"
            );
          }
          if (flightPassengerInfo[i].email === "") {
            this.errorAlert.push(
              flightPassengerInfo[i].title + ": Please Enter Email"
            );
          }
          if (flightPassengerInfo[i].Nationality === "") {
            this.errorAlert.push(
              flightPassengerInfo[i].title + ": Please Enter Nationality"
            );
          }
          if (flightPassengerInfo[i].passNo === "") {
            this.errorAlert.push(
              flightPassengerInfo[i].title + ": Please Enter Passport Number"
            );
          }
          if (flightPassengerInfo[i].issuePassDate === null) {
            this.errorAlert.push(
              flightPassengerInfo[i].title +
                ": Please Enter Passport Issue Date"
            );
          }
          if (flightPassengerInfo[i].expirePassDate === null) {
            this.errorAlert.push(
              flightPassengerInfo[i].title +
                ": Please Enter Passport Expiry Date"
            );
          }
        }
        const TravelerInfo = [];
        if (this.errorAlert.length === 0) {
          for (let i = 0; i < flightPassengerInfo.length; i++) {
            TravelerInfo.push({
              CreatedDate: moment().format("YYYY-MM-DDT00:00:00"),
              DOB: flightPassengerInfo[i].dob,
              Age: this.calculateAge(flightPassengerInfo[i].dob),
              EmailId: flightPassengerInfo[i].email,
              FirstName: flightPassengerInfo[i].firstName,
              Gender: flightPassengerInfo[i].Gender,
              LastName: flightPassengerInfo[i].lastName,
              Nationality: flightPassengerInfo[i].Nationality,
              PassportExpiryDate: flightPassengerInfo[i].expirePassDate,
              PassportIssueDate: flightPassengerInfo[i].issuePassDate,
              PassportNumber: flightPassengerInfo[i].passNo,
              PaxType: this.calculatePaxType(flightPassengerInfo[i].title),
              Prefix: flightPassengerInfo[i].prefix,
              Country: flightPassengerInfo[i].Nationality,
            });
          }
          this.processing = true;

          // Set passengers information
          let Iternary = this.$store.getters["flight/selectedItinerary"];
          Iternary.TravelerInfo = TravelerInfo;
          console.log(TravelerInfo);
          FlightAPI.CreateItinerary({
            PointRate: 1,
            SessionId: this.$store.getters["flight/airSearchRequest"].SessionId,
            ItineraryDetails: Iternary,
          }).then((response) => {
            if (!response && response.ItineraryDetails != undefined) {
              this.processing = false;
              this.$notification["error"]({
                message: i18n.t("addcomm.Error"),
                description: i18n.t("addcomm.Unable to Create time"),
              });
            } else {
              this.processing = false;
              this.$store.commit(
                "flight/setItineraryResponse",
                response.ItineraryDetails
              );
              this.$store.dispatch("navigate", { c2: "flight-review" });
            }
          });
        }
      }
    },
    calculatePaxType(type) {
      if (type.includes("Adult")) {
        return "ADT";
      } else if (type.includes("Children")) {
        return "CHD";
      } else if (type.includes("infant")) {
        return "INF";
      }
    },
    calculateAge(birth) {
      let birthDate = moment(birth).format("L");
      let otherDate = moment().format("L");
      var years =
        new Date(otherDate).getFullYear() - new Date(birthDate).getFullYear();
      if (
        new Date(otherDate).getMonth() < new Date(birthDate).getMonth() ||
        (new Date(otherDate).getMonth() == new Date(birthDate).getMonth() &&
          new Date(otherDate).getDate() < new Date(birthDate).getDate())
      ) {
        years--;
      }
      return years;
    },
    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },
    getPassengerCount(form) {
      return {
        Adult: form.adultCount,
        Children: form.childCount,
        Infant: form.infantCount,
        total: form.adultCount + form.childCount + form.infantCount,
      };
    },
  },
  computed: {
    getErrors() {
      return this.errorAlert.length >= 1;
    },
    getBookingForm() {
      return this.$store.getters["flight/bookingForm"];
    },
    getFlightDetails() {
      return this.$store.getters["flight/selectedFlight"];
    },
  },
};
</script>

