import IBECar from "@/api/ibe/car";
import moment from "moment";
import _ from "lodash";
import Helper from "@/views/modules/car/Helper";
import state from "vue-slider-component/typings/utils/state";
import {i18n} from "@/i18n";
export default {
    namespaced: true,
    state: {
        carSearchRequest: '',
        carSearchResults: '',
        selectedCar: '',
        selectedCarId: '',
        carSearchId: '',
        carBookingResponse: '',
        carDriverInfo: '',
        carBookingRequest: '',
    },
    getters: {
        selectedCar: (state: any) => {
            console.log(state.carSearchResults);
            const allCars = state.carSearchResults;
            console.log(allCars)
            if (allCars !== '') {
                const temp = allCars.find((car: any) => car.Vehicle[0].id === state.selectedCarId);
                console.log(temp);
                return temp;
            } else return ''
        },
        carSearchId: (state: any) => state.carSearchId,
        selectedCarId: (state: any) => state.selectedCarId,
        carDriverInfo: (state: any) => state.carDriverInfo,
        carBookingRequest: (state: any) => state.carBookingRequest,
        carBookingResponse: (state: any) => state.carBookingResponse,
        carSearchRequest: (state: { carSearchRequest: any; }) => state.carSearchRequest,
        carSearchResults: (state: any) => Helper.CleanPayload(state.carSearchResults) || [],
        filteredPayload: (state: any, getters: { carSearchResults: any; }) => (data: any) => Helper.filterPayload(getters.carSearchResults, data) || [],
    },
    mutations: {
        setCarBookingResponse: (state: any, data: any) => state.carBookingResponse = data,
        setCarSearchId: (state: any, id: any) => state.carSearchId = id,
        setSelectedCarId: (state: any, id: any) => state.selectedCarId = id,
        setCarSearchResults: (state: { carSearchResults: any; }, cr: any) => state.carSearchResults = cr,
        setCarSearchRequest: function (state: { carSearchRequest: any; }, cs: any) { state.carSearchRequest = cs },
        setCarBookingRequest: (state: { carBookingRequest: any; }, cr: any) => state.carBookingRequest = cr,
        setDriverInfo:  (state: { carDriverInfo: any; }, cr: any) => state.carDriverInfo = cr,
    },
    actions: {
        carSearchRequest( {commit}: any, payload: any) {
            return new Promise((resolve, reject) => {
                // commit("setCarSearchRequest", payload);
                IBECar.CarSearchRequest(payload).then((response) => {
                    console.log(response);
                    if (response.status !== 200) {
                        const e: any[] = [];
                        response.Errors.filter((d: { ErrorMessage: any; }) => e.push(d.ErrorMessage));
                        reject('Error');
                    } else {
                        if (response.data.results.SearchResponse.MatchList[0].Match === null)
                        reject(i18n.t('addcomm.No Result Found'));
                        else 
                        commit('setCarSearchId', response.data.results.SearchId);
                        commit('setCarSearchResults', response.data.results.SearchResponse.MatchList[0].Match || []);
                        resolve(response);
                    }   
                }, (error) => {
                    reject(error);
                });
            });
        }


    }
}