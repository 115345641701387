
export default [["Air St. Maarten", "Airst-Marteen.jpg"],
["Al Hilal Bank (AHB)", "al-hilal-bank.jpg", 'https://joud.alhilalbank.ae/', 'AHBUAT'],
["Al Masraf Bank", "Al-masraf.jpg"],
["American Express", "Amex-Website-Logo-768x682.png"],
["Aryaduta Hotels", "Aaryaduta-Website-Logo.png"],
["Bank bjb", "bank-bjb-logo.jpg"],
["Bank Bukopin", "Bank-Bukopin.jpg"],
["Bank Danamon Indonesia (BDI)", "Bank-BRI.jpg"],
["Bank Islam", "Bank-Islam.jpg"],
["Bank Mayapada Indonesia (BMI)", "Bank-Mayapada-Logo.jpg"],
["Bank Negara Indonesia (BNI)", "BNI.jpg", 'https://poinplus.bni.co.id/'],
["Bank of Maldives Plc (BML)", "BML-Website-Logo.png"],
["Bank Rakyat Indonesia (BRI)", "CBI.jpg"],
["CBI UAE", "CBQ.jpg"],
["Commercial Bank of Ceylon PLC", "Commercial-Bank.jpg"],
["Commercial Bank of Qatar (CBQ)", "danamon-bdi.jpg"],
["Eastern Bank Limited (EBL)", "Earabia-Website-Logo-768x682.png"],
["Engen Mauritius Loyalty", "EBL-Website-Logo-768x682.png"],
["Erabia", "Engen-Website-Logo-768x682.png"],
["Gulf Bank", "Gulf-Bank.jpg"],
["Healthy U", "Healthy.jpg"],
["IBC Hotels", "i-Seller.jpg"],
["I-Seller", "IBC-Hotel-Web-Logo1-768x682.png"],
["KCB Bank Kenya Limited", "KCB-Web-Logo-768x682.png", 'https://simbapoints.kcbgroup.com/ke/'],
["Le District", "Le-District-Website-Logo-768x682.png"],
["Mutual Trust Bank Limited (MTB)", "Mutual-Trust-Bank.jpg"],
["Nations Trust Bank", "National Trust Bank Web Logo.png"],
["OLA Energy", "Ola-Energy-Logo.png"],
["Oman Arab bank (OAB)", "OAB-Website-Logo-2-768x682.png", 'https://oabrewards.com/'],
["Qatar Islamic Bank (QIB)", "QIB.jpg", 'http://absher.qib.com.qa/'],
["QNB Indonesia (QNBI)", "QNB.jpg"],
["realme", "realme-Website-Logo-1-768x682.png", '', "RealMe"],
["Standard Chartered", "Stand-C-Website-Logo-768x682.png"],
["TBO Holidays.com", "TBO-Website-Logo-768x682.png"],
["Two Rivers Mall", "Two-Rivers.jpg"],
["Visa", "VISA.jpg", 'https://visacards.africa/marketplace', 'visakj'],
["Winners Supermarket Mauritius", "winners.jpg"],
["Yeepay", "Yeepay.jpg"]]
