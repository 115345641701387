import axios from 'axios';
import CREDS from "@/api/creds"

export default {

    async GetAllpickUpCountry() {
        try {
            const d = await axios.get('/car/api/Car/GetAllPickUpCountry');
            if (d.data.results !== undefined) {
                const country: any[] = [];
                d.data.results[0].Country.forEach((c: { Value: any; }) => {
                    country.push(c.Value)
                });
                return country
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },

    async CarPickUpLocationRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarPickUpLocationRequest', postData);
            return d;
        } catch (e) {
            return e
        }
    },

    async CarPickUpCityRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarPickUpCityRequest', postData);
            return d.data.results[0].City.map((c: { Value: any; }) => c.Value);
        } catch (e) {
            return e;
        }
    },

    async CarDropOffCountryRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarDropOffCountryRequest', postData);
            const country: any[] = [];
            d.data.results[0].Country.forEach((c: { Value: any; }) => { country.push(c.Value) });
            return country
        } catch (e) {
            return e
        }
    },

    async CarDropOffCityRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarDropOffCityRequest', postData);
            const city: any[] = [];
            d.data.results[0].City.forEach((c: { Value: any; }) => { city.push(c.Value) });
            return city;
        } catch (e) {
            return e
        }
    },

    async CarDropOffLocationRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarDropOffLocationRequest', postData);
            return d;
        } catch (e) {
            return e
        }
    },

    async CarPickUpTimeRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarPickUpTimeRequest', postData);
            return d;
        } catch (e) {
            return e
        }
    },

    async CarDropOffTimeRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarDropOffTimeRequest', postData);
            return d;
        } catch (e) {
            return e
        }
    },

    async CarSearchRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarSearchRequest', postData);
            return d;
        } catch (e) {
            return e
        }
    },

    async CarTermsRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarTermsRequest', postData);
            return d;
        } catch (e) {
            return e
        }
    },

    async CarExtrasRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarExtrasRequest', postData);
            return d;
        } catch (e) {
            return e
        }
    },

    async CarBookRequest(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/CarBookRequest', postData);
            return d;
        } catch (e) {
            return e
        }
    },

    async GetBookingDetailsForMemberId(postData: {}) {
        try {
            const d = await axios.post('/car/api/Car/GetBookingDetailsForMemberId', postData);
            return d;
        } catch (e) {
            return e
        }
    }
}