<template>
  <div style="margin-top: 2%">
    <div class="container" v-if="hotelDetails !== ''">
      <div class="row" style="margin-bottom: 15px">
        <div class="col-md-6" style="text-align: left">
          <div class="row">
            <h5>
              <b>{{ hotelDetails.basicinfo.hotelname }}</b>
            </h5>
            <p>
              {{ hotelDetails.basicinfo.address }},
              {{ hotelDetails.basicinfo.city }},
              {{ hotelDetails.basicinfo.country }}
            </p>
            <p v-if="hotelDetails.communicationinfo.phone !== ''">
              <a-icon type="phone" /> {{ hotelDetails.communicationinfo.phone }}
            </p>
            <p v-if="hotelDetails.communicationinfo.email !== ''">
              <a-icon type="mail" /> {{ hotelDetails.communicationinfo.email }}
            </p>
            <p v-if="hotelDetails.communicationinfo.fax !== ''">
              {{ $t("addcomm.Fax") }}: {{ hotelDetails.communicationinfo.fax }}
            </p>
            <p v-if="hotelDetails.communicationinfo.website !== ''">
              {{ $t("addcomm.Website") }}:
              {{ hotelDetails.communicationinfo.website }}
            </p>
            <p>
              <a-rate
                :default-value="hotelDetails.basicinfo.starrating"
                disabled
                allow-half
              />
            </p>
          </div>
          <div class="row">
            <h6>
              {{ $t("addcomm.Amenities") }}
              <span @click="showModal"
                ><u> {{ "(" + $t("addcomm.View More") + ")" }} </u></span
              >
            </h6>
            <p v-if="hotelDetails.isGym">
              <img title="Gym" src="./icons/gym.png" /> {{ $t("addcomm.Gym") }}
            </p>
            <p v-if="hotelDetails.isMeetingRoom">
              <img
                title="Meeting Facility"
                src="./icons/meeting_facility.png"
              />{{ $t("addcomm.Meeting Facility") }}
            </p>
            <p v-if="hotelDetails.isPool">
              <img title="Pool" src="./icons/pool.png" />
              {{ $t("addcomm.Pool") }}
            </p>
            <p v-if="hotelDetails.isRestaurant">
              <img title="Restaurant" src="./icons/restaurant.png" />{{
                $t("addcomm.Restaurant")
              }}
            </p>
            <p v-if="hotelDetails.isWifi">
              <img title="Wifi" src="./icons/wifi.png" /> Wifi
            </p>
          </div>
          <div class="row">
            <h5>
              <b
                >{{ hotelDetails.roomrates.RoomRate[0].TotalPoints }}
                {{ $t("addcomm.Points for") }}
                <u>{{ hotelRoomTypes[0].ratebreakdown.rate.length }}</u>
                {{ $t("addcomm.night") }}
              </b>
            </h5>
          </div>
        </div>
        <div class="col-md-6">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                class="carousel-item"
                v-for="(imgs, index) in hotelDetails.allImages"
                :key="index"
                :class="{ active: index === 0 }"
              >
                <img
                  :src="imgs.thumbnailimageurl"
                  height="250px"
                  width="100%"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">{{ $t("addcomm.Previous") }} </span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden"> {{ $t("addcomm.Next") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <h6 class="text-left" style="text-align: left">
          <b>{{ $t("addcomm.Select Your Room") }} </b>
        </h6>

        <table class="table table-bordered table-hover">
          <tbody>
            <tr v-for="(rt, index) in hotelRoomTypes" :key="index">
              <td style="padding: 15px; text-align: left">
                <strong>{{ rt.roomtype.roomdescription }}</strong>
              </td>
              <td style="padding: 15px">
                {{ rt.ratebreakdown.rate[0].RatePoint }}
                {{ $t("common.Points") }} {{ "(" + $t("addcomm.per") + ")" }}
              </td>
              <td style="padding: 15px">
                {{ rt.ActualPoints }} {{ $t("addcomm.Points for") }}
                {{ rt.ratebreakdown.rate.length }} {{ $t("addcomm.night") }}
              </td>
              <td class="text-center" style="padding: 15px">
                <a-button
                  class="btn-block"
                  type="primary"
                  @click="gotoNextPage(rt)"
                >
                  {{ $t("addcomm.Book") }}
                </a-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div class="row" style="text-align: left">
        <h6>{{ $t("addcomm.About This Hotel") }}</h6>
        <span v-html="hotelDetails.hotelDesc"></span>
      </div>
      <a-modal v-model="modalvisible" title="Hotel Amenities" @ok="handleOk">
        <p v-for="(am, index) in hotelAmenities" :key="index">{{ am.Value }}</p>
      </a-modal>
    </div>
    <div
      v-if="hotelDetails === ''"
      class="col-md-9"
      style="height: 100%; padding-top: 200px; z-index: 10; margin-left: 12%"
    >
      <a-spin
        size="large"
        class="ht-100"
        role="progressbar"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </div>
</template>
<script>
import IBEHotel from "@/api/ibe/hotel";
import { i18n } from '@/i18n';

export default {
  data() {
    return {
      hotelDetails: "",
      modalvisible: false,
      hotelAmenities: [],
      hotelRoomTypes: [],
      selectedHotelId: "",
    };
  },
  activated() {
    this.selectedHotelId = this.$store.getters["hotel/selectedHotelId"] || "";
    this.callHotelInfo();
  },
  methods: {
    callHotelInfo() {
      IBEHotel.GetHotelInformation(this.selectedHotelId).then((response) => {
        // console.log(response);
        if (!response.error_description) {
          this.hotelDetails = this.$store.getters["hotel/selectedHotel"] || "";
          this.hotelAmenities =
            this.hotelDetails.basicinfo.hotelamenities.Amenities[0].Amenities
              .hotelamenity || [];

          this.hotelDetails.hotelDesc =
            response.results.HotelInformation.otherinfo.Description;
          this.hotelDetails.allImages =
            response.results.HotelInformation.otherinfo.imageinfo;
          this.hotelDetails.location =
            response.results.HotelInformation.otherinfo.locationinfo;
          this.hotelDetails.communicationinfo =
            response.results.HotelInformation.basicinfo[0].communicationinfo;

          this.hotelRoomTypes = this.hotelDetails.roomrates.RoomRate || [];
          // console.log(this.hotelDetails);
        } else {
          this.$store.dispatch("navigate", { c2: "hotel" });
        }
      });
    },
    showModal() {
      this.modalvisible = true;
    },
    handleOk(e) {
      console.log(e);
      this.modalvisible = false;
    },
    gotoNextPage(roomDetails) {
      if (this.$store.getters.IsUserLoggedIn) {
        this.userCurrentPoints = this.$store.getters.points || 0;
        if (roomDetails.ActualPoints > this.userCurrentPoints) {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: i18n.t('addcomm.Insufficient Points'),
          });
        } else {
          this.$store.commit(
            "hotel/setHotelRoomTypeCode",
            roomDetails.roomtype.roomtypecode
          );
          this.$store.dispatch("navigate", { c2: "hotel-booking-form" });
        }
      } else {
        this.$store.dispatch("navigate", { c2: "login" });
      }
    },
  },
};
</script>
<style scoped>
</style>