<template>
  <div class="lister" style="display: flex; flex-wrap: wrap; 
  padding: 8px; 
  margin-bottom: 60px;
  justify-content: space-around; position: relative;
   ">

    <a-card
      class="clientcard"
      v-for="(item, index) in clientList"
      :key="item[0]"
      :style="`cursor: ${item[2] ? 'pointer' : 'default'}; background-color: ${
        (loadedClientIndex === index) ? '#0061a2' : '#d7e3eb'
      }`"
      :loading="
        loadedClientIndex === index &&
        item[3] &&
        $store.getters.storeId != item[3]
      "
      @mousedown="() => visit(item, index)"
      >
      <img
        slot="cover"
        style="max-height: 180px; padding-top: 16px"
        :src="require('../assets/img/' + item[1])"
      />
      <a-card-meta  :title=$i18n.t(item[0])> </a-card-meta>
    </a-card>
  
</div>
</template>

<script>
import clientNamesLogos from "@/assets/img/brandDirectory.js";
//import { Card } from "ant-design-vue";
//import buyablesService from "@/api/buyablesService";

export default {
  //components: { "a-card": Card },
  data() {
    return {
      loadedClientIndex: -1,
      }
    },
  computed: {
    clientList: function () {
      return clientNamesLogos;
      }
    },
  methods: {
    visit(item, i) {
      this.loadedClientIndex = i;
      const s = item[3]
      if (s)
        this.$store.dispatch("navigate", { c: s, c2: "home" })
      const t = item[2]
      if (t) window.open(t)
      }
    }
  }
</script>

<style scoped>

.lister:before {
  content: "";
  position: fixed;
  display: block;
  top: 182px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events:none;
  background-position: center top;
  background-attachment: fixed;
  background-size: 100%;
  background-image: url('/tb1.jpg') 
  }


.clientcard {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex: 0 0 16%;
  height: 250px;
  border-radius: 4px;
  padding: 8px;
  overflow: hidden;
  margin: 6px;
  }
.clientcard:hover {
  box-shadow: 0 0 16px 0 rgb(214 214 214 / 90%);
  }
.ant-card-body {
  padding: 5px !important;
  }

.ant-card-cover {
  display: flex !important;
  justify-content: center  !important;
  border: 8px dotted pink;
  }

.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  }
</style>
