import { ICommonCountry } from '@/interfaces/giift/common/ICountry';
import axios, { AxiosResponse } from 'axios';
import store from '@/store/index'

export default {

    GetAll(): Promise<ICommonCountry[]> {
        return new Promise((resolve, reject) => {
            const allCountries = store.getters.allCountries;
            if (allCountries && allCountries.length !== 0) resolve(allCountries);
            else axios.get<ICommonCountry[]>("/api/common/v1/country/all")
                .then(({ data }) => { store.commit("mutAllCountries", data); resolve(data); })
                .catch((error) => reject(error));
        });
    },

}