import axios from 'axios';
import { IResults } from '@/interfaces/ibe/common/IResults';

export interface IGetAllHotelCities extends IResults {
    results: string[];
}

export interface IGetAllHotelLocations {
    CityName: string;
    Country: string;
    CountryISOCode: string;
}

export default {

    async GetAllHotelCities() {
        return new Promise<IGetAllHotelLocations[]>((resolve, reject) => {
            axios.get<IGetAllHotelCities>('/ibe/api/Hotel/GetAllHotelCities')
                .then(({ data }) => {
                    const c = data.results.map((c: string) => {
                        return { CityName: c.split(",")[2], Country: c.split(",")[1], CountryISOCode: c.split(",")[0] }
                    });
                    resolve(c);
                })
                .catch((error) => reject(error))
        });
    },

    async GetHotelInformation(id: String) {
        try {
            const d = await axios.get(`/ibe/api/Hotel/GetHotelInformation/${id}`);
            if (d != undefined && d.data.results != undefined && d.status == 200) {
                return d.data;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },

    async GetHotelSearchResponse(postData: {}) {
        try {
            const d = await axios.post('/ibe/api/Hotel/GetHotelSearchResponse', postData);
            if (d != undefined && d.data.results != undefined && d.status == 200) {
                return d.data.results;
            } else {
                throw new Error('Unable to fetch records');
            }
        } catch (e) {
            return e
        }
    },

    async HotelReprice(postData: {}) {
        try {
            const d = await axios.post('/ibe/api/Hotel/HotelReprice', postData);
            if (d.data.results !== undefined) {
                return d.data.results;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },

    async GetHotelBookingResponse(postData: {}) {
        try {
            const d = await axios.post('/ibe/api/Hotel/GetHotelBookingResponse', postData);
            if (d.data.results !== undefined) {
                return d.data.results;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },

    async GetMemberBookedHotelInfoList(postData: {}) {
        try {
            const d = await axios.post('/ibe/api/hotel/GetMemberBookedHotelInfoList', postData);
            if (d.data.results !== undefined) {
                return d.data.results;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },
}