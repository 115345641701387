import Vue from 'vue'
import axios, { AxiosRequestConfig } from "axios"
import Auth from '@/api/auth'
import store from '@/store/index'
import CREDS from "@/api/creds"
import { PointValue } from "@/helpers/pointValue";
import { ValuePoint } from "@/helpers/valuePoint";
export default {

    getShopToken() {
        const postdata = {
            "grant_type": CREDS.VUE_APP_ShopGrantType,
            "client_id": CREDS.VUE_APP_ShopClientId,
            "client_secret": CREDS.VUE_APP_ShopClientSecret,
        }
        return axios.post("/api/StoreFront/GetAuthToken", postdata)
            .then(response => {
                // console.log ("🥑 Shop token response: " + JSON.stringify (response))
                if (response && response.status === 200) {
                    store.commit('mutShop_token', response.data.access_token)
                    // expires_in is in seconds, we ranslate to microseconds for ease of use:
                    const shop_token_expiry_time = Auth.getExpireTime(1000 * response.data.expires_in);
                    store.commit('mutShop_token_expiry_time', shop_token_expiry_time);
                    return response.data.access_token
                }
                return ''
            })
            .catch(() => '')
    },

    getStoreDetails(storeId: string) {
        //console.log("🥑 Asking for store details: " + storeId)
        return axios.get("/api/StoreFront/GetStoreDetails", { params: { StoreId: storeId } })
            .then(
                response => {
                    if (response && response.status === 200) {
                        store.commit('mutInvalidStoreDetails', false)
                        const storeDetails = response.data
                        const currentStoreDetail: any = store.getters.storeDetails
                        if (currentStoreDetail && currentStoreDetail.Id === storeDetails.Id) return currentStoreDetail
                        else {
                            store.commit('mutStoreDetails', storeDetails)
                            //console.log("🥑  New store! " + storeId + ": " + JSON.stringify(storeDetails));
                            return storeDetails
                        }
                        
                    }
                    else {
                        store.commit('mutInvalidStoreDetails', true)
                        store.commit ('mutisProcessing', false)
                        //console.log("🥑  Couldn't get store details for " + storeId + ": " + JSON.stringify(response))
                    }
                },
                error => { console.log(error) }
            )
            .catch(error => console.log("Caught it!: " + error))

    },

    getShopClonedRequest(request: AxiosRequestConfig) {
        const decorated = (t: string) => {
            request.headers['Accept'] = 'application/json';
            request.headers['Authorization'] = 'Bearer ' + t;
            return request;
        }
        const shop_token = store.getters.shop_token
        if (shop_token && Number(store.getters.shop_token_expiry_time) > new Date().getTime())
            return decorated(shop_token)
        else
            return this.getShopToken().then(t => decorated(t))
    },

    isShopTokenValid() {
        const t = store.getters.shop_token
        if (!t || t.length < 5) return false
        return Number(store.getters.shop_token_expiry_time) > new Date().getTime()
    },

    async getProducts(postdata: any) {
        const response = await axios.post("/api/StoreFront/SearchProducts", postdata);
        console.log(response);
        if (response && response.status === 200) {
            console.log(response.data, "AAAAAAAssssss")
            return response.data
        }
        else
            console.error(response)
    },

    async GetProductById(pid: any) {
        console.log(pid);
        const storeDetails = store.getters.storeDetails
        const response = await axios.get("/api/StoreFront/GetProductById/" + pid, {
            params: {
                StoreId: storeDetails.Id,
                CatalogId: storeDetails.Catalog,
                LanguageCode: storeDetails.DefaultLanguage.CultureName,
                CurrencyCode: storeDetails.DefaultCurrencyCode,
            }
        });
        console.log(response);
        if (response && response.status === 200) {
            console.log(response.data, "AAAAAAAssssss")
            return response.data
        }
        else
            console.error(response)
    },

    async getOfferProducts(tags: string | any[]) {
        store.commit ('mutisProcessing', true)
        const offerproducts = [];
        for (let i = 0; i < tags.length; i++) {
            const tag = tags[i].Value.substring(0, tags[i].Value.indexOf("|"));
            const storeDetails = store.getters.storeDetails
            //console.log(`🏷️ tag is ${tag} within ${storeDetails.Id}`)
            const postdata = {
                StoreId: storeDetails.Id,
                CatalogId: storeDetails.Catalog,
                ResponseGroup: "ItemLarge",
                Outline: "",
                Currency: storeDetails.DefaultCurrencyCode,
                LanguageCode: storeDetails.DefaultLanguage.CultureName,
                Skip: 0,
                Take: 10,
                Terms: ["Tags:" + tag],
                // Sort: "",
            }
            const response = await axios.post("/api/StoreFront/SearchProducts", postdata)
            if (response && response.status === 200) {
                store.commit ('mutisProcessing', false)
                offerproducts.push({ title: tag, products: response.data.Products })
            }
            else {
                store.commit ('mutisProcessing', false)
            }
        }
        if (offerproducts.length === tags.length) return offerproducts
        else return []
    },

    async getShopCategories(postdata: any) {
        const response = await axios.post("/api/StoreFront/SearchCategories", postdata)
        if (response && response.status === 200)
            return response.data
        else
            console.log(response)
    },

    getShopBanners() {
        const storeId = store.getters.storeId // banner setup is only for 'AHBUAT'
        return axios.get(
            "/api/StoreFront/GetStoreAssets?StoreId=" + storeId + "&Path=banners"
        )
            .then(response => {
                if (response && response.status === 200) return response.data
                else {
                    console.error("No banner! " + response)
                    return false
                }
            })
    },

    addUpdateCartItems(postdata: any) {
        return axios.post(
            "/api/StoreFront/AddOrUpdateItemInCart", postdata
        )
            .then(response => {
                if (response && response.status === 200) return true
                else {
                    console.error("Cart not updated! " + response)
                    return false
                }
            })
    },

    searchUserCart() {
        const storeDetails = store.getters.storeDetails;
        if (!storeDetails) return Promise.resolve(null); // TO DO: to review
        const memberDetails = store.getters.memberDetails;
        if (!memberDetails) return Promise.resolve(null); // TO DO: to review
        const postdata = {
            StoreId: storeDetails.Id,
            Name: "default",
            CustomerId: memberDetails.MemberRelationsList[0].RelationReference,
            LanguageCode: storeDetails.DefaultLanguage.CultureName,
            CurrencyCode: storeDetails.DefaultCurrencyCode,
            Type: "",
        }
        // return Promise.resolve ([])
        // TMP for demo
        return axios.post("/api/StoreFront/SearchCart", postdata)
            .then(response => {
                let cart = []
                if (response && response.data && response.status === 200)
                    cart = response.data
                store.commit('mutShopUserCart', response.data)
                return cart
        })

    },

    async GetAvailablePaymentMethods() {
        const storeDetails = store.getters.storeDetails;
        const response = await axios.get("/api/StoreFront/GetAvailablePaymentMethods", {
            params: {
                cartId: store.getters.shopUserCart.Id,
                LanguageCode: storeDetails.DefaultLanguage.CultureName,
                CurrencyCode: storeDetails.DefaultCurrencyCode,
            }
        });
        if (response && response.data && response.status === 200)
            return response.data
        else console.error(response);
    },

    async GetAvailableShippingRates() {
        const storeDetails = store.getters.storeDetails;
        const response = await axios.get("/api/StoreFront/GetAvailableShippingRates", {
            params: {
                cartId: store.getters.shopUserCart.Id,
                LanguageCode: storeDetails.DefaultLanguage.CultureName,
                CurrencyCode: storeDetails.DefaultCurrencyCode,
            }
        });
        if (response && response.data && response.status === 200)
            return response.data
        else console.error(response);
    },

    async UpdateCartShipment(userCart: any, billingForm: any, shippingRate: any) {
        const postdata = {
            CartId: userCart.Id,
            DeliveryAddress: {
                Type: 3,
                CountryCode: billingForm.countryCode,
                CountryName: billingForm.countryName,
                City: billingForm.city,
                PostalCode: billingForm.zip,
                Zip: billingForm.zip,
                Line1: billingForm.address1,
                Line2: billingForm.address2,
                FirstName: billingForm.fName,
                LastName: billingForm.lName,
                Phone: billingForm.phone,
                Email: billingForm.email,
            },
            ShipmentMethodCode: shippingRate[0].ShipmentMethodCode,
            OptionName: shippingRate[0].OptionName,
            Price: shippingRate[0].Price.Amount,
            DiscountAmount: shippingRate[0].DiscountAmount.Amount,
            TaxType: null,
            TaxPercentRate: shippingRate[0].TaxPercentRate,
            CurrencyCode: shippingRate[0].Currency.Code,
        };
        console.log(postdata);
        const response = await axios.patch("/api/StoreFront/UpdateCartShipment", postdata);
        if (response && response.data && response.status === 200)
            return response.data
        else console.error(response);
    },

    async UpdateCartPayment(userCart: any, paymentmethod: any, outerId: any) {
        const postdata = {
            CartId: userCart.Id,
            PaymentDetails: [
                {
                    OuterId: outerId, //Payment Reference
                    Amount: userCart.Price.SubTotalWithTax.Amount,
                    PaymentGatewayCode: paymentmethod[0].Code,
                    Price: paymentmethod[0].Price.Amount,
                    DiscountAmount: paymentmethod[0].DiscountAmount.Amount,
                    TaxPercentRate: paymentmethod[0].TaxPercentRate,
                    CurrencyCode: paymentmethod[0].Currency.Code,
                },
            ],
        };
        const response = await axios.patch("/api/StoreFront/UpdateCartPayment", postdata);
        if (response && response.data && response.status === 200)
            return response.data
        else console.error(response);
    },

    async CreateOrderFromCart() {
        const response = await axios.post("/api/StoreFront/CreateOrderFromCart", null, {
            params: {
                cartId: store.getters.shopUserCart.Id
            }
        });
        if (response && response.data && response.status === 200)
            return response.data
        else console.error(response);
    },

    async UpdateCustomerOrder(orderDetails: any, outerId: any) {
        const postdata = {
            OrderNumber: orderDetails.Number,
            Status: "Placed",
            Payments: [
                {
                    OuterId: outerId,
                    PaymentStatus: "Paid",
                    PaymentSource: "Points",
                    CardDetails: null,
                },
            ],
        };
        const response = await axios.patch("/api/StoreFront/UpdateCustomerOrder", postdata);
        if (response && response.data && response.status === 200)
            return response.data
        else console.error(response);
    },

    async GetCustomerOrders() {
        const postdata = {
            "StoreId": store.getters.storeDetails.Id,
            "CustomerId": store.getters.relationReference,
            "Skip": 0,
            "Take": 10
        }
        const response = await axios.post("/api/StoreFront/GetCustomerOrders", postdata);
        if (response && response.data && response.status === 200)
            return response.data
        else console.error(response);
    },

    async getRedeemPoints() {
        // const valuePoint = new ValuePoint(
        //     this.getInitialUnits(this.dmoObject, this.value)[0].price, this.redemptionRate
        // );
        // const pointValue = new PointValue(valuePoint.getPoint() * this.qty, this.redemptionRate);
        const postdata = {
            RelationReference: store.getters.relationReference,
            ProgramId: store.getters.programDefinition.program_id,
            RelationType: CREDS.VUE_APP_RelationType,
            TransactionCurrency: 'Poin+',
            Amounts: 3,
            Points: 3,
            MerchantName: 'Shop VC',
            LoyaltyTxnType: 4
        };
        const response = await axios.post("api/callback/v1/lbms/redemption/RedeemPoints", postdata);
        console.log(response);
        if (response && response.data.results.IsSuccessful)
            return response.data;
        else console.error(response);
    }
}