import axios from 'axios'
// @ts-ignore
import { VCConnector } from '@giift/vc-connector/vc-connector.js'
import store from '@/store/index'
import CREDS from "@/api/creds"
import Shop from "@/views/modules/shop/api/shop"

export default {

    shopDescriptionCache: new Map(),

    get vc() {
        return new VCConnector({
            baseUrl: `https://shopgateway.giift.com:8443`,
            grant_type: "client_credentials",
            client_id: CREDS.VUE_APP_ShopClientId, //"d46a2e75-d6b0-4342-86f8-f58e2c2d37b6",
            client_secret: CREDS.VUE_APP_ShopClientSecret //"d2458c35-0b40-4171-b17f-7eb566f00027"
        });
    },

    getBuyables(c: string, c2: string, filter: string, b: number[] = [0, 200]) {
        let buyableP: any
        const loadFilters = (shopDesc: any) => {
            store.commit('mutShopFilters', filter)
            if (shopDesc && c === 'mkt') {
                //store.commit('mutShopFilters', shopDesc.Aggregations)
                // Hard coded for now for VC:
                const filters = [
                    { Label: 'By brand', term: 'brand', inputType: 'text' },
                    { Label: 'By Price', term: 'price', inputType: 'range' },
                ]
            }
            return shopDesc
        }
        const key = c + c2 + filter + 'fromCursor' + b[0]
        const cached = this.shopDescriptionCache.get(key)
        //if (cached) console.log(`🍒🍒  buyables are cached for ${c} ${key} with product length = ${cached.items.length}`)
        if (cached) {
            store.commit('mutisProcessing', false)
            buyableP = this.setupStore(c).then(() => cached)
        }
        else if (c === "mkt")
            buyableP = this.getMarketPlaceBuyables(c2) //c2)
        else
            buyableP = this.getShopBuyables(c, c2, filter, b)

        return buyableP
            .then((shopDesc: any) => loadFilters(shopDesc))
            .then((shopDesc: any) => {
                // Refresh the cache no matter what:
                this.shopDescriptionCache.set(key, shopDesc)
                store.commit("mutBlock", shopDesc.block)
                store.commit('mutActiveBuyables', shopDesc.items)
            })
    },

    setupStore(storeId: string) {
        if (storeId === 'mkt') return Promise.resolve()
        if (storeId === store.getters.storeId) return Promise.resolve()
        //console.log ("🍒 Setting up a new store: " + storeId + " from " + store.getters.storeId)
        return Shop.getStoreDetails(storeId)
            .then(storeDetails => {
                if (!storeDetails) return Promise.reject('No details')
                let currentOffers = []
                //ShopAuth.searchUserCart().then (cart => store.commit("mutShopUserCart", cart))
                if (storeDetails && storeDetails.DynamicProperties) {
                    const list = storeDetails.DynamicProperties.filter((item: { Name: string; }) => item.Name === "Current Offers")
                    if (list.length > 0) {
                        currentOffers = list[0].Values
                        return Shop.getOfferProducts(currentOffers).then(r => store.commit("mutOfferProducts", r))
                    }
                    else {
                        store.commit("mutOfferProducts", [])
                        return Promise.resolve()
                    }
                }
            })
            .then(() => this.vc.getCategories(storeId))
            .then((cats: any) => {
                if (!cats || cats.length === 0) return
                store.commit("mutShopCategories", cats);
                store.commit('mutStoreId', storeId);
            })
            .catch(() => 0 /*console.log (`Store setup failed for storeId = ${storeId} because ` + e */)
    },

    getShopBuyables(storeId: any = store.getters.storeId, cId: any, filter = '', b = [0, 200]) {
        type catP = Promise<{ Aggregations: []; block: any, items: [] }>
        return this.setupStore(storeId)
            .then((): catP => {
                const shopPages = ['cart', 'checkout', 'home'];
                const nothing: catP = Promise.resolve({ Aggregations: [], items: [], block: null })
                if (!cId) return nothing
                if (shopPages.includes(cId)) return nothing
                const storeDetails = store.getters.storeDetails
                if (!storeDetails) return nothing

                const path = cId.replace(/ANDTHEN/g, '/')
                const cardP = this.vc.getCardsFor(storeDetails.Id, storeDetails.Catalog, path, filter, b)

                store.commit('mutisProcessing', true)
                return cardP.then((cards: { page: any, products: [any] }) => {
                    //console.log (`    🍒🍒 Got shop buyables for ${cId} inside ${store.getters.storeId} / `)
                    store.commit('mutisProcessing', false)
                    const block = cards?.page
                    return { Aggregations: [], block: block, items: cards?.products }
                })
            })
    },

    getFullProduct(storeId: string, id: string) {
        return this.vc.getFullProduct(storeId, id)
    },

    getMarketPlaceBuyables(c: any) {
        //console.log(`🍒🍒 Getting Marketplace buyablesP for ${c}`)
        let buyablesP: any
        switch (c) {
            case "egiftCard": buyablesP = this.getEgiftCardBuyables(); break;
            case "utility": buyablesP = this.getUtilityBuyables('utility'); break;

            case "physicalvoucher": buyablesP = this.getPhysicalvoucherBuyables(); break;
            case "lounges": buyablesP = this.getLoungesBuyables(); break;
            case "green": buyablesP = this.getGreenBuyables(); break;
            case "miles": buyablesP = this.getMilesBuyables(); break;
            case "retailers": buyablesP = this.getRetailersBuyables(); break;
            case "travelpackage": buyablesP = this.getTravelpackageBuyables(); break;

            case "games": buyablesP = this.getUtilityBuyables('game'); break;
            case "sweepstake": buyablesP = this.getsweepstakeBuyables(); break;
            case "auction": buyablesP = this.getauctionBuyables(); break;
            default: buyablesP = Promise.resolve([])
        }
        store.commit('mutisProcessing', true)
        return buyablesP.then((buyables: any) => {
            store.commit('mutisProcessing', false)
            return { Aggregations: [], items: buyables, block: { startCursor: 0, count: buyables.length, totalCount: buyables.length } }
        })
    },

    getEgiftCardBuyables() {
        try {
            return axios.get('/api/cart/v2/buyable/list', {
                params: {
                    type: "card",
                    "buying_with": CREDS.VUE_APP_dmoId,
                    currency: CREDS.VUE_APP_dmoId,
                },
            }).then((response) => {
                // console.log(response);
                if (response && response.data && (response.status === 200)) {
                    return response.data.payload;
                } else {
                    return []
                }
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // });
                });
        } catch (error) {
            console.log(error);
        }
    },


    getUtilityBuyables(type: string) {
        try {
            return axios.get('/api/cart/v2/buyable/list', {
                params: {
                    type,
                    "buying_with": CREDS.VUE_APP_dmoId,
                    currency: CREDS.VUE_APP_dmoId,
                },
            }).then((response) => {
                // console.log(response);
                if (response && response.data && response.status === 200) {
                    response.data.payload = response.data.payload.filter((item: { game_type: string; }) => {
                        const aaa = ['spree']
                        return !aaa.includes(item.game_type)
                    })
                    for (const i in response.data.payload) {
                        response.data.payload[i].country = 'SG'
                    }
                    return response.data.payload;
                } else {
                    return [];
                }
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // });
                });
        } catch (error) {
            console.log(error);
        }
    },

    getPhysicalvoucherBuyables() {
        try {
            return axios.get('/api/cart/v2/buyable/list', {
                params: {
                    type: "physicalvoucher",
                    "buying_with": CREDS.VUE_APP_dmoId,
                    currency: CREDS.VUE_APP_dmoId,
                },
            }).then((response) => {
                // console.log(response);
                if (response && response.data && response.status === 200) {

                    const buyables = response.data.payload.filter((d: { units: any[]; }) => {
                        if (d.units.length > 0) {
                            d.units.map((v: { rate: number; }) => (v.rate = 1.0));
                            if (d.units[0].type !== "fixed") {
                                // console.log(d);
                            }
                            return d;
                        }
                    });
                    return buyables;
                } else {
                    return []
                }
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // })
                })
        } catch (error) {
            console.log(error);
        }
    },

    getLoungesBuyables() {
        try {
            return axios.get('/api/cart/v2/buyable/list', {
                params: {
                    type: "lounge",
                    "buying_with": CREDS.VUE_APP_dmoId,
                    currency: CREDS.VUE_APP_dmoId,
                },
            }).then((response) => {
                // console.log(response);
                if (response && response.data && (response.status === 200))
                    return response.data.payload;
                else
                    return []
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // })
                })
        } catch (error) {
            console.log(error);
        }
    },

    getGreenBuyables() {
        try {
            return axios.get('/api/cart/v2/buyable/list', {
                params: {
                    type: "charity",
                    "buying_with": CREDS.VUE_APP_dmoId,
                    currency: CREDS.VUE_APP_dmoId,
                },
            }).then((response) => {
                // console.log(response);
                if (response && response.data && (response.status === 200)) {
                    const buyables = response.data.payload.filter((d: { units: string | any[]; }) => {
                        if (d.units.length !== 0) {
                            return d;
                        }
                    });
                    return buyables;
                }
                else
                    return []
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // })
                })
        } catch (error) {
            console.log(error);
        }
    },

    getMilesBuyables() {
        try {
            return axios.get('/api/cart/v2/buyable/list', {
                params: {
                    type: "topup",
                    "buying_with": CREDS.VUE_APP_dmoId,
                    currency: CREDS.VUE_APP_dmoId,
                },
            }).then((response) => {
                // console.log(response);
                if (response && response.data && (response.status === 200)) {
                    const buyables = response.data.payload.filter((d: { country: any; units: string | any[]; }) => {
                        if (!d.country && d.units.length > 0) {
                            if (d.units[0].type !== "fixed") {
                                console.log(d);
                            }
                            return d;
                        }
                    });
                    return buyables;
                } else {
                    return []
                }
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // })
                })
        } catch (error) {
            console.log(error);
        }
    },

    getRetailersBuyables() {
        try {
            return axios.get('/api/cart/v2/buyable/list', {
                params: {
                    type: "topup",
                    "buying_with": CREDS.VUE_APP_dmoId,
                    currency: CREDS.VUE_APP_dmoId,
                },
            }).then((response) => {
                // console.log(response);
                if (response && response.data && (response.status === 200)) {
                    const buyables = response.data.payload.filter((d: { country: any; units: string | any[]; }) => {
                        if (d.country && d.units.length > 0) {
                            return d;
                        }
                    });
                    return buyables;
                } else {
                    return []
                }
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // })
                });
        } catch (error) {
            console.log(error);
        }
    },

    getTravelpackageBuyables() {
        try {
            return axios.get('/api/cart/v2/buyable/list', {
                params: {
                    type: "travelpackage",
                    "buying_with": CREDS.VUE_APP_dmoId,
                    currency: CREDS.VUE_APP_dmoId,
                },
            }).then((response) => {
                // console.log(response);
                if (response && response.data && (response.status === 200)) {
                    const buyables = response.data.payload.filter((d: { units: string | any[]; }) => {
                        if (d.units.length !== 0) {
                            return d;
                        }
                    });
                    return buyables;
                } else {
                    return []
                }
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // });
                });
        } catch (error) {
            console.log(error);
        }
    },

    // 获取sweepstkes
    getsweepstakeBuyables() {
        try {
            return axios.post('/gamehub/lotteryAuction/Activity/GetSweepstakeList', {}).then((res) => {
                if (res && res.data) {
                    for (const i in res.data.Data) {
                        res.data.Data[i].country = 'SG'
                    }
                    return res.data.Data;
                } else {
                    return []
                }
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // });
                });
        } catch (error) {
            console.log(error);
        }
    },

    getauctionBuyables() {
        try {
            return axios.post('/gamehub/lotteryAuction/Activity/GetAuctionList', {}).then((res) => {
                if (res && res.data) {
                    for (const i in res.data.Data) {
                        res.data.Data[i].country = 'SG'
                    }
                    return res.data.Data;
                } else {
                    return []
                }
            },
                err => {
                    console.log(err);
                    // this.$notification["error"]({
                    //     message: "Error",
                    //     description: err.response.data.error_description,
                    // });
                });
        } catch (error) {
            console.log(error);
        }
    },

}
