<template>
  <div class="container" :style="{'height':iframebox ? '100%' : '' }" v-if="buyable">
    <div v-if="!iframebox">
    <a-spin v-if="isloading" size=large style="margin-top: 200px" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" />
    <div v-else class="row">
        <div class="col-md-9">
      <div class="row main" v-if="buyable" style="margin: 30px 0 0">
        <!-- <div class="col-md-1">
          <div v-for="img in buyable.imgs" :key="img">
            <img
              :src="img"
              v-bind:class="{ images: buyable.currentImg || (buyable.imgs?buyable.imgs[0]:'') === img }"
              class="card-img-top"
              @mouseover="mouseOverImage(img)"
            />
            <br /><br />
          </div>
        </div> -->

        <div class="col-md-3 onlywidth">
          <img
            :src="buyable.currentImg || (buyable.imgs?buyable.imgs[0]:'')"
            class="card-img-top"
          />
        </div>
        <div class="col-md-7">
          <div class="title">{{ buyable.name }}</div>
          <div>
            <div class="desc" v-html="buyable.description"></div>
            <span style="color: #686868;font-size:14px">{{ $t('details.Country') }} :</span>
            <span style="color: #1f1f1f;margin-left:20px">{{ buyable.country }}</span> 
          </div>
        </div>
      </div>

      <div v-if="buyable.metas" class="prizelist">
        <span class="title">{{ $t('addcomm.Prize List') }}</span>
          <div class="swiper-container" ref="swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in buyable.metas.prizes" :key="item.Id" navContext="mkt">
                  <div class="imgbox">
                  <img :src="item.image" alt="">
                  </div>
                  <div>{{item.prizeName}}</div></div>
            </div>
            <!-- <div class="swiper-button-prev" ref="swiperButtonNext" @click="swiper.slidePrev()"></div>
            <div class="swiper-button-next" ref="swiperButtonPrev" @click="swiper.slideNext()"></div> -->
        </div>
      </div>

      <div class="tc">
        <Related-Items :relatedBuyables="relatedBuyables"></Related-Items>
        <!-- <Recently-Viewed></Recently-Viewed> -->
      </div>

      <div class="tc onlytc">
        <div class="pointstitle">
          <span
            @click="currenttag = 1"
            :class="currenttag == 1 ? 'activispan' : ''"
            >{{ $t('addcomm.Terms and Conditions') }}</span
          >
          <span
            style="margin: 0 42px; border-left: 1px solid #dcdcdc; height: 20px"
          ></span>
          <span
            @click="currenttag = 2"
            :class="currenttag == 2 ? 'activispan' : ''"
            >{{ $t('addcomm.Games Records') }}</span
          >
        </div>
        <div>
          <div v-html="buyable ? buyable.tc : ''" v-if="currenttag == 1"></div>
          <div v-else class="userlist">
            <table style="border-collapse: collapse">
              <tr>
                <th style="width: 400px">{{ $t('addcomm.Exchange Time') }}</th>
                <th style="width: 400px">{{ $t('addcomm.Join Times') }}</th>
                <th style="width: 400px">{{ $t('addcomm.Used Times') }}</th>
                <th style="width: 400px">{{ $t('addcomm.Surplus Times') }}</th>
                <th style="width: 400px">{{ $t('addcomm.Games Url') }}</th>
              </tr>
              <tr v-if="!$store.getters.IsUserLoggedIn || !gamesList">
                <td></td>
                <td></td>
                <td style="color: #999999; text-align: center; font-size: 15px">
                  {{ $t('addcomm.No Data') }}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="(item, index) in gamesList" :key="index" v-else>
                <td>{{ item.dateTime | date("MMMM-dd-yyyy hh:mm:ss") }}</td>
                <td>{{ item.activityTimes }}</td>
                <td>{{ item.usedTimes }}</td>
                <td>{{ item.availableTimes }}</td>
                <td>
                  <a @click="toIframPage(item.url)" style="color: #1890ff"
                    >{{ $t('addcomm.Open Url') }}</a
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      </div>
      <div class="col-md-3" style="padding-top:30px">
          <div style="background-color: #eeeeee; padding: 34px 28px 24px" >
            <div class="units">
              <div>{{ currentPointValue }} {{ $t('common.Points') }}</div>
              <div>( {{ currentPointValue }} {{ buyable.units ? buyable.units[0].unit.toUpperCase() : "" }} )</div>
            </div>
            <div class="points">
              <div>{{ $t('details.Total Points') }}</div>
              <div>{{ currentPointValue }} x {{ Qty }} = {{ currentPointValue * Qty }} {{ $t('addcomm.smallPoints') }}</div>
            </div>
            <div class="row buttons">
              <div>
                <!-- <vue-form-generator
                @validated="onValidated"
                :schema="schema"
                :model="model"
                :options="formOptions"
              ></vue-form-generator> -->
                <div class="emailname">{{ $t('addcomm.EnterEmailAddress') }}：</div>
                <input type="text" class="inputbox" v-model="email" />
                <a-button
                  type="primary"
                  size="large"
                  style="width: 100%;background:#0095eb;margin-top:26px"
                  @click="redeemNow"
                  :disabled="false"
                >
                  {{ $t('common.Redeem Now') }}
                </a-button>
              </div>
              <br /><br /><br />
            </div>
          </div>
        </div>
    </div>
    </div>
    <iframe
      v-if="iframebox"
      :src="iFrameUrl"
      width="100%"
      style="margin-top:20px"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
    
import VueFormService from "@/helpers/vueFormService";
import UserService from "@/api/user";
// import RecentlyViewed from "@/components/recentlyViewed";
import RelatedItems from "@/components/relatedItems";
// import genCard from "@/components/genCard";
import axios from "axios";
import Swiper from "swiper";
import {i18n} from "@/i18n";


export default {
  // RecentlyViewed,genCard
  components: { RelatedItems },
  data() {
    return {
      iFrameUrl: "",
      buyableId: String,
      Qty: 1,
      nopoint: false,
      email: "", // this.$store.getters.memberDetails,
      isProcessing: true,
      isShowForm: false,
      isFormValid: false,
      detailId: "",
      currenttag: 1,
      relatedItems: [],
      isloading: true,
      relatedBuyables: { relatedItems: [], navContext: "games" },
      isButtonDisabled: false,
      gamesList: [],
      iframebox: false,
      isShowSlider: false,
      maxSlider: 0,
      minSlider: 0,
      currentPointValue: "",
      swiper: '',
      sliderData: {
        values: [],
        currentValue: "",
        marks: true,
      },

      model: {},
      schema: {
        fields: [],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  mounted() {
    window.scroll(0, 0);
    
  },
  activated() {
    // use NextTick
    setTimeout(() => {
      this.detailId = this.$store.getters.detailId;
      if (this.$store.getters.memberDetails) {
        this.getUserGamesList(this.detailId);
      }
      this.iframebox = false;
      this.currenttag = 1;
      this.Qty = 1;
      this.email = this.$store.getters.memberDetails.Email;
      this.configureFor(this.buyable);

      this.swiper = new Swiper (this.$refs.swiper, {
      loop: true, // 循环模式选项
      slidesPerView : 3,
      navigation: {
        nextEl: this.$refs.swiperButtonNext,
        prevEl: this.$refs.swiperButtonPrev,
      },
    })
    }, 0);
  },
  computed: {
    buyable: function () {
      return this.$store.getters.activeBuyable;
    },
  },
  methods: {
    configureFor: function (buyable) {
      if (!buyable) return;
      //this.isloading = true;
      UserService.addRecentlyViewed(buyable);
      const units = buyable.units;
      //this.isloading = false;
      if (units) {
        const unit = units[0];
        this.currentPointValue = unit.price.toFixed(2) || unit.min.toFixed(2);
        if (unit.type === "fixed") {
          this.isShowSlider = false;
          if (units.length > 1) {
            this.isShowSlider = true;
            for (let i = 0; i < units.length; i++) {
              if (units[i].type === "fixed") {
                this.sliderData.values.push(
                  units[i].unit.toUpperCase() + " " + units[i].price.toFixed(2)
                );
              }
            }
            // p.price = p.unitPrice * p.min;
            this.sliderData.currentValue = this.sliderData.values[0];
            this.maxSlider = Number(this.sliderData.values[0]);
            this.minSlider = Number(
              this.sliderData.values[this.sliderData.values.length - 1]
            );
            if (units.length > 5) {
              this.sliderData.marks = false;
            }
          }
        } else if (unit.type === "variable") this.isShowSlider = false;
      }
      if (buyable.metas && buyable.metas.form) {
        const objectArray = Object.entries(
          buyable.metas.form.schema[Object.keys(buyable.metas.form.schema)[0]]
            .properties
        );
        // console.log(objectArray);
        for (let i = 0; i < objectArray.length; i++) {
          this.model[objectArray[i][0]] = "";
          this.schema.fields.push({
            type: "input",
            inputType: objectArray[i][1].type,
            label: objectArray[i][1].title,
            model: objectArray[i][0],
            placeholder: "Enter " + objectArray[i][1].title,
            required: true,
            validator: () => {
              if (objectArray[i][1].regex) {
                if (
                  !objectArray[i][1].regex.match(this.model[objectArray[i][0]])
                ) {
                  return ["Regex Mismatch: " + objectArray[i][1].regex];
                } else {
                  return [];
                }
              } else {
                return [];
              }
            },
          });
          if (i === objectArray.length - 1) {
            this.isProcessing = false;
            // console.log(this.fields);
          }
        }
      } else {
        const formData = VueFormService.getDefaultForm();
        this.schema.fields = formData.fields;
        this.model = formData.model;
      }

      // related Items
      this.relatedItems = UserService.relatedItems(
        this.$store.getters.filteredBuyables,
        this.buyable
      );
      // console.log(this.relatedItems);
      this.relatedBuyables = {
        relatedItems: this.relatedItems,
        navContext: "games",
      };
      // console.log(this.relatedBuyables);

      this.getButtonDisabled();
    },

    mouseOverImage(img) {
      if (this.buyable)
        // nasty
        this.buyable.currentImg = img;
    },
    decreaseQty() {
      if (this.Qty <= 1) {
        this.$message.warning(i18n.t("addcomm.No Less"));
      } else {
        this.Qty = this.Qty - 1;
        this.getButtonDisabled();
      }
    },

    toIframPage(url) {
      // this.$store.commit ('mutiFrameUrl', url)
      this.iFrameUrl = url
      this.iframebox = true
      },

    increaseQty() {
      this.Qty = parseInt(this.Qty) + parseInt(1);
      this.getButtonDisabled();
    },
    getSliderValue() {
      const currentSlider = this.sliderData.currentValue.substring(
        4,
        this.sliderData.currentValue.length
      );
      this.currentPointValue = Number(currentSlider);
    },
    showForm() {
      this.isShowForm = true;
    },
    redeemNow() {
      if (!this.$store.getters.memberDetails) {
        this.$store.dispatch("navigate", { c2: "login" });
        return;
      }
      if (!this.email) {
        this.$message.warning(i18n.t('addcomm.Please fill in the email'));
        return;
      }

      if (this.isLoadingBet) {
        return;
      }
      this.isloading = true;
      this.isLoadingBet = true;
      axios
        .post(
          `/gamehub/business/merchant_management/api/v2/activity/getactivityurl`,
          {
            ActivityId: this.detailId,
            Quantity: this.num,
            loginEmail: this.$store.getters.memberDetails.Email,
            sendEmail: this.email,
          }
        )
        .then((res) => {
          if (res && res.data && res.data.codeTranslationKey == "success") {
            // this.$message.success(
            //   "Transaction successfully completed and you will receive an email confirmation with voucher/redemption code."
            // );
            this.num = 1;
            // this.email = ''
            this.$store.commit("mutiFrameUrl", res.data.data.campaignURL);
            this.iframebox = true;
            this.isloading = false;
            this.isLoadingBet = false;
            this.toIframPage(res.data.data.campaignURL);
            }
          else {
            this.$message.error(res.data.message);
            this.isloading = false;
            this.isLoadingBet = false;
          }
        });
    },
    onValidated(isValid) {
      if (isValid) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    },
    getButtonDisabled() {
      if (
        Number(this.currentPointValue) * Number(this.Qty) <
        Number(this.$store.getters.points)
      ) {
        this.isButtonDisabled = false;
      } else {
        this.isButtonDisabled = true;
      }
    },
    getUserGamesList(id) {
      this.isloading = true;
      axios
        .post(
          "/gamehub/business/merchant_management/api/v2/activity/GetActivityUrlRecord",
          {
            ActivityId: id,
            email: this.$store.getters.memberDetails.Email,
          }
        )
        .then((res) => {
          if (res) this.gamesList = res.data.data;
          this.isloading = false;
        });
    },
    getQtyInputChange() {
      if (this.Qty > 0) {
        this.getButtonDisabled();
      }
    },
  },
};
</script>

<style scoped>
.card-img {
  width: auto !important;
}

.desc {
  padding-top: 20px;
  font-size: 14px;
  color: #8e8d8d;
}

.main {
  /* text-align: left; */
  /* background-color: #f9f9f9; */
  margin-top: 30px;
  padding: 30px 12px;
  background-color: #ffffff;
}
.emailname{
  font-size: 12px;
	color: #1f1f1f;
  /* text-align: left; */
  margin-bottom: 6px;
}
.points {
  /* text-align: left; */
  font-size: 14px;
	color: #1f1f1f;
}
.points div:last-child{
  font-size: 12px;
	color: #999999;
  margin-top: 5px;
  margin-bottom: 10px;
}

.units {
  text-align: center;
	font-size: 12px;
	color: #545454;
  margin-bottom: 15px;
}
.units div:first-child{
	font-size: 16px;
	color: #1f1f1f;
  margin-bottom: 5px;
}

.buttons {
}

.quantity {
  margin-left: 10%;
  margin-right: 10%;
}
.quantity .addlose .lose img {
  height: 38px;
}
.quantity-input {
  width: 50%;
  margin-top: -1%;
  text-align: center !important;
}

.images {
  border: 2px solid;
}

.cursor {
  cursor: pointer;
}

.tc {
  /* text-align: left; */
  padding-left: 20px;
  padding-right: 20px;
}
.inputbox {
  width: 100%;
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  border-radius: 4px;
  outline: 0;
  margin-left: -2px;
  border: 1px solid #ccc;
}
.inputbox:focus {
  background-color: #fff;
}

.pointstitle {
  font-size: 18px;
  color: #333;
  padding-top: 15px;
  padding-bottom: 15px;
}
.pointstitle span {
  display: inline-block;
  padding-bottom: 6px;
  border-bottom: 3px solid #fff;
  cursor: pointer;
}
.pointstitle .activispan {
  border-bottom: 3px solid #1890ff;
}

th {
  /* text-align: left; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #666666;
  height: 50px;
  text-align: center;
}

td {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #333333;
  height: 50px;
  text-align: center;
}

tr {
  border-bottom: 1px solid #e5e5e5;
}

@keyframes change {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes change {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes change {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

.prizelist {
  /* 奖品列表 */
  width: 100%;
  border: 1px solid #fff;
  margin-top: 10px;
  padding: 20px 20px 10px;
  background-color: #fff;
}
.prizelist .title {
  font-size: 18px;
  color: #333;
  /* text-align: left; */
  position: relative;
  top: 5px;
}
.title .info {
  padding: 0 15px;
}


.iframeBox {
  width: 100%;
  /* height: calc(100vh - 203px); */
}
/* 
.iframeBox ::-webkit-scrollbar-track-piece { 
    background-color:#f8f8f8;
}
.iframeBox ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
.iframeBox ::-webkit-scrollbar-thumb {
    background-color:#dddddd;
    background-clip:padding-box;
    min-height:28px;
}
.iframeBox ::-webkit-scrollbar-thumb:hover {
    background-color:#bbb;
} */

.card-img-top{
  width: 260px;
  border: solid 1px #eeeeee;
}
.onlywidth{
  width: 285px;
}
.title{
  font-size: 20px;
	color: #1f1f1f;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.onlytc{
  background: #ffffff;
  margin-top: 10px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-top: 10px;
}
.swiper-container{
  width: 90%;
  /* border: 1px solid red; */
  height: 250px;
  box-sizing: border-box;
}
.swiper-slide {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #1f1f1f;
}
.swiper-slide img{
  width: 100%;
}
.imgbox{
  display: flex;
  align-items: center;
  height: 180px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 5px;
  border: solid 1px #eeeeee;
}

.swiper-container{
  --swiper-theme-color: #E3E2E3;/* 设置Swiper风格 */
  --swiper-navigation-color: #E3E2E3;/* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px;/* 设置按钮大小 */
}
</style>
