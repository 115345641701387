<template>
  <div class="container">
    <a-spin v-if="isloading" size=large style="margin-top: 200px" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" />
    <div v-else class="row">
      <div class="col-md-9">
        <div class="row main"   style="margin: 30px 0 0">
          <div class="col-md-3 onlywidth">
            <img
              :src="auction.campaignImage"
              class="card-img-top"
            />
          </div>
          <div class="col-md-7">
            <div class="title">{{ auction.campaignName }}</div>
            <div>
              <div class="vhtml" v-html="auction.campaignDescription" ></div>
              <div class="pricebox">
                <div style="font-size:14px;color: #686868;">{{ $t('addcomm.Current Bidding Price') }}：</div>
                
                <div class="maxBidding"> $ {{ maxBidding }} {{ auction.currency }}
                  <img
                    @click="changebidding(auction.id)"
                    :class="{ rotate: isrotate ? true : false }"
                    src="@/assets/img/paymentmethod/updata.png"
                    alt=""
                  />
                </div>

                <div style="font-size: 14px; color: #1f1f1f;">
                  <span style="color:#686868;" class="itembox" >{{ $t('addcomm.Total Bidding Times') }}：</span>
                  <span>{{ auction.biddingCount }}</span>
                </div>
                <div style="font-size: 14px; color: #1f1f1f;">
                  <span v-if="auction.playLimitType == 'PlaysDay'"
                    ><span style="color:#686868"  class="itembox">{{ $t('addcomm.Bidding Quantity') }}：</span
                    >{{ auction.numberOfPlays }} {{ $t('addcomm.per day') }}</span
                  >
                  <span v-if="auction.playLimitType == 'PlaysCampaign'"
                    ><span style="color:#686868"  class="itembox">{{ $t('addcomm.Bidding Quantity') }}：</span
                    >{{ auction.numberOfPlays }} {{ $t('addcomm.per campaign') }}</span
                  >
                </div>
                <div class="auctinfo"  style="font-size: 14px; color: #1f1f1f;">
                  <span v-if="changetime !== '1、2、3、4、5、6、7'">
                    <span style="color:#686868"  class="itembox">{{ $t('addcomm.Bidding Time') }}：</span
                    >{{
                      changetime
                        .replace("1", $t('addcomm.Mon'))
                        .replace("2", $t('addcomm.Tues'))
                        .replace("3", $t('addcomm.Wed'))
                        .replace("4", $t('addcomm.Thur'))
                        .replace("5", $t('addcomm.Fri'))
                        .replace("6", $t('addcomm.Sat'))
                        .replace("7", $t('addcomm.Sun'))
                    }}
                  </span>
                  <span v-else
                    ><span style="color:#686868"  class="itembox">{{ $t('addcomm.Bidding Time') }}：</span> {{ $t('addcomm.Day at') }}
                  </span>
                  {{ auction.timePeriodStartDate | date("HH:mm:ss") }} -
                  {{ auction.timePeriodEndDate | date("HH:mm:ss") }}
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div class="auctioninfo">
          <div>
            <div><span class="itemtitle">{{ $t('addcomm.Bidding Code') }}：</span>{{ auction.auctionNumber }}</div>
            <div><span class="itemtitle">{{ $t('addcomm.Number of Bids') }}：</span>1</div>
            <div><span class="itemtitle">{{ $t('addcomm.Delivery Fee') }}：</span>{{ $t('addcomm.Free Shipping') }}</div>
          </div>
          <div>
            <div><span class="itemtitle">{{ $t('addcomm.Highest Bidder') }}：</span>{{ auction.maxBiddingConsumerName }}</div>
            <div>
              <span class="itemtitle">{{ $t('addcomm.Start Date') }}：</span>{{
                auction.campaignStartDate | date($i18n.locale === 'zh-CN' ? 'yyyy/MM/dd' : 'MMMM dd, yyyy')
              }}
            </div>
            <div>
              <span class="itemtitle">{{ $t('addcomm.End Date') }}：</span>{{ auction.campaignEndDate | date($i18n.locale === 'zh-CN' ? 'yyyy/MM/dd' : 'MMMM dd, yyyy') }}
            </div>
          </div>
        </div>
        <div class="tc onlytc">
          <div class="pointstitle">
            <span
              @click="currenttag = 1"
              :class="currenttag == 1 ? 'activispan' : ''"
              >{{ $t('addcomm.Terms and Conditions') }}</span
            >
            <span
              style="
                margin: 0 42px;
                border-left: 1px solid #dcdcdc;
                height: 20px;
              "
            ></span>
            <span
              @click="currenttag = 2"
              :class="currenttag == 2 ? 'activispan' : ''"
              >{{ $t('addcomm.Bidding Records') }}</span
            >
          </div>
          <div>
            <div v-html="auction.rules" v-if="currenttag == 1"></div>
            <div v-else class="userlist">
              <table style="border-collapse: collapse">
                <tr>
                  <th style="width: 400px">{{ $t('addcomm.Bidding Time') }}</th>
                  <th style="width: 400px">{{ $t('addcomm.Bidder') }}</th>
                  <th style="width: 400px">{{ $t('addcomm.Bidding Price') }}</th>
                </tr>
                <tr v-if="!auctionConsumerItems.length">
                  <td></td>
                  <td
                    style="color: #999999; text-align: center; font-size: 15px"
                  >
                    {{ $t('addcomm.No Data') }}
                  </td>
                  <td></td>
                </tr>
                <tr v-for="(item, index) in auctionConsumerItems" :key="index">
                  <td>{{ item.createTime | date("MMMM-dd-yyyy hh:mm:ss") }}</td>
                  <td v-if="item.consumerName">
                    {{ item.consumerName.slice(0, 4) }}****
                  </td>
                  <td v-else>-</td>
                  <td>{{ item.maxBidding }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3"   style="padding-top:30px">
        <div style="background-color: #eeeeee; padding: 34px 28px 24px" >
          <div class="units">
            <div>{{ auction.pointsRequired }} {{ $t('common.Points') }} </div>
            <div>{{ auction.biddingMinPrice }} {{ auction.currency }}</div>
          </div>
          <div class="points">
            <div>{{ $t('details.Total Points') }}</div>
            <div>
              {{ auction.pointsRequired }}
              x {{ num / auction.biddingMinPrice }} =
              {{ points || auction.pointsRequired }}
              {{ $t('addcomm.smallPoints') }}
            </div>
          </div>
          <div class="quantity">
            <div class="input-group mb-3 addlose">
              <div class="lose cursor" @click="decreaseNum">
                <img src="@/assets/img/paymentmethod/lose.png" alt="">
              </div>
              <input
                type="text"
                class="form-control"
                v-model="curNum"
                style="text-align: center; background: #ffffff"
                readonly
              />
              <div class="lose cursor"  @click="increaseNum">
                <img src="@/assets/img/paymentmethod/add.png" alt="">
              </div>
            </div>
          </div>
          <div class="row buttons">
            <div>
              <div class="emailname">{{ $t('addcomm.EnterEmailAddress') }}：</div>
              <input type="text" class="inputbox" v-model="email" />
              <button
                type="primary"
                size="large"
                style="width: 100%;background:#0095eb;margin-top:26px"
                @click="redeemNow"
                :disabled="isButtonDisabled"
                class="ant-btn ant-btn-primary ant-btn-lg"
              >
                {{ $t('common.Redeem Now') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import RecentlyViewed from "@/components/recentlyViewed";
// import RelatedItems from "@/components/relatedItems";
import axios from "axios";
import {i18n} from "@/i18n";

export default {
  // components: { RecentlyViewed, RelatedItems },
  data() {
    return {
      auction: "",
      auctionImgList: [],
      detailId: "",
      buyableId: String,
      points: "",
      email: "",
      num: 1,
      isloading: true,
      currenttag: 1,
      changetime: "",
      isrotate: false,
      maxBidding: "",
      curNum: "",
      isProcessing: true,
      isShowForm: false,
      isFormValid: false,
      relatedItems: [],
      relatedBuyables: { relatedItems: [], navContext: "auction" },
      isButtonDisabled: false,

      maxSlider: 0,
      minSlider: 0,
      currentPointValue: "",
      sliderData: {
        values: [],
        currentValue: "",
        marks: true,
      },

      model: {},
      schema: {
        fields: [],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  activated() {
    this.auction = "";
    this.auctionImgList = [];
    setTimeout(() => {
      this.currenttag = 1;

      this.email = this.$store.getters.memberDetails.Email;
      this.detailId = window.location.href.split("detail/")[1];
      this.getauctioninfo(this.detailId);
    }, 0);

    window.scroll(0, 0);
  },

  methods: {
    getauctioninfo(detailId) {
      this.isloading = true;
      axios
        .post("gamehub/lotteryAuction/Activity/GetAuctionInfo", {
          ActivityId: detailId,
        })
        .then((res) => {
          this.auction = res.data.Data;
          this.auctionImgList = [];
          this.auctionImgList.push(res.data.Data.campaignImage);
          this.maxBidding = res.data.Data.maxBidding;
          this.changetime = res.data.Data.appcableTimePeriod.join("、");
          this.points = res.data.Data.pointsRequired;
          this.num = res.data.Data.biddingMinPrice;
          this.auctionConsumerItems = res.data.Data.auctionConsumerItems;
          this.loading = false;
          this.curNum = this.auction.currency + this.auction.biddingMinPrice;
          this.isloading = false;
          // this.points = res.Data.pointsRequired;
        });
    },

    mouseOverImage(img) {
      this.auction.campaignImage = img;
    },
    decreaseNum() {
      if (this.num <= this.auction.biddingMinPrice) {
        this.$message.warning(i18n.t("addcomm.No Less"));
      } else {
        this.num -= this.auction.biddingMinPrice;
        this.curNum = this.auction.currency + this.num;
        this.points =
          (this.num / this.auction.biddingMinPrice) *
          this.auction.pointsRequired;

        this.getButtonDisabled();
      }
    },
    increaseNum() {
      if (
        this.num <=
        this.auction.biddingMaxPrice - this.auction.biddingMinPrice
      ) {
        this.num += this.auction.biddingMinPrice;
        this.curNum = this.auction.currency + this.num;
        this.points =
          (this.num / this.auction.biddingMinPrice) *
          this.auction.pointsRequired;

        this.getButtonDisabled();
      } else {
        this.$message.warning(i18n.t("addcomm.No More"));
      }
    },
    showForm() {
      this.isShowForm = true;
    },
    redeemNow() {
      if (!this.$store.getters.memberDetails) {
        this.$store.dispatch("navigate", { c2: "login" });
        return;
      }
      if (!this.email) {
        this.$message.warning(i18n.t("addcomm.Please fill in the email"));
        return;
      }
      if (this.isLoadingBet) {
        return;
      }
      this.isloading = true;
      this.isLoadingBet = true;
      axios
        .post(`/gamehub/lotteryAuction/Activity/SubmitAuction`, {
          Email: this.$store.getters.memberDetails.Email,
          SendEmail: this.email,
          ActivityId: this.auction.id,
          Quantity: this.num / this.auction.biddingMinPrice,
          BiddingPrice: this.maxBidding + this.num,
        })
        .then((res) => {
          if (res.data.ErrMsg == "the operation was successful") {
            this.getauctioninfo(this.detailId);
            this.$message.success(
              i18n.t("addcomm.Transaction successfully")
            );
            this.num = 1;
            this.isloading = false;
            this.isLoadingBet = false;
            // this.$store.dispatch ('navigate', {c: 'success'})
          } else {
            this.isLoadingBet = false;
            this.$message.error(res.ErrMsg);
            this.isloading = false;
            this.isLoadingBet = false;
          }
        });
    },

    changebidding(id) {
      this.isloading = true;
      this.isrotate = true;
      axios
        .post("/gamehub/lotteryAuction/Activity/GetAuctionInfo", {
          ActivityId: id,
        })
        .then((res) => {
          this.isloading = false;
          setTimeout(() => {
            this.isrotate = false;
          }, 2000);
          this.maxBidding = res.Data.maxBidding;
          this.auctionConsumerItems = res.Data.auctionConsumerItems;
        });
    },

    getButtonDisabled() {
      if (Number(this.points) < Number(this.$store.getters.points)) {
        this.isButtonDisabled = false;
      } else {
        this.isButtonDisabled = true;
      }
    },
  },
};
</script>

<style scoped  >
.card-img {
  width: auto;
}

th {
  /* text-align: left; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #666666;
  height: 50px;
}

td {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #333333;
  height: 50px;
}

tr {
  border-bottom: 1px solid #e5e5e5;
}
.main {
  /* text-align: left; */
  /* background-color: #f9f9f9; */
  margin-top: 30px;
  padding: 30px 12px;
  background-color: #ffffff;
}
.points {
  /* text-align: left; */
  font-size: 14px;
	color: #1f1f1f;
}
.points div:last-child{
  font-size: 12px;
	color: #999999;
  margin-top: 5px;
  margin-bottom: 10px;
}
.units {
  text-align: center;
	font-size: 12px;
	color: #545454;
  margin-bottom: 15px;
}
.units div:first-child{
	font-size: 16px;
	color: #1f1f1f;
  margin-bottom: 5px;
}
.quantity-input {
  width: 50%;
  margin-top: -1%;
  text-align: center !important;
}
.quantity .addlose .lose img {
  height: 38px;
}
.images {
  border: 2px solid;
}
.cursor {
  cursor: pointer;
}
.tc {
  /* text-align: left; */
  padding-left: 20px;
  padding-right: 20px;
}
.inputbox {
  width: 100%;
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.inputbox:focus-visible{
  outline: 0;
}
.recently-viewed {
  padding: 0;
}
.pricebox {
  font-size: 16px;
}
.auctioninfo {
  display: flex;
  background-color: #fff;
  margin-top: 10px;
  /* text-align: left; */
  padding: 20px 30px 30px;
}
.auctioninfo div {
  width: 50%;
  margin-right: 35px;
}
.auctioninfo div div{
  width: 100%;
  color: #1f1f1f;
  margin-bottom: 5px;
}
.maxBidding {
  display: flex;
  align-items: center;
  font-size: 20px;
	color: #ff7900;
  margin: 12px 0 18px;
}
.maxBidding img {
  margin-left: 10px;
  width: 20px;
  cursor: pointer;
  
}
.pointstitle {
  font-size: 18px;
  color: #333;
  padding-top: 15px;
  padding-bottom: 15px;
}
.pointstitle span {
  display: inline-block;
  padding-bottom: 6px;
  border-bottom: 3px solid #fff;
  cursor: pointer;
}
.pointstitle .activispan {
  border-bottom: 3px solid #1890ff;
}

.rotate {
  -moz-animation: change 3s linear infinite;
  -ms-animation: change 3s linear infinite;
  -o-animation: change 3s linear infinite;
  -webkit-animation: change 3s linear infinite;
  animation: change 3s linear infinite;
}

@keyframes change {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes change {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes change {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

.onlywidth{
  width: 285px;
}
.card-img-top{
  width: 260px;
  border: solid 1px #eeeeee;
}

.title{
  font-size: 20px;
	color: #1f1f1f;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.vhtml{
  font-size: 14px;
	color: #8e8d8d;
  margin: 2px 0 10px;
}
.itembox{
  display: inline-block;
  width: 150px;
  margin-bottom: 5px;
}
.emailname{
  font-size: 12px;
	color: #1f1f1f;
  /* text-align: left; */
  margin-bottom: 6px;
}

.itemtitle{
  font-size: 14px;
  width: 150px;
  display: inline-block;
	color: #686868;
}
.onlytc{
  background: #ffffff;
  margin-top: 10px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-top: 10px;
}
</style>
