<template>
  <div id="app">
    <Nav-bar style="height: 209px !important; position: relative"></Nav-bar>
    <div
      style="
        height: 100%; /* 205 is 101 + 24 */
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
        background: #f6f6f6;
        margin-bottom: 60px;
        "
      >
        <a-spin v-show="$store.getters.isProcessing"
          size=large
          class="ht-100 "
          style="margin-top: 200px; position: absolute;left: 50%"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        />
      <div class="h-100" v-show="!$store.getters.isProcessing">
        <BrandTable
          v-if="$store.getters.branDing"
          style="z-index: 2"
        />
        <keep-alive v-else>
          <component :is="mainComponent" />
        </keep-alive>
      </div>
    </div>
    <Shop-Footer
      style="position: absolute; bottom: 0px; height: 24px; width: 100%"
    ></Shop-Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import BrandTable from "@/components/BrandTable";
import ShopFooter from "@/components/ShopFooter";
import { mainComponents } from "@/navContextToComponentMap";

export default {
  components: { ShopFooter, NavBar, BrandTable },
  computed: {
    mainComponent: function () {
      let result;

      //let cat = this.$store.getters.currentShopCategory
      const c = this.$store.getters.navContext;
      const c2 = this.$store.getters.underNavContext;
      const d = this.$store.getters.detailId;

      if (d) {
        if (c === "mkt") result = mainComponents.get(c2 + "Detail");
        else result = mainComponents.get("shopDetail");
      } else if (c2) result = mainComponents.get(c2);
      if (!result) result = mainComponents.get("cardLister");

      //console.log (` 🛶 🛶 🛶 🛶 🛶 Loading Main component for ${d} ${c} ${c1} ${c2} `)
      return result;
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100%;
}



.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.language .nav-link {
  font-size: 14px;
  color: black;
}

.addlose {
  display: flex;
  align-items: center;
}
.addlose img {
  width: 35px;
  height: 35px;
}
.addlose input {
  height: 35px;
  color: #1f1f1f;
  border: none;
}

.addlose input:focus {
  box-shadow: none;
}

/deep/ .ant-spin-spinning{
  position: relative;
  left: 50%;
}


@import "~bootstrap/dist/css/bootstrap.css";
</style>
