<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12" style="margin-top: 15px" v-if="serachCriteria !== ''">
        <a-collapse accordion style="margin-bottom: 15px">
          <a-collapse-panel
            key="1"
            :header="$t('addcomm.Modify Search')"
            style="text-align: left"
          >
            <div slot="extra">
              ({{ filteredPayload.length }} {{$t('addcomm.Results')}} ) {{$t('addcomm.Hotel Search') }}: 
              {{ serachCriteria.City }}, {{ serachCriteria.SearchCountry }},
              {{ serachCriteria.checkInDate.format("DD-MM-YYYY") }}--
              {{ serachCriteria.checkOutDate.format("DD-MM-YYYY") }},
              {{ serachCriteria.noOfRooms }} {{$t('addcomm.Room')}}
            </div>
            <hotel-search-panel show-errors :border="false" title="" />
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <hotel-rating-filter v-model="hotelRatingFilters" />
        <hotel-points-filter v-model="hotelPointsFilters" />
        <hotel-amenities-filter v-model="hotelAmenitiesFilters" />
      </div>
      <div class="col-md-9">
        <br />
        <div class="row" style="margin-right: 3%">
          <div
            class="col-md-3"
            v-for="(result, index) in filteredPayload.slice(
              startIndex,
              endIndex
            )"
            :key="index"
          >
            <a-card style="cursor: pointer" @click="gotoNextPage(result)">
              <img
                slot="cover"
                alt="example"
                :src="result.basicinfo.thumbnailimage"
                height="200px"
              />
              <a-card-meta
                :title="result.basicinfo.hotelname"
                :description="
                  result.roomrates.RoomRate[0].TotalPoints +  $t('common.Points')
                "
              ></a-card-meta>
              <a-rate
                :value="result.basicinfo.starrating"
                :disabled="true"
                allow-half
              />
              <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <img
                    title="Meeting Facility Available"
                    v-if="result.isMeetingRoom"
                    src="./icons/meeting_facility.png"
                  />
                  <img
                    title="Meeting Facility Not Available"
                    v-if="!result.isMeetingRoom"
                    src="./icons/meeting_facility_inactive.png"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    title="Gym Available"
                    v-if="result.isGym"
                    src="./icons/gym.png"
                  />
                  <img
                    title="Gym Not Available"
                    v-if="!result.isGym"
                    src="./icons/gym_inactive.png"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    title="Wifi Available"
                    v-if="result.isWifi"
                    src="./icons/wifi.png"
                  />
                  <img
                    title="Wifi Not Available"
                    v-if="!result.isWifi"
                    src="./icons/wifi_inactive.png"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    title="Restaurant Available"
                    v-if="result.isRestaurant"
                    src="./icons/restaurant.png"
                  />
                  <img
                    title="Restaurant Not Available"
                    v-if="!result.isRestaurant"
                    src="./icons/restaurant_inactive.png"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    title="Swimming Pool Available"
                    v-if="result.isPool"
                    src="./icons/pool.png"
                  />
                  <img
                    title="Swimming Pool Not Available"
                    v-if="!result.isPool"
                    src="./icons/pool_inactive.png"
                  />
                </div>
                <div class="col-md-1"></div>
              </div>
            </a-card>
            <br /><br />
          </div>
        </div>
        <div class="row" style="margin-bottom: 35px">
          <br />
          <a-pagination
            :total="filteredPayload.length"
            @change="pagination"
            :pageSize="pageSize"
            v-if="filteredPayload.length !== 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HotelRatingFLTR from "@/views/modules/hotel/components/HotelRatingFLTR.vue";
import HotelPointsFLTR from "@/views/modules/hotel/components/HotelPointsFLTR.vue";
import HotelAmenitiesFLTR from "@/views/modules/hotel/components/HotelAmenitiesFLTR.vue";
import HotelSearchPanel from "@/views/modules/hotel/components/HotelSearchPanel.vue";

export default {
  name: "HotelResults",
  components: {
    "hotel-search-panel": HotelSearchPanel,
    "hotel-rating-filter": HotelRatingFLTR,
    "hotel-points-filter": HotelPointsFLTR,
    "hotel-amenities-filter": HotelAmenitiesFLTR,
  },
  data() {
    return {
      hotelRatingFilters: [],
      hotelPointsFilters: [],
      hotelAmenitiesFilters: [],
      currentPage: 1,
      startIndex: 0,
      endIndex: 12,
      pageSize: 12,
      visible: false,
      termsCond: [],
      serachCriteria: '',
    };
  },
  computed: {
    filteredPayload() {
      return this.$store.getters["hotel/filteredPayload"]({
        hotelRatingFilters: this.hotelRatingFilters || [],
        hotelPointsFilters: this.hotelPointsFilters  || [],
        hotelAmenitiesFilters: this.hotelAmenitiesFilters  || [],
      });
    },
  },
  methods: {
    gotoNextPage(result) {
      // console.log(result);
      this.$store.commit("hotel/setSelectedHotelId", result.hotelid);
      this.$store.dispatch("navigate", { c2: "hotel-details" });
    },
    pagination(event) {
      this.currentPage = event;
      this.startIndex = (this.currentPage - 1) * this.pageSize;
      this.endIndex = this.startIndex + this.pageSize;
      window.scroll(0, 0);
    },
  },
  created() {
    this.serachCriteria = this.$store.getters["hotel/hotelSearchRequest"];
    if (this.filteredPayload === [] || this.serachCriteria === '') {
      this.$store.dispatch("navigate", { c2: "hotel" });
    }
  },
};
</script>
<style scoped>
</style>