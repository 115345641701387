    <template>
  <div style="marging-top: 5%; margin-left: 5%; margin-right: 5%">
    <br />
    <h5>Flight Booking Successful</h5>
    <br />
    <img src="./icons/success.gif" height="200px" width="200px" />
    <div class="row" v-if="flightBookingResponse !== ''">
      <h5>
        BookingReference:
        {{ flightBookingResponse.PNRDetails.BookingReference }}
      </h5>
      <h5>Booking Id: {{ flightBookingResponse.BookingId }}</h5>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flightBookingResponse: "",
    };
  },
  activated() {
    this.flightBookingResponse =
      this.$store.getters["flight/flightBookingResponse"] || "";
  },
};
</script>
<style scoped>
</style>