<template>
  <div v-if="usefulData != null" id="flight-detail">
    <a-card :bordered="border">
      <a slot="extra" @click="alterSearch()" v-if="amendItinerary">edit</a>
      <div slot="title" style="text-align: left">
        {{ usefulData.departureCity }} <a-icon type="swap-right" />
        {{ usefulData.arrivalCity }} <br />
        <small>{{
          formatedDate(
            usefulData.ListOfFlightDetails[0].ListOfFlightSegments[0]
              .DepartureDate,
            "MMM DD, YYYY"
          )
        }}</small
        >&nbsp;|&nbsp;<small>{{ passengerInfo }}</small>
      </div>
      <div
        class="way"
        v-for="(way, i) in usefulData.ListOfFlightDetails"
        :key="i"
      >
        <div class="row">
          <div class="col-md-12">
            <!-- 、text-align: left;  -->
            <div style="font-weight: bold">
              <a-badge
                status="processing"
                :text="i === 0 ? 'Depart' : 'Return'"
              />&nbsp;
              <span class="font-weight: bold;"
                >{{
                  i === 0 ? usefulData.departureCity : usefulData.arrivalCity
                }}
                -
                {{
                  i === 0 ? usefulData.arrivalCity : usefulData.departureCity
                }}</span
              >
            </div>

            <div class="way-details">
              <div
                class="way-detail-item"
                v-for="(line, index) in way.ListOfFlightSegments"
                :key="index"
              >
                <div class="way-detail-item-body">
                  <div class="way-detail-schedule">
                    <div class="schedule-date">
                      <div>
                        {{ formatedDate(line.DepartureDate, "MMM DD HH:mm") }}
                      </div>
                      <div>
                        <span>{{
                          formatedDate(line.ArrivalDate, "MMM DD HH:mm")
                        }}</span>
                      </div>
                    </div>
                    <div class="schedule-step"></div>
                    <div class="schedule-airport">
                      <div>
                        {{ line.DepartureAirField.SearchAirfieldDetails }}
                      </div>
                      <div>
                        {{ line.ArrivalAirField.SearchAirfieldDetails }}
                      </div>
                    </div>
                  </div>

                  <div class="way-detail-airline">
                    <div>
                      <div class="title">
                        <img
                          class="avatar"
                          :src="line.Carrier.CarrierLogoPath"
                          alt="HK"
                        />{{ line.Carrier.CarrierName }}
                      </div>
                    </div>
                    <div class="des text-light2">
                      <span
                        >{{ line.AirlinelIATACode }}{{ line.FlightNo }}</span
                      >
                      <span>{{ line.EquipmentCode }}</span>
                    </div>
                  </div>

                  <div class="way-detail-duration text-right">
                    <div>
                      {{ line.TotalDurationHrs }} hrs
                      {{ line.TotalDurationMins }} mins
                    </div>
                    <div class="cabin-type text-light2">
                      {{ way.CabinType }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import Helper from "@/views/modules/flight/Helper";

export default {
  name: "flight-details",
  data() {
    return {
      isReturn: true,
      usefulData: null,
    };
  },

  props: {
    border: {
      type: Boolean,
      default: true,
    },
    amendItinerary: {
      type: Boolean,
      default: true,
    },
  },

  model: {
    props: ["showErrors", "border", "title"],
    event: "callback",
  },

  created() {
    this.usefulData = Helper.displaySelectedFlightDetails(
      this.$store.getters["flight/selectedFlight"]
    );
  },

  watch: {
    selectedBooking(d) {
      this.usefulData = Helper.displaySelectedFlightDetails(d);
    },
  },

  methods: {
    formatedDate(dateString, format) {
      return moment(dateString).format(format);
    },
    alterSearch() {
      this.$store.dispatch("navigate", { c2: "flight" });
    },
  },

  computed: {
    selectedBooking() {
      return this.$store.getters["flight/selectedFlight"];
    },

    passengerInfo() {
      const form = this.$store.getters["flight/bookingForm"];
      return Helper.concatPassenger(
        form.adultCount,
        form.childCount,
        form.infantCount
      );
    },
  },
};
</script>
<style scoped>
#flight-detail {
  background-color: #fff;
  font-size: 12px;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}
#flight-detail .text-orange {
  color: #ffa900;
}
#flight-detail .text-bold {
  font-weight: bold;
}
#flight-detail .text-gold {
  color: #ffa900;
}
#flight-detail .text-light2 {
  color: #a4b7c3;
}
#flight-detail .badge {
  border-radius: 2px;
  margin-right: 12px;
}
#flight-detail .detail-header {
  padding: 20px 0 16px 29px;
  border-bottom: 1px solid #eaeaea;
}
#flight-detail .detail-header .detail-header-title {
  font-size: 20px;
  font-weight: bold;
  color: #ffa900;
}
#flight-detail .detail-header .detail-header-info {
  font-size: 14px;
  color: #a8b1b9;
}
#flight-detail .detail-header i.icon-exchange {
  padding: 0 10px;
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(/assets/imgs/travel/icon--exchange.png) no-repeat center
    center;
  -webkit-background-size: contain;
  background-size: contain;
  vertical-align: center;
}
#flight-detail .way {
  /* margin: 20px 30px 0 50px;
  padding-bottom: 25px; */
}
#flight-detail .way:not(:last-child) {
  border-bottom: 1px dashed #dcdcdc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#flight-detail .way .way-detail-item .way-detail-stop {
  padding: 15px 0 15px 87px;
  margin-bottom: -9px;
}
#flight-detail .way .way-detail-item .way-detail-item-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
}
#flight-detail .way .way-detail-item .way-detail-item-body .way-detail-airline {
  padding-left: 26px;
  position: relative;
  max-width: 140px;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-airline
  .title {
  position: relative;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-airline
  .avatar {
  position: absolute;
  top: 50%;
  left: -26px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-airline
  .des {
  color: #dcdcdc;
  font-style: normal;
  margin-top: 8px;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-airline
  .des
  > span
  + span {
  border-left: 1px solid #dcdcdc;
  padding-left: 5px;
  margin-left: 5px;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-airline
  .detail-duration {
  color: #dcdcdc;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule {
  display: flex;
  line-height: 1;
  align-items: center;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  > div {
  margin-right: 9px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-date
  > div
  + div {
  margin-top: 8px;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-step {
  height: 16px;
  width: 0;
  border: 1px solid #dcdcdc;
  position: relative;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-step:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 100%;
  background-color: #fff;
  border: 1px solid #dcdcdc;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-step:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  bottom: 100%;
  background-color: #fff;
  border: 1px solid #dcdcdc;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-step.last:after {
  background-color: #dcdcdc;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-step.first:before {
  background-color: #dcdcdc;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-airport {
  width: 330px;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-airport
  > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-schedule
  .schedule-airport
  > div
  + div {
  margin-top: 8px;
}
#flight-detail
  .way
  .way-detail-item
  .way-detail-item-body
  .way-detail-duration
  .cabin-type {
  margin-top: 8px;
}
</style>