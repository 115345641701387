import IBEFlight from "@/api/ibe/flight";
import moment from "moment";
import _ from "lodash";
import Helper from "@/views/modules/flight/Helper";

const form = {
    from: null,
    to: null,
    departDate: moment(),
    returnDate: null,
    flightClass: "Economy",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
};

const getDefaultState = () => {
    return {
        bookingForm: form,
        defaultForm: form,
        airSearchRequest: {},
        allAirFields: [],
        selectedFlight: {},
        flightPassengerInfo: [],
        itineraryResponse: {},
        flightBookingResponse: {},
        selectedItinerary: {},
    }
};

const state = getDefaultState();

export default {
    namespaced: true,
    state,
    getters: {
        allAirFields: (state: { allAirFields: any; }) => state.allAirFields,
        defaultForm: (state: { defaultForm: any; }) => state.defaultForm,
        bookingForm: (state: { bookingForm: any; }) => state.bookingForm,
        airSearchRequest: (state: { airSearchRequest: any; }) => state.airSearchRequest,
        airSearchResults: (state: any, getters: { airSearchRequest: { ItineraryDetailsList: any; }; }) => Helper.cleanPayload(
            getters.airSearchRequest.ItineraryDetailsList || []
        ),
        filteredPayload: (state: any, getters: { airSearchResults: { payload: any; }; }) => (data: any) => Helper.filterPayload(getters.airSearchResults.payload, data) || [],
        selectedFlight: (state: { selectedFlight: any; }) => state.selectedFlight,
        flightPassengerInfo: (state: any) => state.flightPassengerInfo,
        itineraryResponse: (state: any) => state.itineraryResponse,
        flightBookingResponse: (state: any) => state.flightBookingResponse,
        selectedItinerary: (state: any) => state.selectedItinerary,
    },

    mutations: {
        setBookingForm: (state: { bookingForm: any; }, payload: any) => state.bookingForm = payload,
        setAirSearchRequest: (state: { airSearchRequest: any; }, payload: any) => state.airSearchRequest = payload,
        setAllAirFields: (state: { allAirFields: any; }, payload: any) => state.allAirFields = payload,
        resetAirSearchRequest: (state: { airSearchRequest: {}; }) => state.airSearchRequest = {},
        resetState: (state: any) => _.merge({}, state, getDefaultState()),
        setSelectedFlight: (state: { selectedFlight: any; }, payload: any) => state.selectedFlight = payload,
        setFlightPassengerInfo: (state: any, data: any) => state.flightPassengerInfo = data,
        setItineraryResponse: (state: any, data: any) => state.itineraryResponse = data,
        setFlightBookingResponse: (state: any, data: any) => state.flightBookingResponse = data,
        setSelectedItinerary: (state: any, data: any) => state.selectedItinerary = data,
    },

    actions: {
        loadAllAirFields({
            commit
        }: any, vm: { spinning: boolean; }) {
            vm.spinning = true;
            IBEFlight.GetAllAirField().then(
                response => {
                    commit('setAllAirFields', response.filter((cc: { IsActive: any; }) => cc.IsActive));
                    vm.spinning = false;
                }
            )
        },
        airSearchRequest({
            commit
        }: any, payload: { adultCount: any; childCount: any; infantCount: any; returnDate: moment.MomentInput; departDate: moment.MomentInput; flightClass: any; to: { IATACode: any; }; onReturnToggle: any; from: { IATACode: any; }; MemberId: any; }) {
            return new Promise((resolve, reject) => {
                IBEFlight.AirSearchRequest({
                    Adults: payload.adultCount,
                    Childrens: payload.childCount,
                    Infants: payload.infantCount,
                    ReturnDate: moment(payload.returnDate).format(moment.HTML5_FMT.DATE),
                    DepartureDate: moment(payload.departDate).format(moment.HTML5_FMT.DATE),
                    AirlinePrefCode: "Any",
                    Cabin: payload.flightClass,
                    DestinationLocation: payload.to.IATACode,
                    IPAddress: "",
                    IsReturn: payload.onReturnToggle,
                    MemberId: payload.MemberId,
                    OriginLocation: payload.from.IATACode,
                    PointRate: 1,
                })
                    .then(
                        (response) => {
                            if (response.Errors.length >= 1) {
                                const e: any[] = [];
                                response.Errors.filter((d: { ErrorMessage: any; }) => e.push(d.ErrorMessage));
                                reject(e);
                            } else {
                                commit('setBookingForm', payload);
                                commit('setAirSearchRequest', response);
                                resolve(response);
                            }
                        }, (error) => {
                            reject(error);
                        })
            });
        }
    }
}