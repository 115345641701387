import {i18n} from "@/i18n";
export default {
    loginFormValidation(username: any, password: any) {
        const errorAlert = [];
        if (username === "" ) {
            errorAlert.push(i18n.t('addcomm.Please Enter Username'));
        }
        if (password === "" ) {
            errorAlert.push(i18n.t('addcomm.Please Enter Password'));
        }
        return errorAlert;
    }
}