//import Home from '@/components/Home.vue'
import home from '@/components/Home.vue'
import ShopCart from '@/views/modules/shop/components/ShopCart.vue';
import ShopCheckout from './views/modules/shop/components/ShopCheckOut.vue';
import ShopOrders from './views/modules/shop/components/ShopOrders.vue';
import egiftCardDetail from '@/views/modules/egiftcard/egiftCard-details.vue'
import GreenDetails from '@/views/modules/green/green-details.vue'
import LoungesDetails from '@/views/modules/lounges/lounges-details.vue'
import PhysicalvoucherDetails from '@/views/modules/physicalvoucher/physicalvoucher-details.vue'
import UtilityDetails from '@/views/modules/utility/utility-details.vue'

import MilesDetails from '@/views/modules/miles/miles-details.vue'
import RetailersDetails from '@/views/modules/retailers/retailers-details.vue'
import shopProductDetails from '@/components/product-details.vue'
// cards:
import Flight from '@/views/modules/flight/Flight.vue';
import FlightSearch from '@/views/modules/flight/FlightSearch.vue';
import FlightBooking from '@/views/modules/flight/FlightBooking.vue';
import FlightReview from '@/views/modules/flight/FlightReview.vue';
import FlightBookSuccess from '@/views/modules/flight/FlightBookSuccess.vue';
import Hotel from '@/views/modules/hotel/hotel.vue';
import HotelResults from '@/views/modules/hotel/HotelResults.vue';
import HotelDetails from '@/views/modules/hotel/HotelDetails.vue';
import HotelBookingForm from '@/views/modules/hotel/HotelBookingForm.vue';
import HotelReview from '@/views/modules/hotel/HotelReview.vue';
import HotelBookingSuccess from '@/views/modules/hotel/HotelBookingSuccess.vue';
import Car from '@/views/modules/car/Car.vue';
import CarResults from '@/views/modules/car/CarResults.vue';
import CarBooking from '@/views/modules/car/CarBooking.vue';
import CarReview from '@/views/modules/car/CarReview.vue';
import CarBookingSuccess from '@/views/modules/car/CarBookingSuccess.vue';

//Generic
import NotFound from '@/components/NotFound.vue';
import success from '@/components/success.vue';
import cardLister from "@/components/cardLister.vue";
//auth
import Login from "@/views/modules/auth/Login.vue"
import forgotUsername from "@/views/modules/auth/forgotUsername.vue"
import forgotPassword from "@/views/modules/auth/forgotPassword.vue"
import activate from "@/views/modules/auth/activate.vue"
import unblockMember from "@/views/modules/auth/unblockMember.vue"


//ASG
import sweepstakeDetails from '@/views/modules/sweepstake/sweepstake-details.vue'
import gamesDetails from '@/views/modules/games/games-details.vue'
import auctionDetail from '@/views/modules/auction/auction-details.vue'

// Manage User
import User from '@/views/modules/user/user.vue';
import ChangePassword from '@/views/modules/user/changePassword.vue';
import Accruals from '@/views/modules/user/accruals.vue';
import Redemptions from '@/views/modules/user/redemptions.vue';
import Transactions from '@/views/modules/user/transactions.vue';
import Expiry from '@/views/modules/user/expiry.vue';
import Bookings from '@/views/modules/user/bookings.vue';

export const mainComponents = new Map([
    ['login', Login],
    ['forgotUsername', forgotUsername],
    ['forgotPassword', forgotPassword],
    ['activate', activate],
    ['unblockMember', unblockMember],
    ['home', home],
    ['404NotFound', NotFound],
    ['success', success],
    ['user', User],
    ['changePassword', ChangePassword],
    ['accruals', Accruals],
    ['redemptions', Redemptions],
    ['transactions', Transactions],
    ['expiry', Expiry],
    ['bookings', Bookings],
    ['egiftCardDetail', egiftCardDetail],
    ['greenDetail', GreenDetails],
    ['loungesDetail', LoungesDetails],
    ['physicalvoucherDetail', PhysicalvoucherDetails],
    ['utilityDetail', UtilityDetails],
    ['sweepstakeDetail', sweepstakeDetails],
    ['gamesDetail', gamesDetails],
    ['auctionDetail', auctionDetail],
    ['milesDetail', MilesDetails],
    ['retailersDetail', RetailersDetails],
    ['shopDetail', shopProductDetails],
    ['cardLister', cardLister],
    ['default', cardLister],
    ['flight', Flight],
    ['flight-search', FlightSearch],
    ['flight-booking', FlightBooking],
    ['flight-review', FlightReview],
    ['flight-book-success', FlightBookSuccess],
    ['hotel', Hotel],
    ['hotel-results', HotelResults],
    ['hotel-details', HotelDetails],
    ['hotel-booking-form', HotelBookingForm],
    ['hotel-review', HotelReview],
    ['hotel-booking-success', HotelBookingSuccess],
    ['car', Car],
    ['car-results', CarResults],
    ['car-booking', CarBooking],
    ['car-review', CarReview],
    ['car-booking-success', CarBookingSuccess],
    ['cart', ShopCart],
    ['checkout', ShopCheckout],
    ['orders', ShopOrders]
] as Array<[string, object]>)
