<template>
  <div
    style="background: #ececec; padding: 15px; width: 100%; margin-bottom: 15px"
    v-if="stopsOpts.length !== 0"
  >
    <a-card :title="$t('addcomm.Stop(s)')" :bordered="false" style="width: 100%">
      <a-row>
        <a-col :span="24">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
           {{$t('addcomm.Select All')}}
          </a-checkbox>
        </a-col>
      </a-row>
      <a-checkbox-group
        v-model="stopsFilters"
        style="width: 100%"
        @change="onChange"
      >
        <a-row v-for="checkbox in options" :key="checkbox">
          <a-col :span="24">
            <a-checkbox :value="checkbox">{{ checkbox }}</a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indeterminate: false,
      checkAll: true,
      stopsFilters: [],
      options: [],
    };
  },

  watch: {
    stopsFilters: function () {
      this.$emit("input", this.stopsFilters);
    },

    stopsOpts(d) {
      this.stopsFilters = d;
      this.options = d;
    },
  },

  created() {
    this.stopsFilters =
      this.$store.getters["flight/airSearchResults"].allFlightStops;
    this.options =
      this.$store.getters["flight/airSearchResults"].allFlightStops;
  },

  methods: {
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.options.length;
      this.checkAll = checkedList.length === this.options.length;
    },

    onCheckAllChange(e) {
      Object.assign(this, {
        stopsFilters: e.target.checked ? this.options : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
  },
  computed: {
    stopsOpts() {
      return this.$store.getters["flight/airSearchResults"].allFlightStops;
    },
  },
};
</script>

<style scoped>
.ant-card >>> .ant-card-body {
  padding-bottom: 14px !important;
}

.ant-card >>> .ant-card-head-title {
  text-align: center;
}

div >>> .ant-checkbox-wrapper {
  width: 100%;
  /* text-align: left; */
  margin: 0 0 5px;
}
</style>>
