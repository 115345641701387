<template>
<!-- text-align: left; -->
  <div class="container" style=" margin-top: 15px">
    <div class="row" v-if="!processing">
      <div class="col-md-4">
        <a-card title="Passenger(s)" style="width: 100%">
          <div
            v-for="(traveller, index) in itineraryResponse.TravelerInfo"
            :key="index"
          >
            <h6>
              {{ traveller.Prefix }} {{ traveller.FirstName }}
              {{ traveller.LastName }}
            </h6>
            <p>{{ traveller.Age }} ({{ traveller.Gender }})</p>
          </div>
        </a-card>
      </div>
      <div class="col-md-8">
        <a-card
          :title="
            'Flight Details  (' +
            itineraryResponse.FareDetails.ActualPoints +
            'Points)'
          "
          style="width: 100%"
        >
          <flight-details :amendItinerary="false" :border="false" />
        </a-card>
        <br />
        <div class="row">
          <div class="col-md-12">
            <a-button
              style="margin-left: 1%; margin-bottom: 4%; text-align: center"
              type="primary"
              size="large"
              block
              @click="callRedeem()"
            >
              Redeem ({{ itineraryResponse.FareDetails.ActualPoints }} Points)
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="processing"
      class="col-md-9"
      style="height: 100%; z-index: 10; margin-left: 12%"
    >
        <a-spin
          size=large
          class="ht-100"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        />
    </div>
  </div>
</template>

<script>
import FlightDetails from "@/views/modules/flight/components/FlightDetails.vue";
import FlightAPI from "@/api/ibe/flight";
import LBMSService from "@/api/lbmsService";
import Helper from "@/views/modules/flight/Helper";
import { i18n } from '@/i18n';

export default {
  components: {
    "flight-details": FlightDetails,
  },
  data() {
    return {
      itineraryResponse: "",
      processing: false,
    };
  },
  created() {
    if (
      this.$store.getters["flight/itineraryResponse"].FareDetails == undefined
    ) {
      this.$store.dispatch("navigate", { c2: "flight" });
    } else {
      this.itineraryResponse = this.$store.getters["flight/itineraryResponse"];
    }
  },
  activated() {
    if (
      this.$store.getters["flight/itineraryResponse"].FareDetails == undefined
    ) {
      this.$store.dispatch("navigate", { c2: "flight" });
    } else {
      this.itineraryResponse = this.$store.getters["flight/itineraryResponse"];
    }
  },
  methods: {
    callRedeem() {
      console.log(this.$store.getters.transactionCurrency);
      const memberDetails = this.$store.getters.memberDetails || "";
      const flightDetails = Helper.displaySelectedFlightDetails(
        this.$store.getters["flight/selectedFlight"]
      );
      this.processing = true;
      LBMSService.RedeemPoints({
        RelationReference:
          memberDetails.MemberRelationsList[0].RelationReference || "",
        ProgramId: this.$store.getters.programDefinition.program_id,
        RelationType: this.$store.getters.relationType,
        TransactionCurrency: this.$store.getters.transactionCurrency,
        Amounts: this.itineraryResponse.FareDetails.TotalBaseFare,
        Points: this.itineraryResponse.FareDetails.TotalPoints,
        MerchantName:
          flightDetails.departureCity + "-" + flightDetails.arrivalCity,
        LoyaltyTxnType: 5,
      }).then((result) => {
        if (result.results.IsSuccessful) {
          this.callCreateBooking();
        } else {
          this.processing = false;
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: `${i18n.t('addcomm.Failed')}: ` + result.results.ErrorDescription,
          });
        }
      });
    },
    callCreateBooking() {
      const postData = {
        IPAddress: "127.0.0.1",
        PointRate: 1,
        SessionId: this.$store.getters["flight/airSearchRequest"].SessionId,
        ItineraryDetails: this.itineraryResponse,
      };
      console.log(postData);
      FlightAPI.CreateBooking(postData).then((response) => {
        if (!response) {
          this.processing = false;
          this.$notification["error"]({ message: i18n.t("addcomm.Error"), description: "" });
        } else {
          this.processing = false;
          if (response.Errors.length >= 1) {
            response.Errors.forEach((e) =>
              this.$notification["error"]({
                message: i18n.t("addcomm.Error"),
                description: e.ErrorMessage,
              })
            );
          } else {
            this.$store.commit("flight/setFlightBookingResponse", response);
            this.$store.dispatch("navigate", { c2: "flight-book-success" });
          }
          LBMSService.CheckAvailability().then((response) => {
            if (response.results.IsSuccessful) {
              this.$store.commit("mutPoints", response.results.ReturnObject);
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
/* .ant-card >>> .ant-card-body {
  padding: 0 !important;
} */
</style>