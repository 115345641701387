<template>
  <div class="row main">
    <a-card title="My Orders" style="width: 100%" v-if="!isProcessing">
      <a slot="extra"> <a-input placeholder="Search Order" @change="getSearchText($event.target.value)" /> </a>
      <div class="row">
        <div class="col-md-1">
          <h6>No.</h6>
        </div>
        <div class="col-md-3">
          <h6>Order Number</h6>
        </div>
        <div class="col-md-2">
          <h6>Order Date</h6>
        </div>
        <div class="col-md-2">
          <h6>Items</h6>
        </div>
        <div class="col-md-2">
          <h6>Status</h6>
        </div>
        <div class="col-md-2">
          <h6>Amount</h6>
        </div>
      </div>
      <div class="row" v-if="filteredOrder.length === 0">
        <span><br/><br/>No Matching Records Found</span>
      </div>
      <div v-for="(order, index) in filteredOrder" :key="order.Id">
        <br />
        <div class="row" style="text-align: center">
          <div class="col-md-1">
            {{ index + 1 }}
          </div>
          <div class="col-md-3">
            <span>{{ order.Number }}</span>
          </div>
          <div class="col-md-2">
            <span>{{ order.CreatedDate }}</span>
          </div>
          <div class="col-md-2">
            <span>{{ order.Items.length }} Item</span>
          </div>
          <div class="col-md-2">
            <span>{{ order.Shipments[0].Status }}</span>
          </div>
          <div class="col-md-2">
            <span>{{ order.Price.SubTotalWithTax.FormattedAmount }}</span>
          </div>
        </div>
        <br />
        <div style="text-align: left">
          <a-collapse :bordered="false">
            <a-collapse-panel key="1" header="Order Details">
              <div class="row">
                <div class="col-md-8">
                  <div class="row" v-for="item in order.Items" :key="item.Id">
                    <div class="col-md-2">
                      <img :src="item.ImageUrl" height="80px" width="100px" />
                    </div>
                    <div class="col-md-6">
                      <h6>{{ item.Name }}</h6>
                    </div>
                    <div class="col-md-3">
                      <h6>
                        {{ item.Quantity }} x
                        {{ item.Price.PlacedPriceWithTax.Amount }}
                        {{ item.Price.PlacedPriceWithTax.Currency.Symbol }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div style="font-family: Times New Roman">
                    <h5>Shipping Details</h5>
                    <h6>
                      {{ order.Shipments[0].DeliveryAddress.FirstName }}
                      {{ order.Shipments[0].DeliveryAddress.LastName }}
                    </h6>
                    <h6>{{ order.Shipments[0].DeliveryAddress.Line1 }}</h6>
                    <h6>{{ order.Shipments[0].DeliveryAddress.Line2 }}</h6>
                    <h6>
                      {{ order.Shipments[0].DeliveryAddress.City }}
                      {{ order.Shipments[0].DeliveryAddress.PostalCode }}
                      {{ order.Shipments[0].DeliveryAddress.CountryName }}
                    </h6>
                    <h6>
                      Phone: {{ order.Shipments[0].DeliveryAddress.Phone }}
                    </h6>
                    <h6>
                      Email: {{ order.Shipments[0].DeliveryAddress.Email }}
                    </h6>
                  </div>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
    </a-card>
    <div 
      v-if="isProcessing"
      class="col-md-9"
      style="height: 100%; z-index: 10; margin-left: 12%"
    >
        <a-spin
          size=large
          class="ht-100"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        />
    </div>
  </div>
</template>
<script>
import ShopAuth from "@/views/modules/shop/api/shop";
export default {
  data() {
    return {
      allOrders: [],
      filteredOrder: [],
      isProcessing: true,
    };
  },
  activated() {
    //console.log(this.$store.getters.shopOrders);
    this.allOrders = this.$store.getters.shopOrders || [];
    this.filteredOrder = this.$store.getters.shopOrders || [];
    //console.log(this.allOrders);
    if (this.allOrders.length === 0) this.callGetCustomerOrders();
    else this.isProcessing = false;
  },
  methods: {
    callGetCustomerOrders() {
      ShopAuth.GetCustomerOrders().then((response) => {
        //console.log(response);
        this.allOrders = response.CustomerOrders || [];
        this.filteredOrder = response.CustomerOrders || [];
        //console.log(this.allOrders);
        this.isProcessing = false;
        this.$store.commit("mutShopOrders", this.allOrders);
      });
    },
    getSearchText(value) {
      if (value !== "")
      this.filteredOrder = this.allOrders.filter((order) => order.Number.includes(value));
      else this.filteredOrder = this.allOrders
    }
  },
};
</script>
<style scoped>
.main {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}
</style>