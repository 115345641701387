<template>
  <div
    style="background: #ececec; padding: 15px; width: 100%; margin-bottom: 15px"
    v-if="ratingOpts.length !== 0"
  >
    <a-card :title="$t('addcomm.Ratings') " :bordered="false" style="width: 100%">
      <a-row>
        <a-col :span="24">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
            {{$t('addcomm.Select All')}}  
          </a-checkbox>
        </a-col>
      </a-row>
      <a-checkbox-group
        v-model="hotelRatingFilters"
        style="width: 100%"
        @change="onChange"
      >
        <a-row v-for="checkbox in options" :key="checkbox">
          <a-col :span="24" v-if="checkbox > 0">
            <a-checkbox :value="checkbox">
            <a-rate
              style="font-size: 14px;"
              :default-value="checkbox"
              :disabled="true"
              allow-half
            /></a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indeterminate: false,
      checkAll: true,
      hotelRatingFilters: [],
      options: [],
    };
  },

  watch: {
    hotelRatingFilters: function () {
      this.$emit("input", this.hotelRatingFilters);
    },

    ratingOpts(d) {
      this.hotelRatingFilters = d;
      this.options = d;
    },
  },

  created() {
    this.hotelRatingFilters =
      this.$store.getters["hotel/hotelSearchResults"].allHotelRating;
    this.options =
      this.$store.getters["hotel/hotelSearchResults"].allHotelRating;
  },

  methods: {
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.options.length;
      this.checkAll = checkedList.length === this.options.length;
    },

    onCheckAllChange(e) {
      Object.assign(this, {
        hotelRatingFilters: e.target.checked ? this.options : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
  },
  computed: {
    ratingOpts() {
      return this.$store.getters["hotel/hotelSearchResults"].allHotelRating || [];
    },
  },
};
</script>

<style scoped>
.ant-card >>> .ant-card-body {
  padding-bottom: 14px !important;
}

.ant-card >>> .ant-card-head-title {
  text-align: center;
}

div >>> .ant-checkbox-wrapper {
  width: 100%;
  /* text-align: left; */
  margin: 0 0 5px;
}
</style>>
