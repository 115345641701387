<template>
  <div class="container" style="margin-top: 15px">
    <flight-search-panel :show-errors="true" v-on:callback="redirection" />
  </div>
</template>

<script>
import FlightSearchPanel from "@/views/modules/flight/components/FlightSearchPanel.vue";

export default {
  components: {
    "flight-search-panel": FlightSearchPanel,
  },
  methods: {
    redirection(e) {
      if (e.status) {
        this.$store.dispatch("navigate", { c2: "flight-search" });
      }
    },
  },
};
</script>