<template>
  <div style="background: #ececec; padding: 15px; width: 100%; margin-bottom: 15px"
       v-if="airlineOpts.length !== 0">
    <a-card :title="$t('addcomm.Airlines')"
            :bordered="false"
            style="width: 100%">
      <a-row>
        <a-col :span="24">
          <a-checkbox :indeterminate="indeterminate"
                      :checked="checkAll"
                      @change="onCheckAllChange">
           {{$t('addcomm.Select All')}} 
          </a-checkbox>
        </a-col>
      </a-row>
      <a-checkbox-group v-model="airlineFilters"
                        style="width: 100%"
                        @change="onChange">
        <a-row v-for="checkbox in options"
               :key="checkbox">
          <a-col :span="24">
            <a-checkbox :value="checkbox">  {{ checkbox }}
                                                         
            </a-checkbox>
          </a-col>
         </a-row>
      </a-checkbox-group>
    </a-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      indeterminate: false,
      checkAll: true,
      airlineFilters: [],
      options: [],
    };
  },

  watch: {
    airlineFilters: function () {
      this.$emit("input", this.airlineFilters);
    },

    airlineOpts (d) {
      this.airlineFilters = d;
      this.options = d;
    },
  },

  created () {
    this.airlineFilters =
      this.$store.getters["flight/airSearchResults"].allAirlineNames;
    this.options =
      this.$store.getters["flight/airSearchResults"].allAirlineNames;
  },

  methods: {
    onChange (checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.options.length;
      this.checkAll = checkedList.length === this.options.length;
    },

    onCheckAllChange (e) {
      Object.assign(this, {
        airlineFilters: e.target.checked ? this.options : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
  },
  computed: {
    airlineOpts () {
      return this.$store.getters["flight/airSearchResults"].allAirlineNames;
    },
  },
};
</script>

<style scoped>
.ant-card >>> .ant-card-body {
  padding-bottom: 14px !important;
}

.ant-card >>> .ant-card-head-title {
  text-align: center;
}

div >>> .ant-checkbox-wrapper {
  width: 100%;

  /* text-align: left; */
  margin: 0 0 5px;
}
</style>>
