import axios, { AxiosRequestConfig } from 'axios';
import store from '@/store/index';
import CREDS from "@/api/creds";

export default {

    LBMSLogin() {
        return axios.post(
            "/api/auth/v1/oauth2/token", {
            "client_id": CREDS.VUE_APP_client_id,
            "client_secret": CREDS.VUE_APP_client_secret,
            "grant_type": CREDS.VUE_APP_grant_type,
            "scope": CREDS.VUE_APP_scope,
        })
            .then((response: { status: number; data: { expires_in: number; access_token: any; }; }) => {
                if (response.status === 200) {
                    const lbms_token_expiry_time = new Date().getTime() + 1000 * response.data.expires_in
                    store.commit('mutLbms_token_expiry_time', lbms_token_expiry_time)
                    store.commit('mutLbms_token', response.data.access_token)
                    store.commit('mutLbmsRefresh_token', true)
                    return true
                }
                return false
            })
            .catch((error: { message: any; }) => {
                console.log(error.message);
                return {
                    status: 401,
                    msg: error.message
                }
            })
    },

    getASGToken() {
        return axios
            .post("/gamehub/oauth/api/v1/authorization/businessToken", {
                "appId": "abc",
                "appSecret": "505e7e8b-7b23-40ac-8542-c875f97d4287"
            }
            )
            .then((response: { data: { data: { access_token: any; }; }; }) => {
                if (response.data.data) {
                    const token = response.data.data.access_token
                    // const times tamp = this.getExpireTimes tamp((response.data.expires_in - 600) / 60);
                    // local Storag e.setItem('timestamp', timestamp);
                    //local Storag e.setItem('ASG Token', response.data.data.access_token)
                    store.commit('mutASGToken', token)
                    // local Storag e.setItem('refresh_token', JSON.stringify(true));
                    return token
                }
                else return ''
            })
            .catch((error: { message: any; }) => {
                console.log(error.message);
                return {
                    status: 401,
                    msg: error.message
                }
            })
    },

    refreshToken() {
        return axios.post(
            "/api/auth/v1/oauth2/token",
            {
                "client_id": CREDS.VUE_APP_client_id,
                "client_secret": CREDS.VUE_APP_client_secret,
                "grant_type": 'refresh_token',
                "scope": CREDS.VUE_APP_scope,
                "refresh_token": store.getters.lbmsRefresh_token
            }
        ).then((response: { data: { expires_in: number; access_token: any; refresh_token: any; }; }) => {
            console.log(response, 'inside refresh_token call response');
            if (response && response.data) {
                const lbms_token_expiry_time = 1000 * response.data.expires_in
                store.commit('mutLbms_token_expiry_time', lbms_token_expiry_time)
                store.commit('mutLbms_token', response.data.access_token)
                store.commit('muRefresh_token', response.data.refresh_token)
            }
            // return this.getClonedRequest();
        }, (err: { response: { status: number; error: { error_description: string | undefined; }; }; }) => {
            console.log(err, 'inside refresh_token call error');
            console.log(err.response)
            if (err.response.status === 401) {
                this.removeToken();
            } else {
                throw new Error(err.response.error.error_description);
            }
        });
    },

    forgotPassword(email: any) {
        return axios.get('/api/user/v1/user/forgot_password', {
            params: {
                email
            }
        }).then((response: any) => {
            // console.log(response);
            return response;
        }, (err: { message: any; }) => {
            return {
                status: 401,
                msg: err.message
            }
        });
    },

    getExpireTime(delayInMicroseconds: number) {
        const date1 = new Date();
        const temp = new Date(date1.getTime() + delayInMicroseconds)
        return temp.getTime()
    },

    needAuth(requestUrl: string | string[]): boolean {
        const inArray = [
            'oauth2/token',
            // 'buyable/list',
            'buyable/item',
            'buyable/categories-tree',
            'i18n',
        ].some((el) => {
            return requestUrl.includes(el);
        });
        return !inArray;
    },

    isTokenValid(): boolean {
        return (store.getters.lbms_token !== '' ? true : false);
        },

    getLBMSToken(): any {
        const t = store.getters.lbms_token
        if (t && Number(store.getters.lbms_token_expiry_time) > new Date().getTime())
            return Promise.resolve (t)
        else
            return this.LBMSLogin()
        },

    decorated(request: { headers: { [x: string]: string; }; }, t: string): any {
        request.headers['Accept'] = 'application/json';
        request.headers['Authorization'] = 'Bearer ' + t;
        return request;
    },

    getLbmsRequest(request: any): any { // The token is taken of at startup time
        if (!this.needAuth(request.url)) return request
        return this.decorated(request, store.getters.lbms_token)
    },

    getASGRequest(request: any) {
        const cached_token = store.getters.ASGToken
        if (cached_token) // && Number(store.getters.shop_token_expiry_time) > new Date().getTime())
            return this.decorated(request, cached_token)
        else
            return this.getASGToken().then((t: string) => this.decorated(request, t))
    },

    removeToken() {
        localStorage.removeItem('lbms_token_expiry_time');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('isKeepMeLoggedIn');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('cart-id');
        localStorage.removeItem('IsUserLoggedIn');
        localStorage.removeItem('points');
        localStorage.removeItem('currentCard');
        // cart-items?
    }
}

// function LBMSLogin() {
//     throw new Error('Function not implemented.');
// }
