import Vue from 'vue'
import App from './App.vue'
import store from '@/store/index'
import CREDS from "@/api/creds";
import Filters from '@/assets/common/index';
import { li18n } from '@/i18n'

import 'bootstrap'
import axios from 'axios';
Vue.prototype.$axios = axios;

import Auth from '@/api/auth';
import Shop from '@/views/modules/shop/api/shop';
// form generator with JSON
import VueFormGenerator from "vue-form-generator/dist/vfg-core.js";
import "vue-form-generator/dist/vfg-core.css";
import "swiper/swiper-bundle.min.css";


Vue.use(VueFormGenerator);

Vue.component('widget-doll', { /* no param */ })

Vue.use(Filters);

// vue range slider
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
Vue.component('VueSlider', VueSlider)

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd)

import VueFormly from 'vue-formly';
import VueFormlyBootstrap from 'vue-formly-bootstrap';
Vue.use(VueFormly)
Vue.use(VueFormlyBootstrap)


Vue.config.productionTip = false;

const multiIncludes = (text: string, values: string[]) => values.some(val => text.includes(val));

axios.interceptors.request.use(request => {
  request.headers = { 'Content-Type': 'application/json' }
  if (!request) {
    console.log("WTF")
    return
  }
  // baseurl and nameId
  //request.baseURL = CREDS.VUE_APP_api_url // default
  if (request.url && multiIncludes(request.url, ['gamehub', 'businessToken'])) {
    request.baseURL = CREDS.VUE_APP_ASG_url;
  } else if (request.url && multiIncludes(request.url, ['Hotel', 'flight', 'Car/'])) {
    request.baseURL = CREDS.VUE_APP_IBEBaseUrl;
    if (multiIncludes(request.url, ['Hotel'])) {
      request.headers.UserName = CREDS.VUE_APP_IBEHotelUsername;
      request.headers.Token = CREDS.VUE_APP_IBEHotelToken;
    } else if (multiIncludes(request.url, ['flight'])) {
      request.headers.UserName = CREDS.VUE_APP_IBEFlightUsername;
      request.headers.Token = CREDS.VUE_APP_IBEFlightToken;
    } else if (multiIncludes(request.url, ['Car'])) {
      request.headers.UserName = CREDS.VUE_APP_IBECarUsername;
      request.headers.Token = CREDS.VUE_APP_IBECarToken;
    }
  }
  else if (request.url && (request.url.includes('StoreFront') || request.url.includes('shopgateway')))
    request.baseURL = `${CREDS.VUE_APP_Shop_api_url}:8081`
  else if (request.url ? multiIncludes(request.url, ['auth', 'lbms', 'buyable', 'common', 'cart/v1']) : false) {
    request.baseURL = CREDS.VUE_APP_api_url;
    if (request.params)
      request.params['name_id'] = CREDS.VUE_APP_client_id
    else
      request.params = { 'name_id': CREDS.VUE_APP_client_id }
  }

  // Decoration with credentials:
  if (request.url && (request.url.includes('gamehub') && !request.url.includes('businessToken')))
    return Auth.getASGRequest(request);
  if (request.url && (request.url.includes('lbms') || request.url.includes('cart/v1')))
    return Auth.getLbmsRequest(request)
  else if (request.url && request.url.includes('/api/StoreFront') && !request.url.includes('/GetAuthToken')) {
    //request.headers.UserName = 'admin' // CREDS.VUE_APP_Shop_api_Username;
    //request.headers.Token = 'P@ssword12' //CREDS.Shop_api_Passowrd;
    return Shop.getShopClonedRequest(request)
  }
  return request
},
  error => console.log(error.response)
)

// intercept all response
axios.interceptors.response.use(response => response,
  error => {
    if (error.response) {
      // const Explicits403 = ['The request authorization scope was invalid.', 'Requires cart scope', 'The request authorization was invalid.'];
      if (error.response.data.error_description) {
        if (error.response.data.error_description.includes('unauthorized'))
          console.log("unauthorized") //Auth.LBMS Login();
        else
          return error.response
      }
    }
    //else
    //  console.log ("No response whereas we're trying to intercept a response! ")
  })

// Giift LBMS Login
import LBMSService from "@/api/lbmsService";
import buyablesService from "@/api/buyablesService";
import router from "@/router/index";

let i18n: any
LBMSService.getProgramDefinition()
  .then(() =>
    // @ts-ignore
    li18n.loadLanguageAsync())
  .then((vi18n: any) => {
    i18n = vi18n
    return Auth.getLBMSToken()
  })
  .then((response: boolean) => {
    if (response) {
      new Vue({
        store,
        i18n,
        render: h => h(App)
      }).$mount('#app')
      let p = window.location.pathname;
      if (p === "/") p = "/context/AHB/undercontext/home"
      else if (p.includes("/context/mkt"))
        buyablesService.setupStore("AHB") // Ensuring we have a default when we land on mkt. Soon obsolete!
      router.push(p).catch(() => { })
    }
  })