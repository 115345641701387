<template>
  <div class="container mt-4">
    <a-spin :spinning="spinning" size="large">
      <a-card :title="$t('addcomm.Car Booking')" style="text-align: left">
        <div class="row">
          <div class="col-md-4">
               <label>{{$t('details.Country') }}</label><br />
            <v-select
              :value="pickUp.Country"
               :placeholder= "$t('addcomm.Select Country')"
              width="100%"
              :loading="pickUp.Countries.length === 0"
              :options="pickUp.Countries"
              @input="getpickUpCountry"
            >
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
            </v-select>
          </div>
          <div class="col-md-4">
          <label>{{$t('addcomm.City') }}</label><br />
            <v-select
              :value="pickUp.City"
              :options="pickUp.Cities"
              :disabled="pickUp.Country === ''"
              :loading="
                pickUp.Country.length != 0 && pickUp.Cities.length === 0
              "
               :placeholder= "$t('addcomm.Select City')"
              width="100%"
              @input="getpickUpCity"
            >
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
            </v-select>
          </div>
          <div class="col-md-4">
            <label>{{$t('addcomm.Location')}}</label><br />
            <v-select
              :value="pickUp.Location.Value"
              :options="pickUp.Locations.map((l) => l.Value)"
              :disabled="pickUp.City === ''"
              :loading="
                pickUp.City.length != 0 && pickUp.Locations.length === 0
              "
              :placeholder="$t('addcomm.Select Pickup Location')"
              width="100%"
              @input="getpickUpLocation"
            >
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                >
                {{$t('addcomm.vs__spinner class hide')}}  
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <br />
        <div class="row" v-if="!pickUp.isReturnSameLocation">
          <div class="col-md-4">
            <label>{{$t('details.Country')}} </label><br />
            <v-select
              :value="dropOff.Country"
              :placeholder="$t('addcomm.Select Country') "
              width="100%"
              :options="dropOff.Countries"
              :loading="
                dropOff.Countries.length === 0 && pickUp.Location.Value !== ''
              "
              :disabled="pickUp.Location.Value === ''"
              @input="getdropOffCountry"
            >
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                >
                   {{$t('addcomm.vs__spinner class hide')}}  
                </div>
              </template>
            </v-select>
          </div>
          <div class="col-md-4">
            <label> {{$t('addcomm.City')}}</label><br />
            <v-select
              :value="dropOff.City"
              :disabled="dropOff.Country === '' || pickUp.Location.Value === ''"
              :placeholder="$t('addcomm.Select City') "
              width="100%"
              :options="dropOff.Cities"
              @input="getdropOffCity"
              :loading="dropOff.Cities.length === 0 && dropOff.Country !== ''"
            >
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                >
                   {{$t('addcomm.vs__spinner class hide')}}  
                </div>
              </template>
            </v-select>
          </div>
          <div class="col-md-4">
            <label>Location</label><br />
            <v-select
              :value="dropOff.Location.Value"
              :disabled="dropOff.City === ''"
              :placeholder="$t('addcomm.Select Location') "
              width="100%"
              :options="dropOff.Locations.map((l) => l.Value)"
              :loading="dropOff.Locations.length === 0 && dropOff.City !== ''"
              @input="getdropOffLocation"
            >
            </v-select>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-2">
            <label>{{$t('addcomm.Pick-Up Date')}} </label><br />
            <a-date-picker
              :value="pickUp.pickUpDate"
              size="large"
              style="width: 100%"
              :disabled-date="disabledDate"
              :format="dateFormat"
              :placeholder="$t('addcomm.Select Pick-Up Date')"
              @change="getpickUpDate($event)"
            />
          </div>

          <div class="col-md-2">
            <label>{{$t('addcomm.Drop-Off Date')}}</label><br />
            <a-date-picker
              :value="dropOff.dropOffDate"
              size="large"
              style="width: 100%"
              :disabled-date="disabledEndDate"
              :format="dateFormat"
              :placeholder="$t('addcomm.Select Drop-Off Date')"
              :open="endOpen"
              :disabled="pickUp.pickUpDate === ''"
              @openChange="handleEndOpenChange"
              @change="getdropOffDate($event)"
            />
          </div>

          <div class="col-md-2">
            <label> {{$t('addcomm.Pick-Up Time')}}</label><br />
            <a-select
              v-model="pickUp.Hour"
              size="large"
              :default-value="pickUp.Hour"
              style="width: 100%"
            >
              <template v-for="(n, i) in pickUp.TimeArray">
                <a-select-option v-if="n === '1'" :key="n" :value="i">
                  {{ i }}
                </a-select-option>
              </template>
            </a-select>
          </div>
          <div class="col-md-2">
            <br />
            <a-select
              v-model="pickUp.Minute"
              size="large"
              :default-value="pickUp.Minute"
              style="width: 100%"
            >
              <a-select-option value="0">0</a-select-option>
              <a-select-option value="15">15</a-select-option>
              <a-select-option value="30">30 </a-select-option>
              <a-select-option value="45"> 45 </a-select-option>
            </a-select>
          </div>

          <div class="col-md-2">
            <label>{{$t('addcomm.Drop-Off Time')}}</label><br />
            <a-select
              v-model="dropOff.Hour"
              size="large"
              :default-value="dropOff.Hour"
              style="width: 100%"
            >
              <template v-for="(n, i) in dropOff.TimeArray">
                <a-select-option v-if="n === '1'" :key="n" :value="i">
                  {{ i }}
                </a-select-option>
              </template>
            </a-select>
          </div>
          <div class="col-md-2">
            <!-- <label>Pick-Up Time</label> -->
            <br />
            <a-select
              v-model="dropOff.Minute"
              size="large"
              :default-value="dropOff.Minute"
              style="width: 100%"
            >
              <a-select-option value="0">0</a-select-option>
              <a-select-option value="15">15</a-select-option>
              <a-select-option value="30">30 </a-select-option>
              <a-select-option value="45"> 45 </a-select-option>
            </a-select>
          </div>
        </div>
        <br />
        <div class="row pt-3">
          <a-checkbox
            @change="onReturn($event.target.checked)"
            :defaultChecked="true"
          >
           {{$t('addcomm.Return to same location')}}
          </a-checkbox>
        </div>
        <br />
        <div class="row">
          <a-checkbox
            @change="onDriverAge($event.target.checked)"
            :defaultChecked="true"
          >
           {{$t('addcomm.Driver aged between 25-70 Years?')}} 
          </a-checkbox>
        </div>
        <div class="row" v-if="!pickUp.isDriverBetween">
          <div class="col-md-2">
            <br />
            <a-input v-model="pickUp.driverAge" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12" style="text-align: right">
            <a-button
              @click.prevent="submitSearch"
              type="primary"
              size="large"
              class="btn-block"
              >   {{$t("addcomm.Search")}} </a-button
            >
          </div>
          <br /><br /><br />
        </div>
        <div v-if="getErrors">
          <a-alert
            v-for="(err, index) in errorAlert"
            :key="err"
            :message="err"
            type="error"
            closable
            class="mt-3"
            @close="handleErrorClose(index)"
            show-icon
          />
        </div>
      </a-card>
    </a-spin>
  </div>
  <!-- <pre class="mt-5" v-if="callBack">{{ callBack }}</pre> -->
</template>
<script>
import {i18n} from "@/i18n";
import moment from "moment";
import vSelect from "vue-select";
import IBECar from "@/api/ibe/car";
import "vue-select/dist/vue-select.css";
import Helper from "../Helper";
const emptyLocation = { Id: "", Value: "" };

export default {
  components: {
    vSelect,
  },
  props: {
    showErrors: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: i18n.t('addcomm.Flight Booking'),
       
    },
  },

  model: {
    props: ["showErrors", "border", "title"],
    event: "callback",
  },
  data() {
    return {
      pickUp: {
        Countries: [],
        Cities: [],
        Locations: [],
        Country: "",
        City: "",
        Location: emptyLocation,
        TimeArray: [],
        Hour: 0,
        Minute: 0,
        pickUpDate: "",
        isReturnSameLocation: true,
        driverAge: "25",
        isDriverBetween: true,
      },
      dropOff: {
        Countries: [],
        Cities: [],
        Locations: [],
        Country: "",
        City: "",
        Location: emptyLocation,
        TimeArray: [],
        Hour: 0,
        Minute: 0,
        dropOffDate: "",
      },

      dateFormat: "DD/MM/YYYY", // TOODOO! use locale

      endOpen: false,

      errorAlert: [],
      callBack: false,
      spinning: false,
    };
  },
  async mounted() {
    if (
      this.$store.getters["car/carSearchRequest"].dropOff &&
      this.$store.getters["car/carSearchRequest"].pickUp
    ) {
      this.dropOff = this.$store.getters["car/carSearchRequest"].dropOff;
      this.pickUp = this.$store.getters["car/carSearchRequest"].pickUp;
      this.spinning = false;
    } else {
      this.spinning = false;
      const countries = await IBECar.GetAllpickUpCountry();
      this.pickUp.Countries = countries;
    }
  },
  methods: {
    disabledDate(current) {
      return current && current < moment().endOf("day");
    },

    disabledEndDate(dropOffDate) {
      const pickUpDate = this.pickUp.pickUpDate;
      if (!dropOffDate || !pickUpDate) {
        return false;
      }
      return pickUpDate.valueOf() >= dropOffDate.valueOf();
    },

    handleStartOpenChange(open) {
      if (!open) this.endOpen = true;
    },

    handleEndOpenChange(open) {
      this.endOpen = open;
    },

    onReturn(isChecked) {
      this.pickUp.isReturnSameLocation = isChecked;
      if (!isChecked) {
        this.dropOff = {
          Countries: this.pickUp.Countries || [],
          Cities: this.pickUp.Cities || [],
          Locations: this.pickUp.Locations || [],
          Country: this.pickUp.Country || "",
          City: this.pickUp.City || "",
          Location: this.pickUp.Location || emptyLocation,
          TimeArray: [],
          Hour: 0,
          Minute: 0,
          dropOffDate: "",
        };
        if (this.pickUp.Location.Value !== "") {
          this.getdropOffCountries();
        }
      }
    },
    onDriverAge(isChecked) {
      this.pickUp.isDriverBetween = isChecked;
    },

    getdropOffCountries() {
      const postdata = {
        Location: [
          {
            id: this.pickUp.Location.id,
            locName: "",
            country: "",
            city: "",
            onAirport: "",
            Value: "",
          },
        ],
      };
      IBECar.CarDropOffCountryRequest(postdata).then(
        (response) => (this.dropOff.Countries = response)
      );
    },

    getdropOffCountry(v) {
      this.dropOff.Country = v;
      if (this.dropOff.Country) this.dropOff.City = "";
      this.dropOff.Cities = [];
      this.dropOff.Location = "";
      this.dropOff.Locations = [];
      const postdata = {
        Country: this.dropOff.Country,
        Location: [
          {
            id: this.pickUp.Location.id,
            locName: "",
            country: v,
            city: "",
            onAirport: "",
            Value: "",
          },
        ],
      };
      IBECar.CarDropOffCityRequest(postdata).then((response) => {
        this.dropOff.Cities = response;
      });
    },

    getdropOffCity(v) {
      this.dropOff.City = v;
      if (this.dropOff.City) this.dropOff.Location = emptyLocation;
      this.dropOff.Locations = [];
      const postdata = {
        Country: this.dropOff.Country,
        City: v,
        Location: [
          {
            id: this.pickUp.Location.id,
            locName: "",
            country: this.dropOff.Country,
            city: v,
            onAirport: "",
            Value: "",
          },
        ],
      };
      IBECar.CarDropOffLocationRequest(postdata).then((response) => {
        if (response.data.results[0].Location === null) {
          this.dropOff.Locations = [];
          this.dropOff.Location.id = "";
          this.errorAlert.push("Please Select Other Drop Off City");
        } else {
          this.dropOff.Locations = response.data.results[0].Location;
        }
      });
    },

    getpickUpCountry(v) {
      this.pickUp.Country = v;
      this.pickUp.Cities = [];
      this.pickUp.City = "";
      this.pickUp.Location = emptyLocation;
      this.pickUp.Locations = [];
      this.dropOff.Country = "";
      this.dropOff.Countries = [];
      this.dropOff.City = "";
      this.dropOff.Cities = [];
      this.dropOff.Location = "";
      this.dropOff.Locations = [];
      IBECar.CarPickUpCityRequest({ Country: v }).then(
        (response) => (this.pickUp.Cities = response)
      );
    },

    getpickUpCity(v) {
      this.pickUp.City = v;
      IBECar.CarPickUpLocationRequest({
        Country: this.pickUp.Country,
        City: v,
      }).then((response) => {
        let locations = response.data.results[0].Location || [];
        if (locations.length === 0 || locations === null) {
          this.pickUp.Locations = locations;
          this.errorAlert.push("Please Select Other PickUp City");
        } else {
          this.pickUp.Locations = response.data.results[0].Location;
        }
      });
    },

    getpickUpLocation(v) {
      let pl = this.pickUp.Locations.find((l) => l.Value === v);
      this.pickUp.Location = pl ? pl : { Id: "", Value: "" };
      if (!this.pickUp.isReturnSameLocation && this.pickUp.Location.Id !== "")
        this.dropOff.Country = "";
      this.dropOff.Countries = [];
      this.dropOff.City = "";
      this.dropOff.Cities = [];
      this.dropOff.Location = {};
      this.dropOff.Locations = [];
      this.getdropOffCountries();
    },

    getdropOffLocation(v) {
      const dl = this.dropOff.Locations.find((l) => l.Value === v);
      this.dropOff.Location = dl ? dl : { Id: "", Value: "" };
    },

    getpickUpTime(pickUpDate) {
      const postdata = {
        Location: [
          {
            id: this.pickUp.Location.id,
            locName: "",
            country: "",
            city: "",
            onAirport: "",
            Value: "",
          },
        ],
        Date: [
          {
            year: pickUpDate.getFullYear(),
            month: pickUpDate.getMonth() + 1,
            day: pickUpDate.getDate(),
            hour: "00",
            minute: "00",
          },
        ],
      };
      IBECar.CarPickUpTimeRequest(postdata).then((response) => {
        if (response)
        this.pickUp.TimeArray =
          response.data.results.PickUpOpenTimeResponse.OpenTime.split("");
      });
    },

    getdropOffTime(dropOffDate) {
      const postdata = {
        Location: [
          {
            id: this.dropOff.Location.id || this.pickUp.Location.id,
            locName: "",
            country: "",
            city: "",
            onAirport: "",
            Value: "",
          },
        ],
        Date: [
          {
            year: dropOffDate.getFullYear(),
            month: dropOffDate.getMonth() + 1,
            day: dropOffDate.getDate(),
            hour: "00",
            minute: "00",
          },
        ],
      };
      IBECar.CarDropOffTimeRequest(postdata).then((response) => {
        this.dropOff.TimeArray =
          response.data.results.DropOffOpenTimeResponse.OpenTime.split("");
      });
    },

    getpickUpDate(event) {
      this.pickUp.pickUpDate = event;
      if (new Date(event) !== "" && this.pickUp.Location.Id !== "")
        this.dropOff.dropOffDate = moment(this.pickUp.pickUpDate).add(
          1,
          "days"
        );
      this.getdropOffTime(new Date(this.dropOff.dropOffDate));
      this.getpickUpTime(new Date(event));
    },

    getdropOffDate(event) {
      this.dropOff.dropOffDate = event;
      if (
        new Date(event) !== "" &&
        (this.dropOff.Location.Id !== "" || this.pickUp.Location.Id !== "")
      )
        this.getdropOffTime(new Date(event));
    },

    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },

    async submitSearch() {
      this.errorAlert = [];
      this.callBack = false;
      if (this.pickUp.isReturnSameLocation) {
        this.dropOff.Country = this.pickUp.Country;
        this.dropOff.City = this.pickUp.City;
        this.dropOff.Location.id = this.pickUp.Location.id;
        this.dropOff.Location.Value = this.pickUp.Location.Value;
      }
      this.errorAlert = Helper.bookingFormValidation(this);
      // console.log(this.errorAlert);
      this.$emit("callback", {
        errors: this.errorAlert,
        status: false,
      });

      if (this.errorAlert.length == 0) {
        this.spinning = true;
        const postdata = {
          DriverAge: this.pickUp.driverAge,
          PickUp: [
            {
              Location: [
                {
                  id: this.pickUp.Location.id,
                  locName: this.pickUp.Location.Value,
                  country: this.pickUp.Country,
                  city: this.pickUp.City,
                  onAirport: "",
                  Value: "",
                },
              ],
              Date: [
                {
                  year: new Date(this.pickUp.pickUpDate)
                    .getFullYear()
                    .toString(),
                  month: (
                    new Date(this.pickUp.pickUpDate).getMonth() + 1
                  ).toString(),
                  day: new Date(this.pickUp.pickUpDate).getDate().toString(),
                  hour: this.pickUp.Hour.toString(),
                  minute: this.pickUp.Minute.toString(),
                },
              ],
            },
          ],
          DropOff: [
            {
              Location: [
                {
                  id: this.dropOff.Location.id || this.pickUp.Location.id,
                  locName: this.dropOff.Location.Value,
                  country: this.dropOff.Country,
                  city: this.dropOff.City,
                  onAirport: "",
                  Value: "",
                },
              ],
              Date: [
                {
                  year: new Date(this.dropOff.dropOffDate)
                    .getFullYear()
                    .toString(),
                  month: (
                    new Date(this.dropOff.dropOffDate).getMonth() + 1
                  ).toString(),
                  day: new Date(this.dropOff.dropOffDate).getDate().toString(),
                  hour: this.dropOff.Hour.toString(),
                  minute: this.dropOff.Minute.toString(),
                },
              ],
            },
          ],
          Tracking: [
            {
              adcamp: "",
              adplat: "",
            },
          ],
          MembershipReference: "000000",
          RedemptionRate: 1.0,
          IpAddress: "49.248.108.58",
        };
        if (this.errorAlert.length == 0) {
          const form = { pickUp: this.pickUp, dropOff: this.dropOff };
          this.$store.commit("car/setCarSearchRequest", form);
        }
        try {
          await this.$store.dispatch("car/carSearchRequest", postdata);
        } catch (e) {
          console.log(e);
          this.errorAlert.push(e);
        } finally {
          this.spinning = false;
          this.$emit("callback", {
            errors: this.errorAlert,
            status: this.errorAlert.length == 0 ? true : false,
          });
        }
        console.log("🔍 No search yet ", postdata);
      }
    },
  },

  computed: {
    getErrors() {
      return !this.showErrors ? 0 : this.errorAlert.length >= 1;
    },
  },
};
</script>
