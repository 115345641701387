<template>
  <div class="static-links text-center pt-0 d-sm-flex align-md-items bottombox">
    <div class="linkbox container">
    <div>
      <SPAN>© {{ $t('common.2020 Giift Management Asia Pte Ltd') }}.</SPAN>
      </div>
    <div style="width:500px">
      <span class="links">{{ $t('addcomm.FAQs') }}</span>
      <span class="links">{{ $t('addcomm.TermsConditions') }}</span>
      <span class="links">{{ $t('addcomm.Privacy Policy') }}</span>
      <span class="links">{{ $t('addcomm.Booking Policy') }}</span>
      <span class="links">{{ $t('addcomm.Disclaimer') }}</span>
      </div>
      </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.static-links {
  padding-top: 1%;
  z-index: 999;
}

.bottombox{
  padding: 30px  0 !important;
  box-sizing: border-box;
  line-height: 0px;
  color: #1f1f1f;
  background: #eeeeee;
  font-size: 14px;
}

.links{
  padding-left: 3%;
  color: #1f1f1f;
  font-size: 14px;
}

.linkbox{
  display: flex;
  justify-content: space-between;
}

</style>
