<template>
  <div>
    <div class="bynamebox">
      <span>{{ $t("common.By Countries") }}</span>
      <a-select style="width: 100%" @change="$emit('searchCountries', $event)">
        <a-select-option
          v-for="country in $store.getters.allCountries"
          :key="country.countryName"
        >
          {{ country.countryName }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import GiiftCommonCountry from "@/api/giift/common/country";

export default {
  data() {
    return {
      isProcessing: true,
    };
  },
  mounted() {
    const allCountries = GiiftCommonCountry.GetAll();
    if (allCountries && allCountries.length !== 0) this.isProcessing = false;
    else this.callCountryApi();
  },
  methods: {
    callCountryApi() {
      this.$axios.get("/api/common/v1/country/all").then(
        (response) => {
          // console.log(response);
          if (!response) return;
          this.$store.commit("mutAllCountries", response.data);
          this.isProcessing = false;
        },
        (err) => console.log(err.message)
      );
    },
  },
};
</script>

<style scoped>
.bynamebox {
  /* text-align: left; */
}
.bynamebox span {
  font-size: 14px;
  color: #1f1f1f;
  display: block;
  margin: 20px 0 9px;
  font-family: HiraginoSansGB-W3;
}
</style>
