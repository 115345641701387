import CartService from "./cart";
import OrderService from "./order";
import DeliveryService from "./delivery";
import PaymentService from "./payment";
import store from '@/store/index';
export default {
    buyGiftCard(data: any, type: string) {
        console.log(store.getters.relationType)
        console.log(data);
        const alldata = {
            cartId: '',
            orderId: '',
            deliveryId: '',
            PaymentProviderOrderId: ''
        }
        return CartService.newCart().then((cart) => {
            console.log(cart);
            alldata.cartId = cart.Id;
            return cart;
        }).then(async () => {
            const cartItemData = await CartService.addItem({
                buyable_type: type,
                buyable_id: data.buyable_id,
                ccy: data.ccy,
                qty: data.qty,
                cart_id: alldata.cartId
            });
            console.log(cartItemData);
        }).then(async () => {
            const orderdata = await OrderService.createOrder(alldata.cartId);
            console.log(orderdata)
            alldata.orderId = orderdata.CartOrderItems[0].Id;
        }).then(async () => {
            const deliverydata = await DeliveryService.SetDeliveryProvider(alldata.orderId, { provider_key: 'email', fields: data.fields });
            console.log(deliverydata)
            alldata.deliveryId = deliverydata.OrderId;
        }).then(async () => {
            const PaymentProviderydata = await PaymentService.SetPaymentProvider(alldata.deliveryId, { provider: 'postpay' });
            console.log(PaymentProviderydata);
            if (PaymentProviderydata && PaymentProviderydata.OrderId && PaymentProviderydata.Status === "created") {
                alldata.PaymentProviderOrderId = PaymentProviderydata.OrderId;
                return PaymentProviderydata;
            }
        });
    },

    buyMiles(data: any, type: string) {
        console.log(store.getters.relationType)
        console.log(data);
        const alldata = {
            cartId: '',
            cartItemsId: '',
            orderId: '',
            deliveryId: '',
            PaymentProviderOrderId: ''
        }
        return CartService.newCart().then((cart) => {
            console.log(cart);
            alldata.cartId = cart.Id;
            alldata.cartItemsId = cart.CartItems[0].Id
            return cart;
        }).then(async () => {
            await CartService.patchItem(alldata.cartItemsId, data.fields);
        }).then(async () => {
            const cartItemData = await CartService.addItem({
                buyable_type: type,
                buyable_id: data.buyable_id,
                ccy: data.ccy,
                qty: data.qty,
                cart_id: alldata.cartId
            });
            console.log(cartItemData);
        }).then(async () => {
            const orderdata = await OrderService.createOrder(alldata.cartId);
            console.log(orderdata)
            alldata.orderId = orderdata.CartOrderItems[0].Id;
        }).then(async () => {
            const deliverydata = await DeliveryService.SetDeliveryProvider(alldata.orderId, { provider_key: 'gtopup', fields: data.fields });
            console.log(deliverydata)
            alldata.deliveryId = deliverydata.OrderId;
        }).then(async () => {
            const PaymentProviderydata = await PaymentService.SetPaymentProvider(alldata.deliveryId, { provider: 'postpay' });
            console.log(PaymentProviderydata);
            if (PaymentProviderydata && PaymentProviderydata.OrderId && PaymentProviderydata.Status === "created") {
                alldata.PaymentProviderOrderId = PaymentProviderydata.OrderId;
                return PaymentProviderydata;
            }
        });
    }
}

export enum BUYABLE_TYPES {
    CARD = 'card',
    MILES = 'topup',
    CHARITY = 'charity',
    LOUNGE = 'lounge',
    RETAILER = 'topup',
    TRAVEL_PACKAGE = 'travelpackage',
    UTILITY = 'utility',
    PHYSICAL_VOUCHER = 'physicalvoucher'
}