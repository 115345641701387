import axios from 'axios';

export default {

    async GetAllAirField() {
        try {
            const d = await axios.get('/ibe/api/flight/GetAllAirField');
            if (d.data.results !== undefined) {
                return d.data.results;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },

    async AirSearchRequest(postData: {}) {
        try {
            const d = await axios.post('/ibe/api/flight/AirSearchRequest', postData);
            if (d.data.results !== undefined) {
                return d.data.results;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },

    async CreateItinerary(postData: {}) {
        try {
            const d = await axios.post('/ibe/api/flight/CreateItinerary', postData);
            if (d.data.results !== undefined) {
                return d.data.results;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },

    async CreateBooking(postData: {}) {
        try {
            const d = await axios.post('/ibe/api/flight/CreateBooking', postData);
            if (d.data.results !== undefined) {
                return d.data.results;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },

    async GetFlightBookingListForMember(postData: {}) {
        try {
            const d = await axios.post('/ibe/api/flight/GetFlightBookingListForMember', postData);
            if (d.data.results !== undefined) {
                return d.data.results;
            } else {
                throw {
                    error_description: 'Unable to fetch records'
                };
            }
        } catch (e) {
            return e
        }
    },


};