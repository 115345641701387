<template>
  <div class="home">

    <div class="slider pb-2"
         v-if="banners.length > 0">

      <div id="carouselExampleFade"
           class="carousel slide carousel-fade"
           data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item"
               v-for="(banner, idx) in banners"
               :key="banner.Url"
               :class="{ active: idx == 0 }">
            <!-- <div class="shopnow">Shop Now</div> -->
            <img :src="banner.Url + '?tr=w-1920,h-400'"
                 class="d-block w-100" />
          </div>
        </div>
        <button class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide="prev">
          <span class="carousel-control-prev-icon"
                aria-hidden="true"></span>
          <span class="visually-hidden">{{$t('addcomm.Previous')}} </span>
        </button>
        <button class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide="next">
          <span class="carousel-control-next-icon"
                aria-hidden="true"></span>
          <span class="visually-hidden"> {{ $t('addcomm.Next') }}</span>
        </button>
      </div>
    </div>
    <img v-else
         src="../assets/img/paymentmethod/banner.png"
         alt=""
         style="width:1920px; height: 400px">
    <div v-if="!$store.getters.isProcessing && currentOfferProducts"
         class="multi-item container">
      <div class="row">
        <div v-for="offers in currentOfferProducts"
             :key="offers.title">
          <div class="offertitle">{{ offers.title.toUpperCase() }} {{ $t('addcomm.Offers') }}</div>
          <Offers :products="offers.products"></Offers>
        </div>
      </div>
    </div>
    <div v-if="$store.getters.isProcessing">
      <br /><br />
      <a-skeleton :loading="true"
                  active
                  style="padding-left: 10%; padding-right: 10%"></a-skeleton>
    </div>
    <div class="container"
         v-if="!$store.getters.isProcessing && currentOfferProducts && currentOfferProducts.length <= 0">
      <div class="row"
           style="margin-bottom:70px;margin-top:50px;">
        <div class="col-md col-3"
             style="border-right:1px solid #dcdcdc">
          <img src="../assets/img/paymentmethod/shopping.png"
               alt="">
          <span> {{ $t('home.Free Delivery') }} </span>
          <p> {{ $t('home.For all oders over $99') }}</p>
        </div>
        <div class="col-md col-3"
             style="border-right:1px solid #dcdcdc">
          <img src="../assets/img/paymentmethod/pay.png"
               alt="">
          <span> {{ $t('home.90 Days Return') }}</span>
          <p> {{ $t('home.If goods')}}</p>
        </div>
        <div class="col-md col-3"
             style="border-right:1px solid #dcdcdc">
          <img src="../assets/img/paymentmethod/gift.png"
               alt="">
          <span> {{ $t('home.Secure Payment') }} </span>
          <p> {{ $t('home.100% secure payment') }}</p>
        </div>
        <div class="col-md col-3">
          <img src="../assets/img/paymentmethod/concat.png"
               alt="">
          <span> 24/7 {{$t('home.Support')}} </span>
          <p> {{ $t('home.Dedicated support') }}</p>
        </div>

      </div>
      <div class="row"
           style="margin-bottom:30px;">
        <div class="bottom-image1">
          <div class="title1">
            <span class="title1_span"> {{ $t('home.Shopping') }}</span>
            <p> {{ $t('home.Your ultimate shopping destination') }}</p>
          </div>
        </div>
      </div>
      <div class="row"
           style="margin-bottom: 30px; justify-content: space-between;">
        <div class="col-7 row_col_7">
          <div class="bottom-image2"></div>
        </div>
        <div class="col-5 row2_box">
          <div class="row_box">
            <span class="row_span">{{ $t('home.Travel') }}</span>
            <p class="row_p"> {{ $t('home.Enjoy travel experiences all around the world') }}</p>
          </div>
        </div>
      </div>
      <div class="row"
           style="margin-bottom:30px;">
        <div class="bottom-image3">
          <div class="title3">
            <span class="title3_span">{{ $t('home.Gift Cards') }}</span>
            <p>{{ $t('home.We have several suggestions') }} </p>
          </div>
        </div>
      </div>

      <div class="row"
           style="justify-content: space-between;">
        <div class="col-5 row2_box">
          <div class="row_box">
            <span class="row_span"> {{ $t('home.Donations') }}</span>
            <p class="row_p"> {{ $t('home.Spread the joy and donate to a range of charities around the globe') }}</p>
          </div>
        </div>
        <div class="col-7 row_col_7">
          <div class="bottom-image4"></div>
        </div>
      </div>
      <!-- <div class="row"  style="justify-content: space-between;">
        <div class="col-5 row4_box">                         
         <div class="row_box">
            <span class="row_span">Travel</span>
            <p class="row_p">Enjoy travel experiences all around the world</p>
          </div>
        </div>
        <div class="col-7 row_col_7">
           <div class="bottom-image4" style="padding:0px;">
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Offers from "@/views/modules/shop/components/ShopOffers";
import Shop from "@/views/modules/shop/api/shop";
// import vc from "@/api/vc-connector.ts"
// import axios from "axios";
export default {
  components: { Offers },
  data () {
    return {
      banners: [],
    };
  },
  computed: {
    currentOfferProducts: function () {
      return this.$store.getters.offerproducts;
    },
  },
  created () {
    this.setupShopBanner();
    this.$watch("$store.getters.storeId", this.setupShopBanner);
  },
  methods: {
    setupShopBanner () {
      Shop.getShopBanners().then((response) => {
        if (response) this.banners = response.AssetDetails || [{ Url: 'https://shopgatewayuat.giift.com/assets/stores/AHBUAT/banners/shopbanner1.jpg' }];
      });
    },
  },
};
</script>

<style scoped>
.home .slider {
  max-height: 380px;
  overflow: hidden;
}

.home .offer-2 {
  padding-left: 5%;
  padding-right: 5%;
}
.home .offertitle {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 20px;
  color: #1f1f1f;
}
.home .container .row > .col-md {
  /* border:1px solid red; */
  height: auto;
}
.home .row .col-md img {
  width: 49px;
  height: 47px;
  display: block;
  margin: 0px auto 20px;

}
.home .row .col-md span {
  font-size: 16px;
  text-align: center;
  font-family: HiraginoSansGB-W6;
  color: #1f1f1f;
  font-weight: bold;
  display: block;
}
.home .row .col-md p {
  font-family: HiraginoSansGB-W3;
  font-size: 14px;
  line-height: 3px;
  letter-spacing: 0px;
  color: #a8a6a6;
  text-align: center;
  margin-top: 10px;
}
.container .row .bottom-image1 {
  width: 100%;
  height: 300px;
  position: relative;
  background: url(../assets/img/paymentmethod/background1.png) no-repeat;
  background-size: cover;
  padding: 0px;
}
.container .row .bottom-image3 {
  width: 100%;
  height: 300px;
  position: relative;
  background: url(../assets/img/paymentmethod/background3.png) no-repeat;
  background-size: cover;
  padding: 0px;
}
.container .row .bottom-image3 .title3 {
  position: absolute;
  left: 97px;
  bottom: 41px;
  width: 466px;
  height: 90px;
  background-color: #0095eb;
  border-radius: 45px;
  opacity: 0.9;
  /* text-align: left; */
  padding-left: 39px;
  padding-top: 15px;
}
.container .row .bottom-image1 .title1 {
  position: absolute;
  top: 43px;
  right: 46px;
  width: 466px;
  height: 90px;
  background-color: #0095eb;
  border-radius: 45px;
  opacity: 0.9;
  /* text-align: left; */
  padding-left: 39px;
  padding-top: 15px;
}
.bottom-image1 .title1 .title1_span,
.bottom-image3 .title3 .title3_span {
  color: #ffffff;
  display: block;
  font-family: HiraginoSansGB-W6;
  font-size: 20px;
  margin-bottom: 15px;
}
.bottom-image1 .title1 p,
.bottom-image3 .title3 p {
  font-family: HiraginoSansGB-W3;
  font-size: 14px;
  line-height: 3px;
  color: #d1d1d1;
}
/* 全局的col_7 */
.container .row .col-7.row_col_7 {
  padding: 0px;
}

.container .row .col-7 .bottom-image2 {
  height: 300px;
  background: url(../assets/img/paymentmethod/background2.png) no-repeat;
  padding: 0px;
  margin-right: 26px;
  background-size: cover;
}
.container .row .col-5.row2_box,
.container .row .col-5.row4_box {
  height: 300px;
  background-color: #e5f4fd;
  position: relative;
  padding: 0px;
}
.container .row .row_box {
  padding: 50px 48px;
  box-sizing: border-box;
}
.container .row .row_box .row_span {
  font-size: 20px;
  /* text-align: left; */
  color: #1f1f1f;
  display: block;
  margin-bottom: 8px;
}
/* 全局的p标签 */
.container .row .row_box .row_p {
  font-size: 14px;
  color: #7a7a7a;
  line-height: 20px;
  /* text-align: left; */
}

.container .row .col-left {
  /* width: 470px; */
  height: 300px;
  background-color: #e5f4fd;
}
.container .row .row_col_7 .bottom-image4 {
  /* width: 700px; */
  height: 300px;
  background: url(../assets/img/paymentmethod/background4.png) no-repeat;
  padding: 0px;
  background-size: cover;
  margin-left: 26px;
}
/deep/ .ant-card-body {
  padding: 0 !important;
}
</style>
