<template>
  <!-- nav class="navbar navbar-expand-lg py-0" -->
<nav class="navbar navbar-expand-lg navbar-dark py-0">
<div class="container-fluid">

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul
        class="navbar-nav container"
        style=" width: 100%; display: flex; align-items: center; "
      >
        <a v-if="homit"
            class="nav-link " style="margin-right: 16px" role="button" aria-expanded="false" @mousedown.stop="$store.dispatch('navigate', {c: $store.getters.storeId, c2: 'home'})"
            :class="{ selectedNavItem: ($store.getters.underNavContext === 'home')}" >
            {{ $t('home.home') }}
        </a>
        <li v-for="item in topMenu"
          class="nav-item dropdown"
          :key="item.id"
          :class="{ selectedNavItem: selectedCatId.includes (item.c2) }">
          <a v-if="!item.kidEntries"
            class="nav-link" role="button" aria-expanded="false" @mousedown.stop="$store.dispatch('navigate', item)" >
            {{ i18n (item.name) }}
          </a>
          <template v-else >
            <a 
              class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" data-bs-target="Amenu" aria-expanded="false" >
              {{ i18n (item.name) }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown" id="Amenu" >
              <li v-for="subItem in item.kidEntries"
                  :class="{ selectedNavItem: selectedCatId.includes (subItem.c2) }"
                  :key="subItem.Id"
                  >
                <a v-if="!subItem.kidEntries" @mousedown.stop="$store.dispatch('navigate', subItem)" class="dropdown-item">
                  {{ $t(subItem.name) }}
                </a>
                <template v-else class="dropend">
                  <a class="nav-link dropdown-toggle" role="button"
                    aria-expanded="false">
                    {{ $t(subItem.name) }}
                  </a>
                  <ul class="submenu dropdown-menu" aria-labelledby="navbarDropdown" :id="subItem.id">
                    <li v-for="subsubItem in subItem.kidEntries"
                      :class="{ selectedNavItem: selectedCatId.includes (subsubItem.c2) }"
                      :key="subsubItem.id"
                      >

                      <a class="dropdown-item" @mousedown.stop="$store.dispatch('navigate', subsubItem)" >
                         {{$t(subsubItem.name) }}
                        </a>
                      </li>
                    </ul>
                  </template>
                </li>  
              </ul> 
            </template>
        </li>
      </ul>
    </div>
  </div>
  </nav>
</template>

<script>
export default {
  props: ["cats", "homit"],
  mounted () {

  // close all inner dropdowns when parent is closed
  document.querySelectorAll('.navbar .dropdown').forEach(function(everydropdown){
    everydropdown.addEventListener('hidden.bs.dropdown', function () {
      // after dropdown is hidden, then find all submenus
        this.querySelectorAll('.submenu').forEach(function(everysubmenu){
          // hide every submenu as well
          everysubmenu.style.display = 'none';
        });
    })
  });

  document.querySelectorAll('.dropdown-menu a').forEach(function(element){
    element.addEventListener('click', function (e) {
        let nextEl = this.nextElementSibling;
        if(nextEl && nextEl.classList.contains('submenu')) {	
          // prevent opening link if link needs to open dropdown
          e.preventDefault();
          if(nextEl.style.display == 'block'){
            nextEl.style.display = 'none';
          } else {
            nextEl.style.display = 'block';
          }

        }
    });
  })
    },
  computed: {
    selectedItem: function () {
      return this.$store.getters.currentShopCategory;
    },
    selectedCatId: function () { return this.$store.getters.underNavContext },
    menuEntries: function () {
      const cats = this.cats
      if (!cats || cats.length === 0) return []
      //console.log ("🥬️ Building menu entries, " + cats.length + ` entr${cats.length?"ies":"y"} for ` + JSON.stringify (cats.map (c => c.name || c.Name)) )
      const asTree = entry => {
        const entryId = entry.id;
        const kidEntries = cats.filter(k => k.parent && k.parent.id === entryId)
        if (kidEntries.length > 0)
          entry.kidEntries = kidEntries
        return entry
        }
      return cats.map (entry => asTree (entry))
      },
    topMenu: function () {
      const result = this.menuEntries.filter(m => !m.parent);
      return result
      }
    },
  methods: {
    i18n (s) {
      const t = this.$t(s)
      if (t === s) return this.$t('catalogue.categories.'+s)
      return t
      }
    }
  }

</script>

<style scoped>
.nav-link {
  color: white !important;
  font-weight: bold;
  font-size: 18px;
}
.dropdown-item {
  color: white !important;
  font-weight: bold;
  font-size: 18px;
  /*color: pink !important;
  border: 12px dotted red*/
}

.nav-link {
  font-size: 16px;
  /*color: black;
   font-weight: bold; */
}

.navbar {
  height: 50px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  /* font-weight: bold; */
  background-color: #0061a2 ; /*#2c3e50;*/
  }
nav a {
  color: white !important;
}


.navbar li{
  padding-right: 30px;
}

.dropdown-menu{
  top: 42px !important;
  left: -17px !important;
  border-radius: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0);
}

.dropdown-menu ul {
  background: white
}

.dropdown-menu li{
  margin-top: 1px;
  font-weight: 500;
  font-size: 16px !important;
  padding: 0;
  height: 45px;
  line-height: 45px;
  background-color: #0061a2 ;
  
}

.dropdown-menu li a {
  height: 45px;
  padding: 0 24px;
}

.dropdown-menu li a:hover{
  background: rgba(0, 0, 0, 0);
  color: #1f1f1f !important;
}

.dropdown-item > .nav-link {
  /*color: black;
  font-size: 16px;*/
}
.selectedNavItem {
  color: orange !important;
}
.selectedNavItem > a {
  color: orange !important;
}
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	.dropdown-menu li{ position: relative; 	}
	.nav-item .submenu{ 
		display: none;
		position: absolute;
		left:100%; top:-7px;
	}
	.nav-item .submenu-left{ 
		right:100%; left:auto;
	}
	.dropdown-menu > li:hover{ background-color: #f1f1f1 }
	.dropdown-menu > li:hover > .submenu{ display: block; }
}	
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu{
      margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
  }
}	
/* ============ small devices .end// ============ */
</style>
