<template>
  <div class="main">
    <div>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <br />
          <UserManageNav></UserManageNav>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row login">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <form class="login-form">
            <div class="panel-body">
              <vue-form-generator
                :schema="schema"
                :model="model"
                :options="formOptions"
              ></vue-form-generator>
              <a-button type="primary" block @click.prevent="submit">
                Submit
              </a-button>
            </div>
          </form>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import md5 from "js-md5";
import UserManageNav from "../../../components/userManageNav.vue";
import { i18n } from '@/i18n';
export default {
  components: { UserManageNav },
  data() {
    return {
      isFormValid: false,
      model: {
        Password: "",
        NewPassword: "",
        confirmpassword: "",
      },
      schema: {
        fields: [
          {
            type: "input",
            inputType: "password",
            label: "Your Current Password",
            model: "Password",
            placeholder: "Your current Password",
            required: true,
            validator: VueFormGenerator.validators.string,
          },
          {
            type: "input",
            inputType: "password",
            label: "New Password",
            model: "NewPassword",
            placeholder: "Your New Password",
            required: true,
          },
          {
            type: "input",
            inputType: "password",
            label: "Confirm Password",
            model: "confirmpassword",
            placeholder: "Confirm Password",
            required: true,
            validator: this.npasswordValidator,
          },
        ],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  beforeMount() {
    this.isProcessing = true;
  },
  computed: {
    programDefinition: function() {return this.$store.getters.programDefinition},
    },
  methods: {
    submit() {
      if (this.Password === "") {
        this.$notification["error"]({
          message: i18n.t('addcomm.Login Error'),
          description: i18n.t('addcomm.Please enter Your Current Password'),
        });
        return;
      }
      if (this.NewPassword === "") {
        this.$notification["error"]({
          message: i18n.t('addcomm.Login Error'),
          description: i18n.t('addcomm.Please enter New Password'),
        });
        return;
      }
      if (this.confirmpassword === "") {
        this.$notification["error"]({
          message: i18n.t('addcomm.Login Error'),
          description: i18n.t('addcomm.Please Enter Confirm Password'),
        });
        return;
      }
      if (this.NewPassword !== this.confirmpassword) {
        this.$notification["error"]({
          message: i18n.t('addcomm.Login Error'),
          description: i18n.t('addcomm.New Confirm Password must match')
        });
        return;
      }
      const encryptedPass = md5(
        this.$store.getters.userName + this.model["Password"]
        )
      const encryptedPass1 = md5(
        this.$store.getters.userName + this.model["NewPassword"]
        )
      const postdata = {
        RelationReference: this.$store.getters.relationReference,
        RelationType: 4,
        ProgramId: this.programDefinition.program_id,
        Password: encryptedPass.replace("\n", "").replace("\r", ""),
        NewPassword: encryptedPass1.replace("\n", "").replace("\r", ""),
      };
      this.$axios.post("/api/callback/v1/lbms/user/ChangePassword", postdata).then(
        (response) => {
          // console.log(response);
          if (response.status === 200 && response.data.results.IsSuccessful) {
            this.$notification["success"]({
              message: i18n.t('addcomm.Success'),
              description: i18n.t('addcomm.Password changed successfully')
            });
          } else {
            this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    npasswordValidator() {
      if (this.model.NewPassword !== this.model.confirmpassword) {
        return ["Password Mismatch"];
      }
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
}

.login-form {
  margin-top: 5%;
  /* text-align: left; */
}

.btn {
  width: 100%;
}
</style>