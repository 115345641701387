//import axios from 'axios'
import store from '@/store/index'

let relatedItems: {underlying_id: string, Id: string, id: string}[] = [];

export default {
    /* async getCard() {
        try {
            const cards = await axios.get('/api/card/v1/card/all');
            const keys = [];
            // cards.data = JSON.parse(cards.data)
            Object.keys(cards.data).filter(value => {
                // console.log(cards.data[value].type_id);
                if (cards.data[value].type_id === store.CREDS.VUE_APP_dmoId) {
                    keys.push(cards.data[value]);
                }
            });
            return this.getCurrentCard(keys);
        } catch (error) {
            console.log(error);
        }
    }

    getCurrentCard(keys) {
        if (keys.length === 0) {
            throw ({
                error: {
                    'error': 'invalid_credentials',
                    'error_description': 'User not registered with provider.'
                }
                });
            }
        else {
            if (keys.length > 1) {
                keys = keys.sort((a, b) => {
                    if (a.created_at < b.created_at) { return -1; }
                    if (a.created_at > b.created_at) { return 1; }
                    return 0;
                }).reverse()
                }
            // why? PS jusne 2021
 store.commit ('mutPoints', keys[0].value)
console.log ("🥑 🥑 🥑 Get current card changes points??")
            store.commit ('mutCurrentCard', JSON.stringify(keys[0]))
            return keys[0];
            }
        }

    async getUser(email) {
        try {
            const user = await axios.get('/api/user/v1/user/item');
            // console.log(user);
            if (user.data) {
                // user.data = JSON.parse(user.data);
                user.data[0].email = email;
                store.commit ('mutCurrentUser', user.data[0])
                }
            return user;
        } catch (error) {
            console.log(error);
        }
    }*/

    addRecentlyViewed(buyable: any) {
        // Stamp the buyable with a "permalink" potentially overriding the previous one:
        const id = buyable.underlying_id || buyable.Id || buyable.id
        const permalink = `/context/${store.getters.navContext}/undercontext/${store.getters.underNavContext}/detail/${id}`
        buyable.permalink = permalink
        //console.log(`🛵 Current permalink is ${permalink}`)
        // Now add it to the recently viewed list in the store:
        const recents = store.getters.recentlyViewed
        let filteredRecentlyViewed = recents.filter((item: any) => id != item.underlying_id || item.Id || item.id)
        filteredRecentlyViewed.push (buyable)
        if (filteredRecentlyViewed.length > 6)
            filteredRecentlyViewed = filteredRecentlyViewed.slice(-6)
        store.commit('mutRecentlyViewed', filteredRecentlyViewed)
    },

    // To clean:
    relatedItems(allBuybales: {underlying_id: string, Id: string, id: string}[], currentBuybale: any) {
        // console.log(relatedItems);
        // console.log(allBuybales);
        if (currentBuybale.country) {
            const id = currentBuybale.underlying_id || currentBuybale.Id || currentBuybale.id
            allBuybales = allBuybales.filter((item: any) => (id != (item.underlying_id || item.Id || item.id)) && item.country === currentBuybale.country);
            }
        else
            return []

        // console.log(allBuybales);
        if (allBuybales.length >= 5) {
            const cousins: Array<never> = []
            // console.log('buyable greater than 5');
            for (let i = 0; i < 5; i++) {
                const i: number = Math.floor(Math.random() * allBuybales.length)
                relatedItems.push( cousins[i] )
                if (i === 4) {
                    const resArr: any[] = [];
                    relatedItems.forEach(function (item: {underlying_id: string, Id: string, id: string}) {
                        const id = item.underlying_id || item.Id || item.id
                        const i = resArr.findIndex(x => id != (x.underlying_id || x.Id || x.id));
                        if (i <= -1) {
                            resArr.push(item);
                        }
                    });
                    return resArr;
                }
            }
        } else if (allBuybales.length < 5) {
            relatedItems = [];
            relatedItems = allBuybales;

            const resArr: any[] = [];
            relatedItems.forEach(function (item: any) {
                const i = resArr.findIndex(x => x.underlying_id == item.underlying_id);
                if (i <= -1) {
                    resArr.push(item);
                }
            });
            // const itemslength = relatedItems.length;
            return resArr;
        }
    }
}
