<template>
  <div class="container">
    <a-spin v-if="isloading" size=large style="margin-top: 200px" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" />
    <div v-else class="row">
      <div class="col-md-9">
      <div class="row main"  style="margin: 30px 0 0">
      <div class="col-md-3 onlywidth">
        <img
          :src="sweeps.campaignImage"
          class="card-img-top"
        />
        </div>
      <div class="col-md-7">
        <div class="title">{{ sweeps.campaignName }}</div>
        <div class="desc" v-html="sweeps.campaignDescription"></div>
        </div>
      </div>

        <!-- <div class="prizelist">
          <div class="title">Prize List</div>
          <div class="itembox">
            <div
              style="width: 180px; margin: 0 20px 20px"
              v-for="(item, index) in sweeps.ActivityPrizes"
              :key="index"
            >
              <div class="item">
                <img :src="item.prizeImg" alt="" />
              </div>
              <div class="prizeName">{{ item.prizeName }}</div>
              <div class="prizeDesc">{{ item.prizeDesc }}</div>
            </div>
          </div>
        </div> -->

        <div class="prizelist">
          <span class="title">{{ $t('addcomm.Prize List') }}</span>
          <div class="swiper-container" ref="swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in sweeps.ActivityPrizes" :key="index" navContext="mkt">
                  <div class="imgbox">
                  <img :src="item.prizeImg" alt="">
                  </div>
                  <div>{{item.prizeName}}</div></div>
            </div>
        </div>
      </div>
    <div class="tc" v-if="sweeps.rules">
      <div class="title">{{ $t('addcomm.Terms and Conditions') }}</div>
      <div style="margin-top:20px">
        <div v-html="sweeps.rules"></div>
      </div>
    </div></div>
    <div class="col-md-3"  style="padding-top:30px">
        <div style="background-color: #eeeeee; padding: 34px 28px 24px" >
          <div class="units">
             <div>{{sweeps.pointsRequired}} {{ $t('common.Points') }} </div>
            <div>( 1 {{ $t('addcomm.Coupon') }} ) </div>
          </div>
          <div class="points">
            <div>{{ $t('details.Total Points') }}</div>
            <div>
              {{sweeps.pointsRequired}}
              x {{num}} =
              {{points || sweeps.pointsRequired}}
              {{ $t('addcomm.smallPoints') }}
            </div>
          </div>
          <div class="quantity">
            <div class="input-group mb-3 addlose">
              <div class="lose cursor" @click="decreaseNum">
                <img src="@/assets/img/paymentmethod/lose.png" alt="">
              </div>
              <input
                type="text"
                class="form-control"
                v-model="num"
                style="text-align: center;background:#ffffff"
                readonly
              />
              <div class="lose cursor"  @click="increaseNum">
                <img src="@/assets/img/paymentmethod/add.png" alt="">
              </div>
            </div>
          </div>
          <div class="row buttons">
            <div>
              <div class="emailname">{{ $t('addcomm.EnterEmailAddress') }}：</div>
              <input type="text" class="inputbox" v-model="email" />
              <button
                type="primary"
                size="large"
                style="width: 100%;background:#0095eb;margin-top:26px"
                @click="redeemNow"
                :disabled="isButtonDisabled"
                class="ant-btn ant-btn-primary ant-btn-lg"
              >
                {{ $t('common.Redeem Now') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import RecentlyViewed from "@/components/recentlyViewed";
// import RelatedItems from "@/components/relatedItems";
import Swiper from "swiper";
import {i18n} from "@/i18n";

export default {
  // components: { RecentlyViewed, RelatedItems },
  data() {
    return {
      sweeps: '',
      sweepsImgList:[],
      detailId:'',
      buyableId: String,
      points:'',
      email:'',
      num: 1,
      isloading: true,
      isProcessing: true,
      isShowForm: false,
      isFormValid: false,
      relatedItems: [],
      relatedBuyables: { relatedItems: [], navContext: "sweepstake" },
      isButtonDisabled: false,

      maxSlider: 0,
      minSlider: 0,
      currentPointValue: "",
      sliderData: {
        values: [],
        currentValue: "",
        marks: true,
      },

      model: {},
      schema: {
        fields: [],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  activated() {
    this.sweeps = ''
    this.sweepsImgList = []
    setTimeout(() => {
      this.email = this.$store.getters.memberDetails.Email
      this.detailId = window.location.href.split("detail/")[1]
      this.getsweepsinfo(this.detailId)

      new Swiper (this.$refs.swiper, {
      loop: true, // 循环模式选项
      slidesPerView : 3,
    })
    }, 0);
    window.scroll(0, 0);
  },

  methods: {
    getsweepsinfo(detailId){
      this.isloading = true
      this.$axios.post('gamehub/lotteryAuction/Activity/GetSweepstakeInfo',{
        ActivityId: detailId
      }).then(res=>{
        this.sweeps = res.data.Data;
        this.sweepsImgList = []
        this.sweepsImgList.push(res.data.Data.campaignImage)
        this.isloading = false
        // this.points = res.Data.pointsRequired;
      })
    },

    mouseOverImage(img) {
      this.sweeps.campaignImage = img;
    },
    decreaseNum() {
      if(this.num <= 1){
        this.$message.warning(i18n.t("addcomm.No Less"));
      }else{
        this.num -= 1;
        this.points = this.num * this.sweeps.pointsRequired;
        this.getButtonDisabled()
      }
    },
    increaseNum() {
      const pointsQuantity = !this.sweeps.pointsQuantityPerRedemption
        ? 9999
        : this.sweeps.pointsQuantityPerRedemption;
      if (this.num <= pointsQuantity - 1) {
        this.num += 1;
        this.points = this.num * this.sweeps.pointsRequired;
        this.getButtonDisabled()
      }else{
        this.$message.warning(i18n.t("addcomm.No More"));
      }
    },
    showForm() {
      this.isShowForm = true;
    },
    redeemNow() {
      if(!this.$store.getters.memberDetails) {
        this.$store.dispatch("navigate", { c2: "login" });
        return
      }
      if (!this.email) {
        this.$message.warning(i18n.t('addcomm.Please fill in the email'));
        return;
      }
      if (this.isLoadingBet) {
        return;
      }
      this.isloading = true
      this.isLoadingBet = true;
      this.$axios
        .post(`/gamehub/lotteryAuction/Activity/SubmitSweepstake`, {
          Email: this.$store.getters.memberDetails.Email,
          SendEmail: this.email,
          ActivityId: this.sweeps.id,
          Quantity: this.num,
        })
        .then((res) => {
          if (res.data.ErrMsg == 'the operation was successful') {
            this.payPoint = false;
            this.showcoupen = true;
            this.counpList = res.Data;
            this.isLoadingBet = false;
            this.$message.success(i18n.t('addcomm.Transaction successfully'));
            this.num = 1;
            this.isloading = false
            this.isLoadingBet = false;
            // this.$store.dispatch ('navigate', {c: 'success'})
          } else {
            this.isLoadingBet = false;
            this.$message.error(res.ErrMsg);
            this.isloading = false
          }
        });
    },

    getButtonDisabled() {
      if ( Number(this.points) < Number(this.$store.getters.points)
      ) {
        this.isButtonDisabled = false;
      } else {
        this.isButtonDisabled = true;
      }
    },
    onValidated(isValid) {
      if (isValid) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    },
  },
};
</script>

<style scoped>

.card-img {
  width: auto
}

.main {
  /* text-align: left; */
  /* background-color: #f9f9f9; */
  margin-top: 30px;
  padding: 30px 12px;
  background-color: #ffffff;
}

.points {
  /* text-align: left; */
  font-size: 14px;
	color: #1f1f1f;
}
.points div:last-child{
  font-size: 12px;
	color: #999999;
  margin-top: 5px;
  margin-bottom: 10px;
}

.units {
  text-align: center;
	font-size: 12px;
	color: #545454;
  margin-bottom: 15px;
}
.units div:first-child{
	font-size: 16px;
	color: #1f1f1f;
  margin-bottom: 5px;
}

.quantity {

}

.quantity .addlose .lose img {
  height: 38px;
}
.quantity-input {
  width: 50%;
  margin-top: -1%;
  text-align: center !important;
}

.images {
  border: 2px solid;
}

.cursor {
  cursor: pointer;
}

.tc {
  margin-top: 10px;
  background-color: #fff;
  /* text-align: left; */
  padding: 20px 30px 30px;
  box-sizing: border-box;
}
.tc .title{
  font-size: 18px;
  color: #333;
  /* text-align: left; */
  position: relative;
  text-align: center;
  top: 5px;
}

.inputbox {
  width: 100%;
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.inputbox:focus-visible{
  outline: 0;
}


.title  .info{
  padding: 0 15px;
  }

.itembox{
  width: 100%;
  display: flex;
  overflow-x: scroll;
  border: 1px solid #fff;
  margin-top: 20px;
  }
.itembox .item {
    width: 180px;
    height: 166px;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #EEEEEE;
    font-size: 14px;
    color: #404040;
    }
.itembox img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    }
.itembox .prizeName {
    margin: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
.itembox .prizeDesc {
    color: #868686;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

/* .itembox::-webkit-scrollbar {
  width:0;
  display:none;
} */

.recently-viewed {
  padding: 0;
  }

@keyframes change
{
    0%		{transform: rotate(0deg);}
    50%		{transform: rotate(180deg);}
    100%	{transform: rotate(360deg);}
}
@-webkit-keyframes change
{
    0%		{-webkit-transform: rotate(0deg);}
    50%		{-webkit-transform: rotate(180deg);}
    100%	{-webkit-transform: rotate(360deg);}
}
@-moz-keyframes change
{
    0%		{-moz-transform: rotate(0deg);}
    50%		{-moz-transform: rotate(180deg);}
    100%	{-moz-transform: rotate(360deg);}
}

.onlywidth{
  width: 285px;
}
.card-img-top{
  width: 260px;
  border: solid 1px #eeeeee;
}
.desc {
  padding-top: 20px;
  font-size: 14px;
  color: #8e8d8d;
}
.title{
  font-size: 20px;
	color: #1f1f1f;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.emailname{
  font-size: 12px;
	color: #1f1f1f;
  /* text-align: left; */
  margin-bottom: 6px;
}
.prizelist {
  /* 奖品列表 */
  width: 100%;
  border: 1px solid #fff;
  margin-top: 10px;
  padding: 20px 20px 10px;
  background-color: #fff;
}
.prizelist .title {
  font-size: 18px;
  color: #333;
  /* text-align: left; */
  position: relative;
  top: 5px;
}
.swiper-container{
  width: 90%;
  /* border: 1px solid red; */
  height: 250px;
  box-sizing: border-box;
}
.swiper-slide {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #1f1f1f;
}
.swiper-slide img{
  height: 100%;
}
.imgbox{
  display: flex;
  align-items: center;
  height: 180px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 5px;
  border: solid 1px #eeeeee;
}
.swiper-wrapper{
  width: 33.3%;
}
</style>
