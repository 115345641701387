<template>
  <div style="margin-bottom: 12px">
    <a-card
      :bordered="false"
      v-for="(form, index) in formDetails"
      :key="index"
      style="padding: 0px; text-align: left"
    >
      <div class="mb-2" style="font-size: 15px">
        Passenger Info: {{ form.title }}
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <label>Title</label>
          <a-select
            :default-value="'Mr'"
            style="width: 100%"
            v-model="form.prefix"
            @change="onPrefixChange(form.prefix, index)"
          >
            <a-select-option value="Mr."> Mr. </a-select-option>
            <a-select-option value="Mrs."> Mrs. </a-select-option>
            <a-select-option value="Ms."> Ms. </a-select-option>
          </a-select>
        </div>
        <div class="col-md-4">
          <label>First Name</label>
          <a-input
            v-model="form.firstName"
            placeholder="Enter your First name"
            @change="onFirstNameChange(form.firstName, index)"
          />
        </div>
        <div class="col-md-4">
          <label>Last Name</label>
          <a-input
            v-model="form.lastname"
            placeholder="Enter your Last name"
            @change="onLastNameChange(form.lastname, index)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label>Gender</label><br />
          <a-select
            :default-value="'Male'"
            style="width: 100%"
            v-model="form.Gender"
            @change="onGenderChange(form.Gender, index)"
          >
            <a-select-option value="Male"> Male </a-select-option>
            <a-select-option value="Female"> Female </a-select-option>
          </a-select>
        </div>
        <div class="col-md-6">
          <label>DOB</label><br />
          <a-date-picker
            style="width: 100%"
            v-model="form.dob"
            :default-value="null"
            :disabled-date="disabledBeforeDate"
            :format="'DD/MM/YYYY'"
            placeholder="Select Date of Birth"
            @change="onChangeDOB(form.dob, index)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label>Email</label>
          <a-input
            v-model="form.email"
            @change="onChangeEmail(form.email, index)"
            placeholder="Enter your Email"
          />
        </div>
        <div class="col-md-6">
          <label>Nationality</label>
          <a-select
            v-model="form.Nationality"
            :default-value="''"
            style="width: 100%"
            @change="onChangeNationally(form.Nationality, index)"
          >
            <a-select-option value="">Select your Nationality</a-select-option>
            <a-select-option
              v-for="country in $store.getters.allCountries"
              :key="country.countryCode"
              :value="country.countryCode"
            >
              {{ country.countryName }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label>Passport Number</label>
          <a-input
            v-model="form.passNo"
            placeholder="Enter your Passport Number"
            @change="onPassposrtNoChange(form.passNo, index)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label>Passport Issue Date</label>
          <a-date-picker
            style="width: 100%"
            v-model="form.issuePassDate"
            :default-value="null"
            :disabled-date="disabledBeforeDate"
            :format="'DD/MM/YYYY'"
            placeholder="Select Passport Issue Date"
            @change="onChangeIssuePassDate(form.issuePassDate, index)"
          />
        </div>
        <div class="col-md-6">
          <label>Passport Expiry Date</label>
          <a-date-picker
            style="width: 100%"
            v-model="form.expirePassDate"
            :default-value="null"
            :disabled-date="disabledAfterDate"
            :format="'DD/MM/YYYY'"
            placeholder="Select Passport Expiry Date"
            @change="onChangeExpirePassDate(form.expirePassDate, index)"
          />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import GiiftCommonCountry from "@/api/giift/common/country";

export default {
  name: "flight-passenger-info",
  props: ["formDetails"],

  model: {
    props: ["tag", "increment"],
  },

  data() {
    return {
      // today: ),
    };
  },
  created() {
    console.log(this.formDetails);
    GiiftCommonCountry.GetAll();
    // this.$watch("$store.getters.storeId", this.setupShopBanner);
  },
  methods: {
    getAllFormValue() {
      console.log(this.formDetails);
    },
    handleSubmit() {},
    disabledAfterDate: (current) => {
      return current && current < moment().endOf("day");
    },
    disabledBeforeDate: (current) => {
      return current && current > moment().endOf("day");
    },

    onChangeDOB(result, index) {
      this.formDetails[index].dob = result.format("YYYY-MM-DDT00:00:00");
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onChangeNationally(result, index) {
      this.formDetails[index].Nationality = result;
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onChangeIssuePassDate(result, index) {
      this.formDetails[index].issuePassDate = result.format(
        "YYYY-MM-DDT00:00:00"
      );
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onChangeExpirePassDate(result, index) {
      this.formDetails[index].expirePassDate = result.format(
        "YYYY-MM-DDT00:00:00"
      );
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onChangeEmail(data, index) {
      this.formDetails[index].email = data;
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onGenderChange(data, index) {
      this.formDetails[index].gender = data;
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onPrefixChange(data, index) {
      this.formDetails[index].prefix = data;
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onPassposrtNoChange(data, index) {
      this.formDetails[index].passNo = data;
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onFirstNameChange(data, index) {
      this.formDetails[index].firstName = data;
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },

    onLastNameChange(data, index) {
      this.formDetails[index].lastName = data;
      this.$store.commit("flight/setFlightPassengerInfo", this.formDetails);
    },
  },
};
</script>
<style scoped>
.ant-card >>> label {
  /* font-weight: 600; */
  /* text-align: left; */
  width: 100%;
  margin-bottom: 5px;
}
.ant-card >>> .form-group span {
  width: 100%;
  /* text-align: left; */
}

.ant-card >>> fieldset {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  /* margin-left: calc(var(--bs-gutter-x) / -2); */
  width: 100%;
}

.ant-card >>> .col-md-6 {
  padding: 0 15px;
}
.ant-card >>> .ant-card-body {
  padding: 0 !important;
}
</style>