import _ from "lodash";
import Helper from "@/views/modules/hotel/Helper";
import IBEHotel from "@/api/ibe/hotel";
import {i18n} from "@/i18n";

export default {
    namespaced: true,
    state: {
        hotelSearchRequest: '',
        hotelSearchResults: '',
        selectedHotel: '',
        currentHotel: '',
        selectedHotelId: '',
        hotelRoomTypeCode: '',
        hotelSearchId: '',
        hotelBookingResponse: '',
        hotelRepriceResponse: '',
        hotelBookingRequest: '',
    },
    getters: {
        selectedHotel: (state: any) => {
            const allHotels = state.hotelSearchResults;
            if (allHotels !== '') {
                const temp = allHotels.find((hotel: any) => hotel.hotelid === state.selectedHotelId);
                return temp;
            } else return ''
        },
        currentHotel: (state: any) => { state.selectedHotel },
        hotelSearchId: (state: any) => state.hotelSearchId,
        selectedHotelId: (state: any) => state.selectedHotelId,
        hotelRoomTypeCode: (state: any) => state.hotelRoomTypeCode,
        hotelBookingResponse: (state: any) => state.hotelBookingResponse,
        hotelRepriceResponse: (state: any) => state.hotelRepriceResponse,
        hotelBookingRequest: (state: any) => state.hotelBookingRequest,
        hotelSearchRequest: (state: { hotelSearchRequest: any; }) => state.hotelSearchRequest,
        hotelSearchResults: (state: any) => Helper.CleanPayload(state.hotelSearchResults) || [],
        filteredPayload: (state: any, getters: { hotelSearchResults: any; }) => (data: any) => Helper.filterPayload(getters.hotelSearchResults, data) || [],
    },
    mutations: {
        setHotelBookingResponse: (state: any, data: any) => state.hotelBookingResponse = data,
        setHotelSearchId: (state: any, data: any) => state.hotelSearchId = data,
        setHotelRoomTypeCode: (state: any, data: any) => state.hotelRoomTypeCode = data,
        setSelectedHotelId: (state: any, id: any) => state.selectedHotelId = id,
        setHotelSearchResults: (state: { hotelSearchResults: any; }, cr: any) => state.hotelSearchResults = cr,
        setHotelSearchRequest: function (state: { hotelSearchRequest: any; }, cs: any) { state.hotelSearchRequest = cs },
        setHotelRepriceResponse: (state: { hotelRepriceResponse: any; }, cr: any) => state.hotelRepriceResponse = cr,
        setHotelBookingRequest: (state: { hotelBookingRequest: any; }, cr: any) => state.hotelBookingRequest = cr,
    },
    actions: {
        hotelSearchRequest( {commit}: any, payload: any) {
            return new Promise((resolve, reject) => {
                IBEHotel.GetHotelSearchResponse(payload).then((response) => {
                    console.log(response);
                    if (response.Errors) {
                        const e: any[] = [];
                        response.Errors.filter((d: { ErrorMessage: any; }) => e.push(d.ErrorMessage));
                        reject('Error');
                    } else {
                        if (response.SearchResponse.hotels.hotel === null || 
                            response.SearchResponse.hotels.hotel.length === 0)
                        reject(i18n.t('addcomm.No Result Found'));
                        else 
                        commit('setHotelSearchId', response.SearchId);
                        commit('setHotelSearchResults', response.SearchResponse.hotels.hotel || []);
                        resolve(response);
                    }   
                }, (error) => {
                    reject(error);
                });
            });
        }
    }
}