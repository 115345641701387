import {i18n} from "@/i18n";
export default {

    bookingFormValidation(vm: any) {
        const errorAlert = [];
        if (!vm.form.from || vm.form.from.IATACode == "") {
            errorAlert.push(i18n.t('addcomm.Please Select From Location'));
        }
        if (!vm.form.to || vm.form.to.IATACode == "") {
            errorAlert.push(i18n.t('addcomm.Please Select To Location'));
        }
        if (vm.form.departDate == null || vm.form.departDate == "") {
            errorAlert.push(i18n.t('addcomm.Please Select Departure Date'));
        }
        if (vm.onReturnToggle && (vm.form.returnDate == null || vm.form.returnDate == "")) {
            errorAlert.push(i18n.t('addcomm.Please Select Return Date') );
        }
        return errorAlert;
    },

    calDuration(t: any) {
        const hs = 60 * 60 * 1000;
        const h = Math.floor(t / hs);
        const m = Math.floor((t % hs) / 1000 / 60);
        return Math.abs(h) - 1 + "h " + Math.abs(m) + "min";
    },

    calDateDeff(arrival: any, departure: any) {
        if (!arrival || !departure) {
            throw Error("invalid date format");
        }
        const DS = 24 * 60 * 60 * 1000;
        return (
            Math.floor(new Date(arrival + "Z").getTime() / DS) -
            Math.floor(new Date(departure + "Z").getTime() / DS)
        );
    },

    removeDuplicates(arr: any) {
        return arr.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
    },

    cleanPayload(payload: any) {
        const r: any = {
            allAirlineNames: [],
            allFlightStops: []
        };
        const d = payload;
        d.forEach((item: any) => {
            item.Fare = item.FareDetails.ActualPoints;
            item.oneWayFlightDetails = item.ListOfFlightDetails[0];
            item.showOnewayFlightDetail = false;
            if (item.ListOfFlightDetails.length > 1) {
                item.returnFlightDetails = item.ListOfFlightDetails[1];
                if (item.returnFlightDetails.ListOfFlightSegments.length > 1) {
                    for (
                        let i = 0; i < item.returnFlightDetails.ListOfFlightSegments.length; i++
                    ) {
                        // calculate transit time for return flights
                        if (item.returnFlightDetails.ListOfFlightSegments[i + 1]) {
                            item.returnFlightDetails.ListOfFlightSegments[i].stopTime =
                                this.calDuration(
                                    new Date(
                                        item.returnFlightDetails.ListOfFlightSegments[
                                            i
                                        ].ArrivalDate
                                    ).getTime() -
                                    new Date(
                                        item.returnFlightDetails.ListOfFlightSegments[
                                            i + 1
                                        ].DepartureDate
                                    ).getTime()
                                );
                        }
                    }
                }
            }
            if (item.oneWayFlightDetails.ListOfFlightSegments.length > 1) {
                //  push all airlines name for depart(one way) flights
                r.allAirlineNames.push(
                    item.oneWayFlightDetails.ListOfFlightSegments[0].Carrier
                        .CarrierName
                );

                // flights stops for filter
                r.allFlightStops.push(
                    item.oneWayFlightDetails.ListOfFlightSegments.length - 1
                );

                for (
                    let i = 0; i < item.oneWayFlightDetails.ListOfFlightSegments.length; i++
                ) {
                    // calculate transit time for depart(one way) flights
                    if (item.oneWayFlightDetails.ListOfFlightSegments[i + 1]) {
                        item.oneWayFlightDetails.ListOfFlightSegments[i].stopTime =
                            this.calDuration(
                                new Date(
                                    item.oneWayFlightDetails.ListOfFlightSegments[
                                        i
                                    ].ArrivalDate
                                ).getTime() -
                                new Date(
                                    item.oneWayFlightDetails.ListOfFlightSegments[
                                        i + 1
                                    ].DepartureDate
                                ).getTime()
                            );
                    }
                }
            }
        });
        r.payload = d;
        r.allAirlineNames = this.removeDuplicates(r.allAirlineNames).sort();
        r.allFlightStops = this.removeDuplicates(r.allFlightStops).sort();
        return r;
    },

    filterPayload(payload: any, filters: any) {

        // Filter by CarrierName
        if (filters.airlineFilters !== undefined) {
            payload = payload.filter((data: any) => {
                if (filters.airlineFilters.includes(data.ListOfFlightDetails[0].ListOfFlightSegments[0].Carrier.CarrierName)) {
                    return data;
                }
            });
        }

        // Filter by Number of Stops
        if (filters.stopsFilters !== undefined && filters.stopsFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                if (filters.stopsFilters.includes((data.ListOfFlightDetails[0].ListOfFlightSegments.length - 1))) {
                    return data;
                }
            });
        }

        // Filter by Points
        if (filters.pointsFilters !== undefined && filters.pointsFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                if ((data.FareDetails.ActualPoints >= filters.pointsFilters[0]) && (data.FareDetails.ActualPoints <= filters.pointsFilters[1])) {
                    return data;
                }
            });

        }

        // Filter by Flight Duration
        if (filters.airlineFilters !== undefined && filters.durationFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                if (data.ListOfFlightDetails[0].TotalDurationHrs >= filters.durationFilters[0] &&
                    data.ListOfFlightDetails[0].TotalDurationHrs <= filters.durationFilters[1]) {
                    return data;
                }
            });
        }

        // Filter by Departure Time
        if (filters.departureTimeFilters !== undefined && filters.departureTimeFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                for (const item of filters.departureTimeFilters) {
                    const bothTimeValue = item.split('-');
                    const temp1 = bothTimeValue[0].split(':');
                    const temp2 = bothTimeValue[1].split(':');
                    if (new Date(data.ListOfFlightDetails[0].ListOfFlightSegments[0].DepartureDate).getHours() >= Number(temp1[0]) &&
                        new Date(data.ListOfFlightDetails[0].ListOfFlightSegments[0].DepartureDate).getHours() <= Number(temp2[0])) {
                        return data;
                    }
                }
            });
        }

        // Filter by Arrival Time
        if (filters.arrivalTimeFilters !== undefined && filters.arrivalTimeFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                for (const item of filters.arrivalTimeFilters) {
                    const bothTimeValue = item.split('-');
                    const temp1 = bothTimeValue[0].split(':');
                    const temp2 = bothTimeValue[1].split(':');
                    if (new Date(
                        data.ListOfFlightDetails[0].ListOfFlightSegments[data.ListOfFlightDetails[0].ListOfFlightSegments.length - 1].ArrivalDate
                    ).getHours() >= Number(temp1[0]) &&
                        new Date(
                            data.ListOfFlightDetails[0].ListOfFlightSegments[data.ListOfFlightDetails[0].ListOfFlightSegments.length - 1].ArrivalDate
                        ).getHours() <= Number(temp2[0])) {
                        return data;
                    }
                }
            });
        }

        return payload;
    },

    displaySelectedFlightDetails(flight: any) {
        const data = {
            departureCity: "",
            arrivalCity: "",
            ListOfFlightDetails: [],
        };
        if (flight && flight.ListOfFlightDetails != undefined) {
            const main = flight.ListOfFlightDetails[0];
            data.departureCity =
                main.ListOfFlightSegments[0].DepartureAirField.City;
            data.arrivalCity =
                main.ListOfFlightSegments[
                    main.ListOfFlightSegments.length - 1
                ].ArrivalAirField.City;
            data.ListOfFlightDetails = flight.ListOfFlightDetails;
        }
        return data;
    },

    concatPassenger(adults: number, children: number, infants: number): string {
        const infoArr = [];
        if (adults > 0) {
            infoArr.push(`${adults} adults`);
        }
        if (children > 0) {
            infoArr.push(`${children} children`);
        }
        if (infants > 0) {
            infoArr.push(`${infants} infants`);
        }
        return infoArr.join(', ');
    }
}