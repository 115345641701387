<template>
  <div style="text-align: left; font-size: 14px">
    <div >
      Search by brand:
      <input
        type="text"
        class="form-control"
        v-model="brandFilter"
        @keyup.enter="searchit"
        >
    </div>
    <div style="display: flex; flex-wrap: wrap">
      <span style="flex: 0 0 100%; padding-top: 16px">Set price range:</span>
      <span style="flex: 0 0 100%; padding-top: 16px; display: flex; flex-wrap: wrap; justify-content: space-between">
        From: <input v-model="minPrice" style="width: 66%" type="text">
        To: <input v-model="maxPrice" style="width: 66%" type="text"/>
      </span>
    </div>
        <!-- a-checkbox
          @change="tickFilter(item, filter.Label, $event.target.checked)"
          :defaultChecked="false">
          {{ item.Label }}
        </a-checkbox -->

  </div>
</template>

<script>
//import debounce from "lodash/debounce"
import buyablesService from "@/api/buyablesService"


export default {
  props: ["filters"],
  data() {
    return {
      termsArray: [],
      brandFilter: null,
      minPrice: 0,
      maxPrice: null
      }
    },
  methods: {
    searchit (v) {
      let filter = ""
      const bf = this.brandFilter
      console.log ("Yo: " + bf + "..." + typeof bf + " ... " + v.value)
      if (bf && bf.length > 0) {
        let CanonicalCapitalizedBf = bf.substr (0,1).toUpperCase() + bf.toLowerCase().substr (1)
        filter += `brand:${bf},${CanonicalCapitalizedBf} `
        }
      filter +=  `price:[${this.minPrice} TO`
      if (this.maxPrice) filter += ` ${this.maxPrice}]`
      else filter += `]`
      
      this.$store.commit("mutFilter", filter)

      let getthemproducts = () => {
        //console.log("🥕 🥕 Really getting products!!!")
        this.$store.commit("mutisProcessing", true)
        const c = this.$store.getters.navContext
        const c2 = this.$store.getters.underNavContext
        buyablesService.getBuyables (c, c2, filter)
        }
      //debounce(getthemproducts, 350)();
      getthemproducts()
    }
  }
}
</script>
