<template>
  <div class="main">
    <h3>404</h3>
    <h4>Page Not Found</h4>
    <br /><br />
    <a-button @click="$store.dispatch ('navigate', {c2: 'home'})" type="primary" size="large" style="font-weight: bolder">
      Back to Home Page
    </a-button>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main {
  margin-top: 5%;
  margin-bottom: 5%;
}
</style>