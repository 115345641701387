<template>
  <div class="row">
    <div class="col-md-8 driver-form">
      <a-card :title="$t('addcomm.Driver Information')" style="width: 100%">
        <div class="row" style="text-align: left">
          <div class="col-md-2">
            <label>{{$t('addcomm.Title')}}</label><br />
            <a-select
              :default-value="driverform.title"
              style="width: 140px"
              v-model="driverform.title"
            >
              <a-select-option value="Mr."> {{$t('addcomm.Mr')}} </a-select-option>
              <a-select-option value="Mrs.">{{$t('addcomm.Mrs')}} </a-select-option>
              <a-select-option value="Ms."> {{$t('addcomm.Ms')}}</a-select-option>
            </a-select>
          </div>
          <div class="col-md-5">
            <label>{{ $t("common.First Name") }}</label
            ><br />
            <a-input
              v-model="driverform.firstname"
              :placeholder="$t('addcomm.Enter your First name') "
            />
          </div>
          <div class="col-md-5">
            <label>{{ $t('common.Last Name') }}</label><br />
            <a-input
              v-model="driverform.lastname"
               :placeholder="$t('addcomm.Enter your Last name')"
            />
          </div>
        </div>
        <br />
        <div class="row" style="text-align: left">
          <a-checkbox
            @change="onFilghtNumber($event.target.checked)"
            :defaultChecked="false"
          >
           {{ $t('common.Do have Flight Number')}} 
          </a-checkbox>
        </div>
        <br />
        <div class="row" style="width: 210px" v-if="driverform.isFlightNumber">
          <a-input
            v-model="driverform.flightNo"
            :placeholder="$t('addcomm.Enter your Flight Number')"
          />
        </div>
        <br />
        <div class="row">
          <a-button type="primary" block @click="proceed()"
            > {{$t('addcomm.Proceed to Booking')}} </a-button
          >
        </div>
        <br />
        <div v-if="getErrors">
          <a-alert
            v-for="(err, index) in errorAlert"
            :key="err"
            :message="err"
            type="error"
            closable
            class="mt-3"
            @close="handleErrorClose(index)"
            show-icon
          />
        </div>
      </a-card>
    </div>
    <div class="col-md-4 car-form" v-if="carDetails !== ''">
      <a-card
        :title="
          carDetails.Vehicle[0].Name +
          ' (' +
          carDetails.Price[0].TotalPoints +
          ') Points'
        "
        style="width: 100%"
      >
        <div class="row" style="text-align: left">
          <img
            class="avatar"
            :src="carDetails.Vehicle[0].ImageURL"
            width="250px"
          />
          <h6>{{$t('addcomm.Pick Up')}}: {{ carDetails.Route[0].PickUp[0].locName }}</h6>
          <h6>{{$t('addcomm.Drop Off')}}: {{ carDetails.Route[0].DropOff[0].locName }}</h6>
          <h6> {{$t('addcomm.Car Class')}} : {{ carDetails.Vehicle[0].CarClass }}</h6>
          <h6>
            AC / Non-AC:
            {{ carDetails.Vehicle[0].aircon === "Yes" ? "AC" : "Non-AC" }}
          </h6>
          <h6> {{$t('addcomm.Transmission')}}: {{ carDetails.Vehicle[0].automatic }}</h6>
          <h6> {{$t('addcomm.Fuel')}}: {{ carDetails.Vehicle[0].CarFuel }}</h6>
          <h6> {{$t('addcomm.Doors')}}: {{ carDetails.Vehicle[0].doors }}</h6>
          <h6> {{$t('addcomm.Seats')}}: {{ carDetails.Vehicle[0].seats }}</h6>
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
import Helper from "../car/Helper";
import car from "../../../api/ibe/car";
export default {
  data() {
    return {
      driverform: {
        title: "Mr.",
        firstname: "",
        lastname: "",
        flightNo: "",
        isFlightNumber: false,
      },
      errorAlert: [],
      carDetails: "",
      memberDetails: "",
      totalPoints: 0,
      isFullProtection: false,
      fullProtection: "",
      extraListResponse: "",
    };
  },
  activated() {
    this.resetForm();
    this.memberDetails = this.$store.getters.memberDetails || "";
    this.carDetails = this.$store.getters["car/selectedCar"] || "";
    if (this.carDetails.Price === undefined) {
      this.$store.dispatch("navigate", { c2: "car" });
    } else {
      this.totalPoints = this.carDetails.Price[0].TotalPoints || 0;
      this.callCarExtrasRequest();
    }
  },
  methods: {
    resetForm() {
      (this.driverform = {
        title: "Mr.",
        firstname: "",
        lastname: "",
        flightNo: "",
        isFlightNumber: false,
      }),
        (this.carDetails = "");
      this.errorAlert = [];
    },
    callCarExtrasRequest() {
      car
        .CarExtrasRequest({
          Items: null,
          Vehicle: this.carDetails.Vehicle,
          SearchRequest: this.$store.getters["car/carSearchRequest"],
        })
        .then((response) => {
          // console.log(response);
          this.extraListResponse = response.data.results || "";
          const extraList =
            response.data.results.ExtrasListRS.Items[0].ExtraInfo || [];
          // console.log(extraList);
          extraList.forEach((item) => {
            if (item.Extra[0].Name === "Full Protection") {
              this.isFullProtection = true;
              this.fullProtection = item.Extra[0];
            }
          });
          // console.log(this.isFullProtection, this.fullProtection);
        });
    },
    getSelectedCountry(name) {
      this.driverform.country = name;
    },
    proceed() {
      this.errorAlert = [];
      this.errorAlert = Helper.driverFormValidation(this);
      if (this.errorAlert.length == 0) {
        let SearchRequest = this.$store.getters["car/carSearchRequest"];
        delete this.carDetails.Vehicle[0].CarClass;
        delete this.carDetails.Vehicle[0].CarFuel;
        const postdata = {
          Items: null,
          Booking: {
            PaymentInfo: [
              {
                CreditCard: {
                  CardNumber: "",
                  CCV2: "",
                  CardHolder: "",
                  ExpirationDate: [
                    {
                      year: "",
                      month: "",
                    },
                  ],
                  type: "",
                },
                depositPayment: "false",
              },
            ],
            ExtraList: [
              {
                Extra:
                  this.isFullProtection === true ? [this.fullProtection] : [],
              },
            ],
            PickUp: [
              {
                Location: [
                  {
                    id: SearchRequest.pickUp.Location.id,
                    locName: SearchRequest.pickUp.Location.Value,
                    country: SearchRequest.pickUp.Country,
                    city: SearchRequest.pickUp.City,
                    onAirport: "",
                    Value: "",
                  },
                ],
                Date: [
                  {
                    year: new Date(SearchRequest.pickUp.pickUpDate)
                      .getFullYear()
                      .toString(),
                    month: (
                      new Date(SearchRequest.pickUp.pickUpDate).getMonth() + 1
                    ).toString(),
                    day: new Date(SearchRequest.pickUp.pickUpDate)
                      .getDate()
                      .toString(),
                    hour: SearchRequest.pickUp.Hour.toString(),
                    minute: SearchRequest.pickUp.Minute.toString(),
                  },
                ],
              },
            ],
            DropOff: [
              {
                Location: [
                  {
                    id: SearchRequest.dropOff.Location.id,
                    locName: SearchRequest.dropOff.Location.Value,
                    country: SearchRequest.dropOff.Country,
                    city: SearchRequest.dropOff.City,
                    onAirport: "",
                    Value: "",
                  },
                ],
                Date: [
                  {
                    year: new Date(SearchRequest.dropOff.dropOffDate)
                      .getFullYear()
                      .toString(),
                    month: (
                      new Date(SearchRequest.dropOff.dropOffDate).getMonth() + 1
                    ).toString(),
                    day: new Date(SearchRequest.dropOff.dropOffDate)
                      .getDate()
                      .toString(),
                    hour: SearchRequest.dropOff.Hour.toString(),
                    minute: SearchRequest.dropOff.Minute.toString(),
                  },
                ],
              },
            ],
            Vehicle: this.carDetails.Vehicle,
            DriverInfo: [
              {
                Email: "support@giift.com",
                Telephone: this.memberDetails.MobileNumber,
                Fax: "",
                DriverName: [
                  {
                    title: this.driverform.title,
                    firstname: this.driverform.firstname,
                    lastname: this.driverform.lastname,
                  },
                ],
                Address: [
                  {
                    country: "",
                    city: "",
                    street: "",
                    postcode: "",
                  },
                ],
                DriverAge:
                  this.$store.getters["car/carSearchRequest"].pickUp
                    .driverAge || "25",
              },
            ],
            AdditionalInfo: [
              {
                Comments: "",
                PickUpService: "",
                DropOffService: "",
              },
            ],
            AirlineInfo: [
              {
                flightNo: this.driverform.flightNo || "",
              },
            ],
            id: "",
            status: "",
            statusCode: "",
          },
          Tracking: [
            {
              adcamp: "",
              adplat: "",
            },
          ],
          RedemptionRate: 1.0,
          PointRate: 0,
          IpAddress: "49.248.108.58",
          BookingReferenceId: "",
          FinalPoints: this.totalPoints,
          MembershipReference:
            this.memberDetails.MemberRelationsList[0].RelationReference || "",
          MemberName: this.memberDetails.FirstName || "",
          MemberEmail: this.memberDetails.Email || "",
          MemberPhone: this.memberDetails.MobileNumber || "",
          MemberAddress: this.memberDetails.Address1 || "",
          CarROE: "",
          CarMarkUp: "",
          PaymentType: 0,
          BookingPaymentDetails: null,
          FinalBaseFare: 0,
          IBEDiscount: 0,
          PromoCode: "",
          // SearchRequest: this.$store.getters["car/carSearchRequest"],
          SearchRequest: {
            DriverAge: SearchRequest.pickUp.driverAge,
            PickUp: [
              {
                Location: [
                  {
                    id: SearchRequest.pickUp.Location.id,
                    locName: SearchRequest.pickUp.Location.Value,
                    country: SearchRequest.pickUp.Country,
                    city: SearchRequest.pickUp.City,
                    onAirport: "",
                    Value: "",
                  },
                ],
                Date: [
                  {
                    year: new Date(SearchRequest.pickUp.pickUpDate)
                      .getFullYear()
                      .toString(),
                    month: (
                      new Date(SearchRequest.pickUp.pickUpDate).getMonth() + 1
                    ).toString(),
                    day: new Date(SearchRequest.pickUp.pickUpDate)
                      .getDate()
                      .toString(),
                    hour: SearchRequest.pickUp.Hour.toString(),
                    minute: SearchRequest.pickUp.Minute.toString(),
                  },
                ],
              },
            ],
            DropOff: [
              {
                Location: [
                  {
                    id: SearchRequest.dropOff.Location.id,
                    locName: SearchRequest.dropOff.Location.Value,
                    country: SearchRequest.dropOff.Country,
                    city: SearchRequest.dropOff.City,
                    onAirport: "",
                    Value: "",
                  },
                ],
                Date: [
                  {
                    year: new Date(SearchRequest.dropOff.dropOffDate)
                      .getFullYear()
                      .toString(),
                    month: (
                      new Date(SearchRequest.dropOff.dropOffDate).getMonth() + 1
                    ).toString(),
                    day: new Date(SearchRequest.dropOff.dropOffDate)
                      .getDate()
                      .toString(),
                    hour: SearchRequest.dropOff.Hour.toString(),
                    minute: SearchRequest.dropOff.Minute.toString(),
                  },
                ],
              },
            ],
            Tracking: [
              {
                adcamp: "",
                adplat: "",
              },
            ],
            MembershipReference:
              this.memberDetails.MemberRelationsList[0].RelationReference || "",
            RedemptionRate: 1,
            IpAddress: "127.0.0.1",
            SearchId: Number(this.$store.getters["car/carSearchId"]),
          },
          Match: this.carDetails,
          ExtrasListResponse:
            this.isFullProtection === true
              ? [this.fullProtection]
              : this.extraListResponse,
        };
        // console.log(postdata);
        this.$store.commit("car/setDriverInfo", this.driverform);
        this.$store.commit("car/setCarBookingRequest", postdata);
        this.$store.dispatch("navigate", { c2: "car-review" });
      }
    },
    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },
    onFilghtNumber() {
      this.driverform.isFlightNumber = !this.driverform.isFlightNumber;
    },
  },
  computed: {
    getErrors() {
      return this.errorAlert.length >= 1 ? true : false;
    },
  },
};
</script>
<style scoped>
.driver-form {
  padding-left: 5%;
  padding-top: 3%;
}

.car-form {
  padding-left: 2%;
  padding-top: 3%;
  padding-right: 5%;
}
</style>
