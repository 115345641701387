
import axios from 'axios';
export default {
    async newCart() {
        const response = await axios.get('/api/cart/v1/cart/new');
        console.log(response);
        if (response) return response.data; 
        else return false;
    },

    async addItem(postData: {}) {
        const response = await axios.post('/api/cart/v1/item/item', postData);
        console.log(response);
        if (response) return response.data;
        else return false;
    },

    async patchItem(cartItemId: string, data: any) {
        const response = await axios.patch('/api/cart/v1/item/item/' + cartItemId, data);
        console.log(response);
        if (response) return response.data;
        else return false;
    },

}