<template>
  <div class="row flex-nowrap h-100 px-2 overflow-hidden">
    <!--  v-if="$store.getters.chosenCard === 'mkt'" -->
    <div class="col-md-2 d-none d-md-block overflow-hidden">
      <div class="cards-length pt-2">
        {{ block.totalCount }}
        <span v-if="block.totalCount > 1"
          >{{ $t("addcomm.Items") }} {{ $t("addcomm.Found") }}</span
        >
        <span v-else>{{ $t("addcomm.Item") }} {{ $t("addcomm.Found") }}</span>
      </div>
      <div
        class="searchbox"
        :class="[$store.getters.navContext === 'mkt' ? '' : 'over']"
      >
        <ShopFilter v-if="$store.getters.navContext != 'mkt'" :filters="$store.getters.shopFilters" />
        <template v-if="false">
          <Search v-on:searchText="filterBySearch($event)"></Search>
          <Countries v-on:searchCountries="filterByCountry($event)"></Countries>
        </template>
      </div>
    </div>

    <div
      v-show="!$store.getters.isProcessing"
      class="col-sm-12 col-md-10 overflow-auto"
    >
      <div v-if="block.totalCount > 0" class="row" style="">
        <genCard
          v-for="item in pageBuyables"
          class="col-md-2"
          :key="item.underlying_id || item.Id || item.id"
          :item="item"
          :navContext="$store.getters.navContext"
        />
      </div>
      <a-pagination
        v-if="block.totalCount > countPerPage"
        :current="$store.getters.pageCursor"
        :total="block.totalCount"
        :pageSize="countPerPage"
        @change="
          (e) =>
            $store.dispatch('paginate', {
              pageCursor: e,
              countPerPage: countPerPage,
            })
        "
        class="py-2"
        style="
          bottom: 0;
          margin-top: 32px;
          display: flex;
          flex: 1 0 100%;
          justify-content: center;
        "
      />
    </div>
  </div>
</template>

<script>
import genCard from "@/components/genCard";
import Search from "@/components/Search";
import Countries from "@/components/Countries.vue";
import ShopFilter from "../views/modules/shop/components/ShopFilter";
import {i18n} from "@/i18n";

export default {
  components: { genCard, Search, Countries, ShopFilter },
  data() {
    return {
      filterText: "",
      Codes: [],
      countPerPage: 12,
    };
  },
  computed: {
    block() {
      return this.$store.getters.block;
    },
    pageBuyables() {
      const sb = this.$store.getters.startBlock / this.countPerPage;
      const i = this.countPerPage * (this.$store.getters.pageCursor - 1 - sb);
      return this.$store.getters.filteredBuyables.slice(
        i,
        i + this.countPerPage
      );
    },
  },
  methods: {
    imgSourceOf(item) {
      if (item.imgs) return item.imgs[0];
      if (item.type === "Sweepstake" || item.type === "Auction")
        return item.campaignImage;
      if (item.Images) return item.Images[0] ? item.Images[0].Url : "";
    },
    filterBySearch(value) {
      if (!value) return;
      this.filterText = value;
      this.filterAll(this.filterText, this.Codes);
    },
    filterByCountry(value) {
      if (!value) return;
      if (!value.includes) console.log("🍒 🍒 What? " + typeof value);
      const allCountries = this.$store.getters.allCountries;
      this.Codes = [];
      if (allCountries) {
        allCountries.filter((country) => {
          if (value.includes(country.countryName)) {
            this.Codes.push(country.countryCode);
          }
        });
        this.filterAll(this.filterText, this.Codes);
      }
    },
    filterAll(filterText, Codes) {
      let result = this.$store.getters.activeBuyables;
      if (filterText !== "")
        result = result.filter((buyable) => {
          const n = buyable.campaignName || buyable.name || buyable.Name;
          const t = filterText.toLowerCase();
          if (n.toLowerCase().includes(t)) return buyable;
        });
      if (Codes.length > 0)
        result = result.filter((buyable) => Codes.includes(buyable.country));
      this.$store.commit("mutFilteredBuyables", result);
      this.$store.dispatch ('paginate', {
              pageCursor: 1,
              countPerPage: this.countPerPage,
            })
    },
    titleFor(item) {
      let n = "Un-named item";
      if (item.type == "Sweepstake" || item.type == "Auction")
        n = item.campaignName;
      else n = item.title || item.Name || item.name;
      if (n.length < 22) return n;
      return n.substring(0, 22) + "...";
    },
    pricingFor(item) {
      if (item.type == "Sweepstake" || item.type == "Auction")
        return `${i18n.t('addcomm.From')}: ${item.pointsRequired.toFixed(2)} ${i18n.t('common.Points')}`;
     
      else {
        if (item.Price) return item.Price.SalePriceWithTax.FormattedAmount;
        if (!item.units) return "";
        const unit = item.units[0];
        if (!unit) return "";
        return `${i18n.t('addcomm.From')}: ${unit.price.toFixed(2) || unit.min.toFixed(2)} ${i18n.t('common.Points')}`;
      }
    },
  },
};
</script>

<style scoped>
.flex-nowrap {
  padding-top: 30px;
  }
.ant-card-cover {
  justify-content: space-around;
  display: flex;
  }
.ant-card-hoverable:hover {
  box-shadow: none !important;
  }

.searchbox {
  margin: 30px 20px;
  padding: 30px 20px;
  background-color: #eeeeee;
  }
.over {
  overflow-y: scroll;
  }

.col-md-2 {
  padding: 0 !important;
  }

@keyframes change {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes change {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes change {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
.cards-length {
  text-align: center;
}
</style>
