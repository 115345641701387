<template>
  <div>
    <div v-if="showCheckoutForm" class="row main">
      <div class="col-md-8">
        <a-card title="Billing Address" style="width: 100%">
          <div style="padding: 10px">
            <div class="row" style="text-align: left">
              <div class="col-md-6">
                <label>{{ $t("common.First Name") }}</label
                ><br />
                <a-input
                  v-model="billingForm.fName"
                  placeholder="Enter your First name"
                />
              </div>
              <div class="col-md-6">
                <label>Last Name</label><br />
                <a-input
                  v-model="billingForm.lName"
                  placeholder="Enter your Last name"
                />
              </div>
            </div>
            <br />

            <div class="row" style="text-align: left">
              <div class="col-md-6">
                <label>Email</label><br />
                <a-input
                  v-model="billingForm.email"
                  :placeholder="$t('EnterYourEmailAddress')"
                />
              </div>
              <div class="col-md-6">
                <label>Phone</label><br />
                <a-input
                  v-model="billingForm.phone"
                  placeholder="Enter your Phone Number"
                />
              </div>
            </div>
            <br />

            <div class="row" style="text-align: left">
              <div class="col-md-6">
                <label>Address</label><br />
                <a-input
                  v-model="billingForm.address1"
                  placeholder="Enter your Address"
                />
              </div>
              <div class="col-md-6">
                <label>Address 2 (Optional)</label><br />
                <a-input
                  v-model="billingForm.address2"
                  placeholder="Enter your Phone Number"
                />
              </div>
            </div>
            <br />

            <div class="row" style="text-align: left">
              <div class="col-md-4">
                <label>Country</label><br />
                <a-select
                  show-search
                  placeholder="Select a country"
                  style="width: 100%"
                  @change="getSelectedCountry($event)"
                >
                  <a-select-option
                    :value="item.countryName"
                    v-for="item in allCountries"
                    :key="item.id"
                  >
                    {{ item.countryName }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="col-md-4">
                <label>City</label><br />
                <a-input
                  v-model="billingForm.city"
                  placeholder="Enter your City"
                />
              </div>
              <div class="col-md-4">
                <label>Zip</label><br />
                <a-input
                  v-model="billingForm.zip"
                  placeholder="Enter your Zip Code"
                />
              </div>
            </div>

            <br />
            <div class="row" style="padding-left: 10px; padding-right: 10px">
              <a-button type="primary" block @click="checkout()">
                Proceed to Checkout</a-button
              >
            </div>

            <br />
            <div v-if="getErrors">
              <a-alert
                v-for="(err, index) in errorAlert"
                :key="err"
                :message="err"
                type="error"
                closable
                class="mt-3"
                show-icon
                @close="handleErrorClose(index)"
              />
            </div>
          </div>
        </a-card>
      </div>
      <div class="col-md-4" v-if="userCart.Price && cartItems.length > 0">
        <a-card
          :title="'Cart Items (' + cartItems.length + ')'"
          style="width: 100%"
        >
          <a slot="extra" @click="$store.dispatch('navigate', { c2: 'cart' })"
            >View Cart</a
          >
          <div class="row" v-for="item in cartItems" :key="item.Id">
            <div class="col-md-6">
              <p style="font-size: 12px; text-align: left">
                {{
                  item.Name.length > 22
                    ? item.Name.substring(0, 26) + "..."
                    : item.Name
                }}
              </p>
            </div>
            <div class="col-md-2">{{ item.Quantity }} Qty</div>
            <div class="col-md-4">
              {{ item.Price.SalePriceWithTax.FormattedAmount }}
            </div>
            <br />
          </div>
          <hr />
          <h6>
            Order Total:
            {{ userCart.Price.SubTotalWithTax.FormattedAmount || 0 }}
          </h6>
        </a-card>
      </div>
      <div class="col-md-4" v-if="cartItems.length === 0">
        <br /><br />
        <a-skeleton :loading="true" active></a-skeleton>
      </div>
    </div>
    <div v-if="!showCheckoutForm" class="row main">
      <a-card title="Order Processing" style="width: 100%">
        <div v-if="showInitializePay">
          <br /><br />
          <h5>Step 1: Initializing the Order</h5>
          <br /><a-spin v-if="!waitingForPayment" /><a-icon
            v-if="waitingForPayment"
            type="vertical-align-bottom"
            :style="{ fontSize: '30px' }"
          />
        </div>
        <div v-if="waitingForPayment">
          <h5>Step 2: Waiting for Payment</h5>
          <br /><a-spin v-if="!showCreatingOrder" /><a-icon
            v-if="showCreatingOrder"
            type="vertical-align-bottom"
            :style="{ fontSize: '30px' }"
          />
        </div>
        <div v-if="showCreatingOrder">
          <h5>Step 3: Creating Order</h5>
          <br /><a-spin v-if="!showOrderPlaced" /><a-icon
            v-if="showOrderPlaced"
            type="vertical-align-bottom"
            :style="{ fontSize: '30px' }"
          />
        </div>
        <div v-if="showOrderPlaced">
          <h5>Step 4: Order Placed</h5>
          <br /><a-button type="primary" @click="gotoMyOrder()">
            {{ gotoMyorder }}</a-button
          >
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
import ShopAuth from "@/views/modules/shop/api/shop";
import LBMSService from "@/api/lbmsService";
import { i18n } from '@/i18n';
export default {
  data() {
    return {
      cartItems: [],
      userCart: {},
      allCountries: [],
      billingForm: {
        fName: "",
        lName: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        countryName: "",
        countryCode: "",
        city: "",
        zip: "",
      },
      errorAlert: [],
      paymentmethod: [],
      shippingRate: [],
      showInitializePay: false,
      waitingForPayment: false,
      showCreatingOrder: false,
      showOrderPlaced: false,
      showCheckoutForm: true,
      gotoMyorder: "Go to My Orders",
    };
  },
  activated() {
    this.showInitializePay = false;
    this.waitingForPayment = false;
    this.showCreatingOrder = false;
    this.showOrderPlaced = false;
    this.showCheckoutForm = true;
    ShopAuth.searchUserCart().then((response) => {
      console.log(response);
      this.userCart = response || {};
      this.cartItems = response.Items || [];
    });
    // this.userCart = this.$store.getters.shopUserCart;
    // this.cartItems = this.userCart.Items || [];
    ShopAuth.GetAvailablePaymentMethods().then((response) => {
      console.log(response);
      this.paymentmethod = response;
    });
    ShopAuth.GetAvailableShippingRates().then((response) => {
      console.log(response);
      this.shippingRate = response;
    });
    this.allCountries = this.$store.getters.allCountries;
    // console.log(this.allCountries);
    if (this.allCountries.length === 0) this.callCountryApi();
  },
  methods: {
    callCountryApi() {
      this.$axios.get("/api/common/v1/country/all").then(
        (response) => {
          console.log(response);
          this.allCountries = response.data;
          if (!response) return;
          this.$store.commit("mutAllCountries", response.data);
        },
        (err) => console.log(err.message)
      );
    },
    getSelectedCountry(name) {
      console.log(name);
      // console.log(this.allCountries);
      let country = {};
      this.allCountries.forEach((c) => {
        if (c.countryName === name) country = c;
      });
      console.log(country);
      this.billingForm.countryName = country.countryName;
      this.billingForm.countryCode = country.countryCode;
      console.log(this.billingForm.countryName, this.billingForm.countryCode);
    },
    checkout() {
      this.errorAlert = [];
      if (this.billingForm.fName === "") {
        this.errorAlert.push("Please enter First Name");
      }
      if (this.billingForm.lName === "") {
        this.errorAlert.push("Please enter Last Name");
      }
      const regexEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        !regexEmail.test(this.billingForm.email.toLowerCase()) ||
        this.billingForm.email === ""
      ) {
        this.errorAlert.push("Please enter valid Email Address");
      }
      if (this.billingForm.phone === "") {
        this.errorAlert.push("Please enter Phone Number");
      }
      if (this.billingForm.address1 === "") {
        this.errorAlert.push("Please enter Address");
      }
      if (this.billingForm.countryName === "") {
        this.errorAlert.push("Please select Country");
      }
      if (this.billingForm.city === "") {
        this.errorAlert.push("Please enter City");
      }
      if (this.billingForm.zip === "") {
        this.errorAlert.push("Please enter Zip Code");
      }
      if (this.errorAlert.length == 0) {
        this.showCheckoutForm = false;
        this.showInitializePay = true;
        console.log("call UpdateCartShipment");
        ShopAuth.UpdateCartShipment(
          this.userCart,
          this.billingForm,
          this.shippingRate
        ).then((response) => {
          console.log(response);
          if (response) this.makePaymentFromLBMSUser();
        });
      }
    },
    makePaymentFromLBMSUser() {
      this.waitingForPayment = true;
      const redeempostdata = {
        RelationReference:
          this.$store.getters.memberDetails.MemberRelationsList[0]
            .RelationReference || "",
        ProgramId: this.$store.getters.programDefinition.program_id,
        RelationType: this.$store.getters.relationType,
        TransactionCurrency: this.$store.getters.transactionCurrency || "Giift Points", // for demo hard coding this to match transactionCurrency of shop with LBMS user
        Amounts: this.userCart.Price.SubTotalWithTax.Amount || 0,
        Points: this.userCart.Price.SubTotalWithTax.Amount || 0,
        MerchantName: this.$store.getters.storeDetails.Id,
        LoyaltyTxnType: 11,
      };
      console.log(redeempostdata);
      LBMSService.RedeemPoints(redeempostdata).then((result) => {
        console.log(result);
        if (result.results.IsSuccessful) {
          this.outerId = result.results.ReturnObject || "123456789";
          this.callUpdateCartPayment();
        } else {
          this.apiProcessing = false;
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: "Failed: " + result.results.ErrorDescription,
          });
        }
      });
    },
    callUpdateCartPayment() {
      ShopAuth.UpdateCartPayment(
        this.userCart,
        this.paymentmethod,
        this.outerId
      ).then((response) => {
        console.log(response);
        if (response) this.callCreateOrder();
      });
    },
    callCreateOrder() {
      this.showCreatingOrder = true;
      ShopAuth.CreateOrderFromCart().then((response) => {
        console.log(response);
        if (response) this.orderDetails = response;
        this.callUpdateCustomerOrder();
      });
    },
    callUpdateCustomerOrder() {
      ShopAuth.UpdateCustomerOrder(this.orderDetails, this.outerId).then(
        (response) => {
          console.log(response);
          if (response) this.showOrderPlaced = true;
          // updated user Point
          LBMSService.CheckAvailability().then((response) => {
            if (response.results.IsSuccessful) {
              this.$store.commit("mutPoints", response.results.ReturnObject);
            }
          });
        }
      );
    },
    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },
    getErrors() {
      return this.errorAlert.length >= 1;
    },
    gotoMyOrder() {
      this.gotoMyorder = "Please Wait...";
      ShopAuth.GetCustomerOrders().then((response) => {
        const allOrders = response.CustomerOrders || [];
        this.$store.commit("mutShopOrders", allOrders);
        this.$store.dispatch("navigate", { c2: "orders" });
      });
    },
  },
};
</script>
<style scoped>
.main {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}
</style>
