<template>
  <div>
    <div class="row">
      <div class="col-md-2"></div>
      <div
        class="col-md-8"
        v-if="!isProcessing"
        style="margin-top: 3%; margin-bottom: 5%"
      >
        <div style="padding: 15px; border: 1px solid; border-radius: 4px">
          <div class="row" style="text-align: left">
            <h6> {{$t('addcomm.Car booking is now confirmed')}}.</h6>
            <h6>
              {{$t('addcomm.Booking Id')}}:
              {{ carBookingResponse.MakeBookingRS.Booking.id || "" }}
            </h6>
            <h6>
               {{$t('addcomm.Reference Number')}}:
              {{ carBookingResponse.BookingReferenceId || "" }}
            </h6>
            <h6>
              {{$t('addcomm.Status')}} :
              {{ carBookingResponse.MakeBookingRS.Booking.status || "" }}
            </h6>
            <h6>
                 {{$t('details.Total Points')}}:
              {{ carBookingRequest.FinalPoints }}
               {{$t('common.Points')}} 
            </h6>
          </div>
          <div class="row">
            <div class="col-md-6" style="text-align: left">
              <a-card size="small" title="Booking Details" style="width: 100%">
                <h6>
                  {{$t('common.Pick Up Location')}} :
                  {{ carBookingRequest.Booking.PickUp[0].Location[0].locName }},
                  {{ carBookingRequest.Booking.PickUp[0].Location[0].city }},
                  {{ carBookingRequest.Booking.PickUp[0].Location[0].country }}
                </h6>
                <h6>
                   {{$t('common.Pick Up Date')}} :
                  {{ carBookingRequest.Booking.PickUp[0].Date[0].day }}-{{ carBookingRequest.Booking.PickUp[0].Date[0].month }}-{{ carBookingRequest.Booking.PickUp[0].Date[0].year }}
                </h6>
                <h6>
                   {{$t('common.Pick Up Time')}}:
                  {{ carBookingRequest.Booking.PickUp[0].Date[0].hour }}:{{ carBookingRequest.Booking.PickUp[0].Date[0].minute }}
                </h6>
                <h6>
                 {{$t('common.Drop Off Location')}}:
                  {{
                    carBookingRequest.Booking.DropOff[0].Location[0].locName
                  }},
                  {{ carBookingRequest.Booking.DropOff[0].Location[0].city }},
                  {{ carBookingRequest.Booking.DropOff[0].Location[0].country }}
                </h6>
                <h6>
                   {{$t('common.Drop Off Date')}}:
                  {{ carBookingRequest.Booking.DropOff[0].Date[0].day }}-{{ carBookingRequest.Booking.DropOff[0].Date[0].month }}-{{ carBookingRequest.Booking.DropOff[0].Date[0].year }}
                </h6>
                <h6>
                   {{$t('common.Drop Off Time')}}:
                  {{ carBookingRequest.Booking.DropOff[0].Date[0].hour }}:{{ carBookingRequest.Booking.DropOff[0].Date[0].minute }}
                </h6>
                <br />
                <h5><u> {{$t('addcomm.Driver Information')}}</u></h5>
                <h6>
                  {{$t('common.Name')}}:
                  {{
                    carBookingRequest.Booking.DriverInfo[0].DriverName[0].title
                  }}
                  {{
                    carBookingRequest.Booking.DriverInfo[0].DriverName[0]
                      .firstname
                  }}
                  {{
                    carBookingRequest.Booking.DriverInfo[0].DriverName[0]
                      .lastname
                  }}
                </h6>
                <h6>
                   {{$t('addcomm.Age')}} : {{ carBookingRequest.Booking.DriverInfo[0].DriverAge }}
                </h6>
              </a-card>
            </div>
            <div class="col-md-6" style="text-align: left">
              <a-card size="small" title="Car Details" style="width: 100%">
                <h6> {{$t('addcomm.Age')}} : {{ selectedCar.Vehicle[0].Name }}</h6>
                <h6>{{$t('addcomm.Transmission Type')}} : {{ selectedCar.Vehicle[0].automatic }}</h6>
                <h6>{{$t('addcomm.Fuel')}}: {{ selectedCar.Vehicle[0].fuelPolicy }}</h6>
                <h6>{{$t('addcomm.Seats')}}: {{ selectedCar.Vehicle[0].seats }}</h6>
                <h6> {{$t('addcomm.Doors')}}: {{ selectedCar.Vehicle[0].doors }}</h6>
                <h6> {{$t('addcomm.Car Class')}}: {{ selectedCar.Vehicle[0].CarClass }}</h6>
                <h6>
                  Ac/Non-Ac:
                  {{
                    selectedCar.Vehicle[0].aircon === "Yes" ? "AC" : "Non-AC"
                  }}
                </h6>
              </a-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carBookingResponse: "",
      selectedCar: "",
      carBookingRequest: "",
      isProcessing: true,
    };
  },
  activated() {
    this.carBookingResponse =
      this.$store.getters["car/carBookingResponse"] || "";
    this.selectedCar = this.$store.getters["car/selectedCar"];
    this.carBookingRequest = this.$store.getters["car/carBookingRequest"];
    this.isProcessing = false;
    // console.log(this.carBookingResponse, "carBookingResponse");
    // console.log(this.selectedCar, "selectedCar");
    // console.log(this.carBookingRequest, "carBookingRequest");
  },
};
</script>
<style scoped>
</style>