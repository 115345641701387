<template>
  <div
    style="background: #ececec; padding: 15px; width: 100%; margin-bottom: 15px"
    v-if="ratingOpts.length !== 0"
  >
    <a-card :title="$t('addcomm.Hotel Amenities')" :bordered="false" style="width: 100%">
      <a-row>
        <a-col :span="24">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
          {{$t('addcomm.Select All')}}  
          </a-checkbox>
        </a-col>
      </a-row>
      <a-checkbox-group
        v-model="hotelAmenitiesFilters"
        style="width: 100%"
        @change="onChange"
      >
        <a-row v-for="checkbox in options" :key="checkbox">
          <a-col :span="24">
            <a-checkbox :value="checkbox">{{ checkbox }}</a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </a-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      indeterminate: false,
      checkAll: true,
      hotelAmenitiesFilters: [],
      options: [],
    };
  },

  watch: {
    hotelAmenitiesFilters: function () {
      this.$emit("input", this.hotelAmenitiesFilters);
    },

    ratingOpts(d) {
      this.hotelAmenitiesFilters = d;
      this.options = d;
    },
  },

  created() {
    this.hotelAmenitiesFilters = [
    //   "Air Conditioning",
      "Bar",
      "Business Centre",
      "Coffee Shop",
      "Gym",
      "Pool",
      "Restaurant",
    //   "Room Service",
      "Wi-Fi Access",
    ];
    this.options = [
    //   "Air Conditioning",
      "Bar",
      "Business Centre",
      "Coffee Shop",
      "Gym",
      "Pool",
      "Restaurant",
    //   "Room Service",
      "Wi-Fi Access",
    ];
  },

  methods: {
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.options.length;
      this.checkAll = checkedList.length === this.options.length;
    },

    onCheckAllChange(e) {
      Object.assign(this, {
        hotelAmenitiesFilters: e.target.checked ? this.options : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
  },
  computed: {
    ratingOpts() {
      //   return this.$store.getters["hotel/hotelSearchResults"].allHotelRating;
      return [
        // "Air Conditioning",
        "Bar",
        "Business Centre",
        "Coffee Shop",
        "Gym",
        "Pool",
        "Restaurant",
        // "Room Service",
        "Wi-Fi Access",
      ];
    },
  },
};
</script>

<style scoped>
.ant-card >>> .ant-card-body {
  padding-bottom: 14px !important;
}

.ant-card >>> .ant-card-head-title {
  text-align: center;
}

div >>> .ant-checkbox-wrapper {
  width: 100%;
  /* text-align: left; */
  margin: 0 0 5px;
}
</style>>
