<template>
  <div class="main">
    <div>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <br />
          <UserManageNav></UserManageNav>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 main-center">
          <div class="row">
            <div>All Bookings</div>
            <div :style="{ marginTop: '16px' }">
              <a-radio-group
                :default-value="radioValue"
                @change="getradioValues($event.target.value)"
              >
                <a-radio-button value="a"> Flight </a-radio-button>
                <a-radio-button value="b"> Hotel </a-radio-button>
                <a-radio-button value="c"> Car </a-radio-button>
              </a-radio-group>
            </div>
          </div>
          <br /><br />
          <div class="row">
            <table
              class="table table-bordered table-hover"
              v-if="tableData.length !== 0 && radioValue === 'a'"
            >
              <thead>
                <tr>
                  <th scope="col">From Location</th>
                  <th scope="col">Destination Location</th>
                  <th scope="col">Departure Date</th>
                  <th scope="col">ArrivalDate</th>
                  <th scope="col">Points</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableData.slice(startIndex, endIndex)" :key="index">
                  <td>{{ item.OriginLocation }}</td>
                  <td>{{ item.DestinationLocation }}</td>
                  <td>{{ formatDate(item.DepartureDate) }}</td>
                  <td>{{ formatDate(item.ArrivalDate) }}</td>
                  <td>{{ item.FareDetails.TotalPoints }}</td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-bordered table-hover"
              v-if="tableData.length !== 0 && radioValue === 'b'"
            >
              <thead>
                <tr>
                  <th scope="col">Booking Id</th>
                  <th scope="col">Hotel Name</th>
                  <th scope="col">CheckIn Date</th>
                  <th scope="col">CheckOut Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Points</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableData.slice(startIndex, endIndex)" :key="index">
                  <td>{{ item.BookingId }}</td>
                  <td>{{ item.Hotel.basicinfo.hotelname }}</td>
                  <td>{{ formatDate(item.searchCriteria.CheckInDate) }}</td>
                  <td>{{ formatDate(item.searchCriteria.CheckOutDate) }}</td>
                  <td>
                    {{ item.Status === "1" ? "Booked" : "Booking Failed" }}
                  </td>
                  <td>{{ item.TotalPoint }}</td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-bordered table-hover"
              v-if="tableData.length !== 0 && radioValue === 'c'"
            >
              <thead>
                <tr>
                  <th scope="col">Booking Id</th>
                  <th scope="col">Pick Up Location</th>
                  <th scope="col">Drop Off Location</th>
                  <th scope="col">Status</th>
                  <th scope="col">Points</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableData.slice(startIndex, endIndex)" :key="index">
                  <td>{{ item.Booking.id }}</td>
                  <td>
                    {{ item.Booking.PickUp[0].Location[0].locName }},
                    {{ item.Booking.PickUp[0].Location[0].city }},
                    {{ item.Booking.PickUp[0].Location[0].country }}
                  </td>
                  <td>
                    {{ item.Booking.DropOff[0].Location[0].locName }},
                    {{ item.Booking.DropOff[0].Location[0].city }},
                    {{ item.Booking.DropOff[0].Location[0].country }}
                  </td>
                  <td>{{ item.Booking.status }}</td>
                  <td>{{ item.FinalPoints }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="row"
            v-if="tableData.length === 0"
            style="text-align: center"
          >
            <h5>No data available</h5>
          </div>
          <div class="row" style="margin-bottom: 35px">
            <a-pagination
              :total="tableData.length"
              @change="pagination"
              :pageSize="pageSize"
              v-if="tableData.length !== 0"
            />
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import UserManageNav from "../../../components/userManageNav.vue";
import CarAPI from "@/api/ibe/car";
import HotelAPI from "@/api/ibe/hotel";
import FlightAPI from "@/api/ibe/flight";
import moment from "moment";
export default {
  components: { UserManageNav },
  data() {
    return {
      radioValue: "a",
      tableData: [],
      currentPage: 1,
      startIndex: 0,
      endIndex: 10,
      pageSize: 10,
    };
  },
  created() {
    if (this.radioValue == "a") {
      this.callFlightAPI();
    }
  },
  methods: {
    pagination(event) {
      this.currentPage = event;
      this.startIndex = (this.currentPage - 1) * this.pageSize;
      this.endIndex = this.startIndex + this.pageSize;
      window.scroll(0, 0);
    },
    formatDate(date) {
      return moment(date).format("LL");
    },
    getradioValues(value) {
      this.radioValue = value;
      console.log(value);
      this.tableData = [];
      if (this.radioValue === "a") {
        this.callFlightAPI();
      } else if (this.radioValue === "b") {
        this.callHotelAPI();
      } else if (this.radioValue === "c") {
        this.callCarAPI();
      }
    },
    callFlightAPI() {
      this.tableData = [];
      FlightAPI.GetFlightBookingListForMember({
        MemberId:
          "000000",
      }).then((response) => {
        console.log(response);
        this.tableData = response || [];
      });
    },
    callHotelAPI() {
      this.tableData = [];
      HotelAPI.GetMemberBookedHotelInfoList({
        MembershipReference:
          this.$store.getters.memberDetails.MemberRelationsList[0]
            .RelationReference || "",
      }).then((response) => {
        console.log(response);
        this.tableData = response.HotelDetails || [];
      });
    },
    callCarAPI() {
      this.tableData = [];
      CarAPI.GetBookingDetailsForMemberId({
        MemberId:
          this.$store.getters.memberDetails.MemberRelationsList[0]
            .RelationReference || "",
        RefererId: "40",
      }).then((response) => {
        console.log(response);
        this.tableData = response.data.results || [];
      });
    },
  },
};
</script>
<style scoped>
.main-center {
  padding: 10px;
  margin-left: 20px;
  /* text-align: left; */
}
</style>